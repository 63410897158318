export type DefaultingMode = 'ENRICH' | 'OVERRIDE' | 'OVERRIDE_IF_INVALID';

export enum DefaultingScope {
  BREAK_FEE = 'BREAK_FEE',
  LIMIT_WINDOW = 'LIMIT_WINDOW',
  AGGREGATED_FAIR_PRICES = 'AGGREGATED_FAIR_PRICES',
  ALLOCATIONS = 'ALLOCATIONS',
  BARRIER_UNITS = 'BARRIER_UNITS',
  BOOKING_INFO = 'BOOKING_INFO',
  BROKER_COMMISSION_CURRENCY = 'BROKER_COMMISSION_CURRENCY',
  BREAK_FEE_ELECTION = 'BREAK_FEE_ELECTION',
  CALCULATION_METHOD = 'CALCULATION_METHOD',
  CAP = 'CAP',
  COMPONENT_SECURITY_INDEX_ANNEX = 'COMPONENT_SECURITY_INDEX_ANNEX',
  CONFIRM_MEDIA = 'CONFIRM_MEDIA',
  CONTACT = 'CONTACT',
  DELIVERY_TYPE = 'DELIVERY_TYPE',
  DELTAS = 'DELTAS',
  DETERMINATION_METHOD = 'DETERMINATION_METHOD',
  DISPERSION_LEGS = 'DISPERSION_LEGS',
  DIVIDEND_CURRENCY = 'DIVIDEND_CURRENCY',
  DIVIDEND_PAYMENT_DATE = 'DIVIDEND_PAYMENT_DATE',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  PAYMENT_DELAY = 'PAYMENT_DELAY',
  END_OF_OBSERVATION = 'END_OF_OBSERVATION',
  EXPECTED_N = 'EXPECTED_N',
  FAIR_PRICES = 'FAIR_PRICES',
  FIXED_DAY_TYPE = 'FIXED_DAY_TYPE',
  FOREX = 'FOREX',
  FORWARD_START_DETERMINATION_METHOD = 'FORWARD_START_DETERMINATION_METHOD',
  HEDGES = 'HEDGES',
  IA_CURRENCY = 'IA_CURRENCY',
  IA_VALUE_DATE = 'IA_VALUE_DATE',
  INTEREST_RATE_INDEX = 'INTEREST_RATE_INDEX',
  LEND_BORROW = 'LEND_BORROW',
  LISTED_ALLOCATIONS = 'LISTED_ALLOCATIONS',
  LOT_SIZE = 'LOT_SIZE',
  MARKET = 'MARKET',
  MARKET_CODE = 'MARKET_CODE',
  MATURITY_DATE = 'MATURITY_DATE',
  MCA = 'MCA',
  NEGOTIATED_SIZE = 'NEGOTIATED_SIZE',
  NEGOTIATION = 'NEGOTIATION',
  NOTIONAL = 'NOTIONAL',
  OBSERVABLE_TYPE = 'OBSERVABLE_TYPE',
  OPTION_STYLE = 'OPTION_STYLE',
  PIVOT_CURRENCY = 'PIVOT_CURRENCY',
  PRE_ALLOCATIONS = 'PRE_ALLOCATIONS',
  PRE_ALLOCATIONS_VALUE_DATE = 'PRE_ALLOCATIONS_VALUE_DATE',
  QUOTE_RECAP = 'QUOTE_RECAP',
  RATE_FIXING_OFFSET = 'RATE_FIXING_OFFSET',
  RATE_OVERNIGHT = 'RATE_OVERNIGHT',
  RATE_RESET = 'RATE_RESET',
  RATE_TENOR = 'RATE_TENOR',
  REFERENCE = 'REFERENCE',
  REFERENCE_MATURITY = 'REFERENCE_MATURITY',
  RESET_FREQUENCY = 'RESET_FREQUENCY',
  RIGHT_TO_SUBSTITUTE = 'RIGHT_TO_SUBSTITUTE',
  ROLE_DEFINITION = 'ROLE_DEFINITION',
  ROUNDING_STRATEGY = 'ROUNDING_STRATEGY',
  SALES_COUNTERPARTY = 'SALES_COUNTERPARTY',
  SALES_DIFFUSION = 'SALES_DIFFUSION',
  SCHEDULE = 'SCHEDULE',
  SECONDARY_IA = 'SECONDARY_IA',
  SETTLEMENT_LISTED = 'SETTLEMENT_LISTED',
  SETTLEMENT_METHOD_ELECTION = 'SETTLEMENT_METHOD_ELECTION',
  SETTLEMENT_OTC = 'SETTLEMENT_OTC',
  SPOT_NET = 'SPOT_NET',
  STRIKE_DATE = 'STRIKE_DATE',
  SWAP_CURRENCY = 'SWAP_CURRENCY',
  TENOR_DATE = 'TENOR_DATE',
  TRADE_DATE = 'TRADE_DATE',
  TRADE_RECAP = 'TRADE_RECAP',
  TRADED_AWAY_FEEDBACK = 'TRADED_AWAY_FEEDBACK',
  TRADER_DIFFUSION = 'TRADER_DIFFUSION',
  UNDERLYING = 'UNDERLYING',
  UNITS = 'UNITS',
  VALUE_DATE = 'VALUE_DATE',
  DIVIDEND_FUTURE_DELTA = 'DIVIDEND_FUTURE_DELTA',
  RELATED_EXCHANGE = 'RELATED_EXCHANGE',
  SPECIAL_DIVIDENDS = 'SPECIAL_DIVIDENDS',
  LOCAL_TAXES = 'LOCAL_TAXES',
}
