import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import { isClsProduct } from '../neosModel';
import { ElsScheduleRecomputeScope } from '../rfq/models/recomputeModel';
import type { LegPeriodDates } from '../rfq/strategy/leg/product/elsProductOnyxModel';

export function createChangeScheduleStartOrEndDateThunk(
  rfqId: string,
  strategyId: string,
  date: Partial<LegPeriodDates>,
  dispatchable: Dispatchable,
): Thunk {
  return function changeScheduleStartOrEndDate(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createRecomputeRfqThunk },
      },
      selectors,
    },
  ) {
    const isStartDate = date.startDate !== undefined;
    const isEndDate = date.endDate !== undefined;

    const state = getState();
    const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);

    if (isClsProduct(product)) {
      return dispatch(dispatchable);
    }

    if (isStartDate) {
      return dispatch(
        createRecomputeRfqThunk(
          rfqId,
          ElsScheduleRecomputeScope.CHANGE_START_DATE_SCHEDULE_PERIOD,
          dispatchable,
        ),
      );
    }
    if (isEndDate) {
      return dispatch(
        createRecomputeRfqThunk(
          rfqId,
          ElsScheduleRecomputeScope.CHANGE_END_DATE_SCHEDULE_PERIOD,
          dispatchable,
        ),
      );
    }

    return dispatch(dispatchable);
  };
}
