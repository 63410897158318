import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { flatMap } from 'lodash';
import { isElsProduct } from '../strategyModel';
import type { OnyxStrategy } from '../strategyOnyxModel';

export function createLoadStrategiesThunk(rfqId: string, onyxStrategies: OnyxStrategy[]): Thunk {
  return function loadStrategies(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: {
          featureCrudActions,
          createProductLoadedAction,
          strategyDataCrudActions,
          referenceCrudActions,
          listedAllocationCrudActions,
          deltaStockListedAllocationCrudActions,
          otcAllocationCrudActions,
          electronicMediaUsersActionCreators,
        },
      },
      thunks: {
        neos: { createLoadLegsThunk, createInitializeElsSectionsThunk },
      },
      fromMappers,
      selectors,
    },
  ) {
    const state = getState();

    const elsToggle = selectors.isFeatureToggleEnabled(state, 'neos.els.update.enabled');

    const strategiesMappingResults = onyxStrategies.map(strategy =>
      fromMappers.mapFromOnyxStrategy(rfqId, strategy, fromMappers),
    );

    const actions: ActionContainer[] = flatMap(
      strategiesMappingResults,
      ({
        strategyData,
        features,
        products,
        referencesState,
        listedAllocations,
        otcAllocations,
        deltaStockListedAllocations,
      }) => {
        const { uuid: strategyId, strategyType } = strategyData;

        const oldAllocations = selectors.getOtcAllocationsByRfqId(state, rfqId);

        const featureActions = features.map(feature =>
          featureCrudActions.upsert({ strategyId, type: feature.type }, feature),
        );
        const productsActions = products.map(product => createProductLoadedAction(product));
        const strategyDataActions = [
          strategyDataCrudActions.upsert(strategyId, { ...strategyData }),
        ];
        const referenceActions = referencesState.map(reference =>
          referenceCrudActions.patchOrInsert(
            { underlyingId: reference.underlyingId, rfqId },
            { ...reference },
          ),
        );

        const listedAllocationsActions = [
          ...listedAllocations.map(listedAllocation =>
            listedAllocationCrudActions.deleteByPartialKey({ legId: listedAllocation.legId }),
          ),
          ...listedAllocations.map(listedAllocation =>
            listedAllocationCrudActions.insert(
              {
                rfqId,
                legId: listedAllocation.legId,
                executionId: listedAllocation.executionId,
                allocationId: listedAllocation.uuid,
              },
              listedAllocation,
            ),
          ),
        ];

        const deltaStockListedAllocationsActions = [
          ...deltaStockListedAllocations.map(deltaStockListedAllocation =>
            deltaStockListedAllocationCrudActions.deleteByPartialKey({
              legId: deltaStockListedAllocation.legId,
            }),
          ),
          ...deltaStockListedAllocations.map(deltaStockListedAllocation =>
            deltaStockListedAllocationCrudActions.insert(
              {
                rfqId,
                legId: deltaStockListedAllocation.legId,
                allocationId: deltaStockListedAllocation.uuid,
              },
              deltaStockListedAllocation,
            ),
          ),
        ];

        const uiSizeType = selectors.getStrategyUi(state.ui, strategyId)?.displayedSizeType;

        const availableSizeTypes = selectors.getStrategyAvailableSizeTypes(
          state.referenceData,
          strategyType,
          selectors,
        );

        const onChangeSizeType = uiSizeType
          ? availableSizeTypes.find(st => st.includes(uiSizeType.replace('LOCAL_', '')))
          : undefined;

        const otcAllocationsActions = [
          ...otcAllocations.map(otcAllocation =>
            otcAllocationCrudActions.deleteByPartialKey({ legId: otcAllocation.legId }),
          ),

          ...otcAllocations
            .map(otcAllocation => {
              const oldAlloc = oldAllocations.find(alloc => alloc.uuid === otcAllocation.uuid);
              const dispatchables: ActionContainer[] = [
                otcAllocationCrudActions.insert(
                  {
                    rfqId,
                    legId: otcAllocation.legId,
                    allocationId: otcAllocation.uuid,
                  },
                  {
                    ...otcAllocation,
                    sizeType: oldAlloc
                      ? oldAlloc.sizeType
                      : onChangeSizeType || otcAllocation.sizeType,
                    eligibleMedia:
                      oldAlloc && !oldAlloc.eligibleMedia
                        ? otcAllocation.media || otcAllocation.eligibleMedia
                        : oldAlloc?.eligibleMedia,
                  },
                ),
              ];
              if (otcAllocation.counterpartId) {
                dispatchables.push(
                  electronicMediaUsersActionCreators.createElectronicMediaUsersRequestedAction(
                    otcAllocation.counterpartId,
                  ),
                );
              }
              return dispatchables;
            })
            .reduce((acc, val) => acc.concat(val), []),
        ];

        return [
          ...featureActions,
          ...productsActions,
          ...strategyDataActions,
          ...referenceActions,
          ...listedAllocationsActions,
          ...otcAllocationsActions,
          ...deltaStockListedAllocationsActions,
        ];
      },
    );

    if (strategiesMappingResults[0]) {
      const { products } = strategiesMappingResults[0];

      const product = products[0];

      if (
        product &&
        isElsProduct(product) &&
        elsToggle &&
        !selectors.getElsSections(state).length
      ) {
        dispatch(createInitializeElsSectionsThunk());
      }
    }

    dispatch(
      strategiesMappingResults.map(({ strategyData, legsData }) =>
        createLoadLegsThunk(rfqId, strategyData.uuid, strategyData.strategyType, legsData),
      ),
      ...actions,
    );
  };
}
