import { PreconfirmationButton } from '../preconfirmation/preconfirmationButton';
import { AggregatedPredealCheck } from '../predealChecks/aggregatedPredealCheck';
import { ElsSwitcher } from '../workflow/elsSwitcher/ElsSwitcher';
import { NegoStepSwitcher } from '../workflow/negoStep/NegoStepSwitcher.container';
import { RfqDifferences } from '../workflow/rfqDifferences/rfqDifferences';
import styles from './TransactionActions.module.scss';

import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { FC } from 'react';
import { getTransactionActionsModel } from './getTransactionActionsModel';

export interface TransactionActionsProps {
  rfqId: string;
}

export const TransactionActions: FC<TransactionActionsProps> = ({ rfqId }) => {
  const { isPostNegoEnabled, isElsEnabled, isInternalEls } = useAppSelector(state =>
    getTransactionActionsModel(state, rfqId, selectors),
  );

  return (
    <section className={`${styles['pre-alloc-and-pdc']}`}>
      {!isInternalEls ? <AggregatedPredealCheck rfqId={rfqId} /> : null}
      {isPostNegoEnabled && <NegoStepSwitcher rfqId={rfqId} />}
      {isElsEnabled && <ElsSwitcher rfqId={rfqId} />}
      {!isInternalEls ? <PreconfirmationButton rfqId={rfqId} /> : null}
      <RfqDifferences rfqId={rfqId} />
    </section>
  );
};
