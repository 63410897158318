import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { getAveragePriceTooltipMessage } from '../../../prices/quotes/getQuotesModel';

interface SalesBidLeg {
  legId: string;
  quoteId: string;
  unit: string;
  salesBid: number | undefined;
  bidIsTransparent: boolean;
  averagePrice: string | undefined;
  isAveragePriceTooltipSalesBidEnabled: boolean;
}

export interface SalesBidModel {
  isEnabled: boolean;
  legs: SalesBidLeg[];
}
export function getSalesBidModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): SalesBidModel {
  const compositionLegs = selectors.getStrategyCompositionLegsData(appState, strategyId, selectors);
  const isStatusAfterOrderBeingCrossed = selectors.isStatusAfterOrderBeingCrossed(
    appState,
    rfqId,
    selectors,
  );
  const legsQuotes: SalesBidLeg[] = compositionLegs.map(({ uuid, quoteId }) => {
    const { unit, salesBid } = selectors.getQuote(appState, quoteId);
    const isBuyWay = selectors.getLegWay(appState, rfqId, strategyId, uuid, selectors) === 'BUY';
    const isListed = selectors.isListedLeg(appState, uuid, selectors);
    const bidIsTransparent = isBuyWay;
    const { averagePrice } = selectors.getOrderByLegId(appState.orderData, rfqId, uuid) ?? {};
    const isAveragePriceTooltipSalesBidEnabled =
      isListed &&
      !isBuyWay &&
      isStatusAfterOrderBeingCrossed &&
      unit === averagePrice?.unit &&
      salesBid !== averagePrice?.value;

    return {
      legId: uuid,
      quoteId,
      unit: unit ?? '',
      salesBid,
      bidIsTransparent,
      isAveragePriceTooltipSalesBidEnabled,
      averagePrice: getAveragePriceTooltipMessage(averagePrice?.value, averagePrice?.unit),
    };
  });
  const isEnabled = selectors.areTraderPricesEnabled(appState, rfqId, strategyId, selectors);

  return { isEnabled, legs: legsQuotes };
}
