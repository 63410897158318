import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { ImportedCompositionLegsData } from '@/neos/business/rfq/thunks/rfqApplyImportedCompositionLegsThunk';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  CompositionLegsModalComponent,
  type CompositionLegsModalDispatchProps,
  type CompositionLegsModalOwnProps,
  type CompositionLegsModalStateProps,
} from './CompositionLegsModal';
import { getCompositionLegsModalModel } from './getCompositionLegsModalModel';

export const CompositionLegsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompositionLegsModalComponent);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: CompositionLegsModalOwnProps,
): CompositionLegsModalStateProps {
  return {
    showModal: selectors.isCompositionLegsModalShown(state.ui, strategyId),
    model: getCompositionLegsModalModel(state, rfqId, strategyId, selectors, services),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: CompositionLegsModalOwnProps,
): CompositionLegsModalDispatchProps {
  return {
    hideModal: () => {
      dispatch(neosThunks.createNeosHideCompositionLegsModalThunk(rfqId, strategyId));
    },
    onImportData: (data: ImportedCompositionLegsData[]) => {
      dispatch(neosThunks.createRfqApplyImportedCompositionLegsThunk(rfqId, strategyId, data));
    },
    onUnderlyingAdded: (underlyingId: string) => {
      dispatch(neosThunks.createRfqAddLegWithUnderlyingThunk(rfqId, strategyId, underlyingId));
    },
    onFormDefaulted: () => {
      dispatch(neosActionCreators.createDefaultCompositionLegsRequestedAction(rfqId, strategyId));
    },
    onLegRemoved: (legId: string) => {
      dispatch(neosThunks.createRfqDeleteLegThunk(legId));
    },
    onLegUnderlyingChanged: (productId: string, underlyingId: string | undefined) => {
      dispatch(
        neosActionCreators.createNeosChangeCompositionLegUnderlyingAction({
          rfqId,
          strategyId,
          productId,
          underlyingId,
        }),
      );
    },
    onWeightChanged: (legId: string, weight: number | undefined) => {
      dispatch(
        neosThunks.createRfqChangeLegWeightThunk(
          rfqId,
          strategyId,
          legId,
          weight ? weight / 100 : weight,
        ),
      );
    },
    onSizeChanged: (legId: string, size: number | undefined) => {
      dispatch(
        neosThunks.createRfqChangeStrategyOrLegSizeThunk(rfqId, {
          strategyId,
          legId,
          value: size,
          isStrategyUpdate: false,
        }),
      );
    },
    onSpotChanged: (underlyingId: string, spot: number | undefined) => {
      dispatch(
        neosActionCreators.referenceCrudActions.update({ rfqId, underlyingId }, { refSpot: spot }),
      );
    },
    onExpectedNChanged: (productId: string, expectedN: number | undefined) => {
      dispatch(neosActionCreators.productCrudActions.update(productId, { expectedN }));
    },
    onCloseDataWarning: () => {
      dispatch(
        neosActionCreators.strategyUiCrudActions.update(strategyId, {
          displayMissingCompositionDataWarning: false,
        }),
      );
    },
    onCloseIndexWarning: () => {
      dispatch(
        neosActionCreators.strategyUiCrudActions.update(strategyId, {
          displayCompoImportHasMultipleIndicesWarning: false,
        }),
      );
    },
    onCloseMaturityWarning: () => {
      dispatch(
        neosActionCreators.strategyUiCrudActions.update(strategyId, {
          displayCompoImportHasMultipleMaturitiesWarning: false,
        }),
      );
    },
    onHideImportedCountMessage: () => {
      dispatch(
        neosActionCreators.strategyUiCrudActions.update(strategyId, {
          compoLastImportRowCount: undefined,
          compoLastImportTime: undefined,
        }),
      );
    },
  };
}
