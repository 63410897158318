import type { FC } from 'react';
import {
  type FieldInfo,
  GenericLegsQuotes,
} from '../../../prices/quotes/genericQuotes/GenericLegsQuotes';
import type { SalesBidModel } from './getSalesBidModel';

export interface SalesBidOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface SalesBidStateProps {
  model: SalesBidModel;
}

export interface SalesBidDispatchProps {
  onLegSalesBidChanged: (quoteId: string, legId: string, value?: number) => void;
}

export type SalesBidProps = SalesBidOwnProps & SalesBidStateProps & SalesBidDispatchProps;

export const SalesBidComponent: FC<SalesBidProps> = ({
  rfqId,
  strategyId,
  model: { legs, isEnabled },
  onLegSalesBidChanged,
}) => {
  const fieldInfo: FieldInfo = {
    color: 'BID',
    fieldName: 'salesBid',
    isTransparentKey: 'bidIsTransparent',
    legErrorField: 'legSalesPriceBid',
  };
  return (
    <section>
      <label>Sls Bid</label>
      <GenericLegsQuotes
        rfqId={rfqId}
        strategyId={strategyId}
        fieldInfo={fieldInfo}
        legsQuotes={legs}
        areTraderPricesEnabled={isEnabled}
        isUnitDisplayed={true}
        onLegChanged={onLegSalesBidChanged}
      />
    </section>
  );
};
