import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { isElsBasketProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { ElsBasketComposition } from '@/neos/components/rfq/strategies/strategy/features/elsBasket/ELSBasketComposition';
import { ElsBasketDescription } from '@/neos/components/rfq/strategies/strategy/features/elsBasket/ELSBasketDescription';
import { ElsBasketExport } from '@/neos/components/rfq/strategies/strategy/features/elsBasket/ElsBasketExport';
import { ElsBasketImport } from '@/neos/components/rfq/strategies/strategy/features/elsBasket/ElsBasketImport';
import { ElsBasketClipboardPaste } from './ElsBasketClipboardPaste';

type ElsBasketProps = {
  rfqId: string;
  strategyId: string;
};

export function ElsBasket({ rfqId, strategyId }: ElsBasketProps) {
  const product = useAppSelector(state =>
    selectors.getStrategyMasterProduct(state, strategyId, selectors),
  );
  const isDisplayed = isElsBasketProduct(product);

  if (!isDisplayed) {
    return null;
  }

  return (
    <div style={{ width: '1200px', gridColumn: '2 / -2' }} className="p-1 px-2">
      <div className="border p-3 h-100 overflow-auto card">
        <h4 className="d-inline-block p-1 m-0 mb-3">Basket description</h4>
        <div>
          <ElsBasketDescription product={product} strategyId={strategyId} rfqId={rfqId} />
          <div className="d-flex justify-content-between">
            <h5 className="d-inline-block p-1 m-0 mb-3">Composition</h5>
            <div>
              <ElsBasketClipboardPaste product={product} rfqId={rfqId} />
              <ElsBasketImport product={product} rfqId={rfqId} />
              <ElsBasketExport product={product} rfqId={rfqId} />
            </div>
          </div>
          <ElsBasketComposition product={product} strategyId={strategyId} rfqId={rfqId} />
        </div>
      </div>
    </div>
  );
}
