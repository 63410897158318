import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { RateLegPeriodDates } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { neosThunks } from '@/neos/business/thunks';

import { useAppSelector } from '@/bootstrap/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleLeg } from './ScheduleLeg';

type ScheduleRateLegProps = {
  strategyId: string;
  rfqId: string;
  title?: string;
  ratesDateFields: RateLegPeriodDates;
};

export function ScheduleRateLeg({
  strategyId,
  rfqId,
  title,
  ratesDateFields,
}: ScheduleRateLegProps) {
  const dispatch = useDispatch();
  const isReadonly = useAppSelector(appState =>
    selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors),
  );
  const rateData = useSelector((state: AppState) =>
    selectors.selectScheduleRateLegDates(state, strategyId, selectors),
  );
  const hasRateOvernightFeature = useAppSelector(
    state =>
      selectors.getFeature(state.featureState, {
        strategyId,
        type: 'RATE_OVERNIGHT',
      })?.rateOvernight,
  );

  const readonlyColumns: AllUnionKeys<RateLegPeriodDates>[] = hasRateOvernightFeature
    ? ['firstFixingDate']
    : [];

  function addRateLegLine() {
    dispatch(neosThunks.createAddRateSchedulePeriodThunk(strategyId));
  }

  function deleteRateLegLine(uuid: string) {
    dispatch(neosThunks.createRemoveRateSchedulePeriodThunk(strategyId, uuid));
  }

  function updateRateLegField(newDate: Partial<RateLegPeriodDates>, uuid: string) {
    const dispatchable = neosThunks.createUpdateRateSchedulePeriodThunk(strategyId, newDate, uuid);
    dispatch(
      neosThunks.createChangeScheduleStartOrEndDateThunk(rfqId, strategyId, newDate, dispatchable),
    );
  }

  return (
    <ScheduleLeg
      isReadonly={isReadonly}
      title={title ?? 'Rate Leg'}
      data={rateData}
      dataStructure={ratesDateFields}
      addLine={addRateLegLine}
      deleteLine={deleteRateLegLine}
      onValueChanged={updateRateLegField}
      readonlyColumns={readonlyColumns}
    />
  );
}
