import type { Thunk } from '@/bootstrap/thunks';
import { isClsProduct, isElsProduct } from '../legModel';

type clsOrElsStratType = 'CLS' | 'ELS';
export function createFetchCurvesListBasedOnCurrencyThunk(
  rfqId: string,
  strategyIds: string[],
): Thunk {
  return function fetchCurvesListThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createCurvesListRequestedAction },
      },
    },
  ) {
    const state = getState();

    const elsOrClsStratId = strategyIds.find(
      sId =>
        selectors.isElsStrategy(state, sId, selectors) ||
        selectors.isClsStrategy(state, sId, selectors),
    );

    if (elsOrClsStratId) {
      const { strategyType } = selectors.getStrategyData(state, elsOrClsStratId);
      const product = selectors.getStrategyMasterProduct(state, elsOrClsStratId, selectors);

      let swapCurrency = undefined;

      if (isClsProduct(product)) {
        swapCurrency = selectors.getFeature(state.featureState, {
          strategyId: elsOrClsStratId,
          type: 'SWAP_CURRENCY',
        })?.swapCurrency;
      }

      if (isElsProduct(product)) {
        swapCurrency = product.swapCurrency;
      }

      if (product && swapCurrency) {
        dispatch(
          createCurvesListRequestedAction(swapCurrency, rfqId, strategyType as clsOrElsStratType),
        );
      }
    }
  };
}
