import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type { BreakFeePeriod } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { assertProductIsEls } from '../../../../neos/business/neosModel';

export function createUpdateBreakFeePeriodThunk(
  strategyId: string,
  id: string,
  partialUpdate: Partial<BreakFeePeriod>,
): Thunk {
  return function updateBreakFeePeriodThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();

    const product = selectors.getStrategyProduct(appState, strategyId, selectors);

    assertProductIsEls(product);

    const periods = [...(product?.breakFeePeriods ?? [])];
    const periodToUpdateIndex = periods.findIndex(line => line.id === id);
    const oldPeriod = periods[periodToUpdateIndex];

    if (!oldPeriod) {
      throw new Error('Index of periods is out of bound');
    }

    const newDividendPeriod = {
      ...oldPeriod,
      ...partialUpdate,
    };
    periods[periodToUpdateIndex] = newDividendPeriod;

    const action: Action = productCrudActions.update(product.uuid, {
      breakFeePeriods: periods,
    });
    dispatch(action);
  };
}
