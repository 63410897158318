import type { Thunk } from '@/bootstrap/thunks';
import { internalGetErrorId } from '../../mappers/error/internal/errorIdGenerator';
import type { OnyxFieldError } from '../../mappers/error/onyxErrorModel';
import type { RetryOnErrorOptions } from '../../neosActions';
import { DefaultingScope } from '../models';
import { DefaultingOptions } from '../rfqActions';

export function createRfqUpdateLegMaturityThunk(
  rfqId: string,
  strategyId: string,
  legId: string,
  maturity: string | undefined,
): Thunk {
  return function rfqUpdateLegMaturityThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: {
          createUpdateLegMaturityThunk,
          createHandleErrorsThunk,
          createUpdateProductThunk,
          changeElsIsScheduleObsoleteThunk,
          changeClsIsScheduleObsoleteThunk,
        },
      },
      actionCreators: {
        neos: { createDefaultRfqRequestedAction, errorCrudActions },
      },
    },
  ) {
    const state = getState();
    const { strategyIds } = selectors.getRfqData(state, rfqId);
    const { legIds } = selectors.getStrategyData(state, strategyId);
    const retryOnErrorOptions: RetryOnErrorOptions = {
      retries: 1,
    };

    const elsFeatureToggleEnabled = selectors.isFeatureToggleEnabled(
      state,
      'neos.els.update.enabled',
    );

    const clsToggle = selectors.isFeatureToggleEnabled(state, 'neos.cls.enabled');
    const isEls = elsFeatureToggleEnabled && selectors.isElsStrategy(state, strategyId, selectors);
    const isCls = clsToggle && selectors.isClsStrategy(state, strategyId, selectors);

    const overrideScopes = [
      DefaultingScope.DETERMINATION_METHOD,
      DefaultingScope.UNDERLYING,
      DefaultingScope.EXPECTED_N,
      DefaultingScope.END_OF_OBSERVATION,
      DefaultingScope.LIMIT_WINDOW,
    ];

    if (isEls || isCls) {
      overrideScopes.push(DefaultingScope.FIXED_DAY_TYPE);
    }

    if (isEls) {
      overrideScopes.push(DefaultingScope.BREAK_FEE);
    }

    const defaultingOptions = new DefaultingOptions({
      overrideScopes,
      enrichScopes: [DefaultingScope.SALES_DIFFUSION, DefaultingScope.TRADE_DATE],
      isSettlementOtcOverride: true,
    });
    const chainOptions = {
      success: {
        dispatchables: [
          errorCrudActions.delete(
            internalGetErrorId('maturity')({
              legId,
              strategyId,
            }),
          ),
        ],
      },
      error: {
        dispatchables: [
          createHandleErrorsThunk(
            rfqId,
            getLegMaturityFieldErrors(strategyIds, strategyId, legIds, legId),
          ),
          createUpdateLegMaturityThunk(rfqId, strategyId, legId, undefined),
        ],
      },
    };

    const product = selectors.getProduct(state, legId);
    const underlyingId = selectors.getUnderlyingId(product);

    if (underlyingId && maturity) {
      const infos = selectors.getUnderlyingInfo(state, underlyingId)?.option
        .optionOnFutureMaturityInfo;
      const futureMaturity = infos ? infos[maturity]?.futureMaturity : undefined;
      dispatch(createUpdateProductThunk(strategyId, legId, 'futureMaturity', futureMaturity));
    }

    dispatch(createUpdateLegMaturityThunk(rfqId, strategyId, legId, maturity));
    dispatch(changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, true));
    dispatch(changeClsIsScheduleObsoleteThunk(rfqId, product.uuid, true));
    dispatch(
      createDefaultRfqRequestedAction(rfqId, defaultingOptions, chainOptions, retryOnErrorOptions),
    );
  };
}

function getLegMaturityFieldErrors(
  strategyIds: string[],
  strategyId: string,
  legIds: string[],
  legId: string,
): OnyxFieldError[] {
  return [
    {
      field: `rfq.strategies[${strategyIds.indexOf(strategyId)}].legs[${legIds.indexOf(
        legId,
      )}].product.maturity`,
      message: 'Due to defaulting service error, maturity has been reset',
    },
  ];
}
