import * as common from './common';
import type * as kyc from './kycPredealCheck';
import type * as mifid from './mifidPredealCheck';
import * as pdcWithLegs from './predealCheckWithLegs';
import type * as risk from './riskPredealCheck';

export type DerogationKey = common.DerogationKey;
export type PredealCheckType = common.PredealCheckType;
export type PredealCheckAllocationOrDeltaLevel = common.PredealCheckAllocationOrDeltaLevel;
export type PredealCheckLevel = common.PredealCheckLevel;
export type PredealCheckUiColor = common.PredealCheckUiColor;
export type PredealCheckWithoutLegsType = common.PredealCheckWithoutLegsType;
export type PttPredealCheck = pdcWithLegs.PttPredealCheck;
export type AllocationOrDeltaPredealChecksWithLegs =
  pdcWithLegs.AllocationOrDeltaPredealChecksWithLegs;
export type AllocationOrDeltaPredealCheckWithLegsType =
  pdcWithLegs.AllocationOrDeltaPredealCheckWithLegsType;
export type DfaPredealCheck = pdcWithLegs.DfaPredealCheck;
export type DfaSecPredealCheck = pdcWithLegs.DfaSecPredealCheck;
export type EmirPredealCheck = pdcWithLegs.EmirPredealCheck;
export type KycPredealCheck = kyc.KycPredealCheck;
export type MifidPredealCheck = mifid.MifidPredealCheck;
export type RiskPredealCheck = risk.RiskPredealCheck;
export type RequestKycPredealCheck = kyc.RequestKycPredealCheck;
export type RequestMifidPredealCheck = mifid.RequestMifidPredealCheck;

export type PredealCheck =
  | KycPredealCheck
  | MifidPredealCheck
  | RiskPredealCheck
  | PttPredealCheck
  | AllocationOrDeltaPredealChecksWithLegs;

type AllocationOrDeltaPredealCheckWithoutLegs =
  | kyc.AllocationOrDeltaKycPredealCheck
  | mifid.AllocationOrDeltaMifidPredealCheck
  | risk.RiskAllocationPredealCheck
  | risk.RiskDeltaPredealCheck;

export type AllocationPredealCheck =
  | kyc.AllocationKycPredealCheck
  | mifid.AllocationMifidPredealCheck
  | risk.RiskAllocationPredealCheck
  | pdcWithLegs.AllocationCostAndChargesPredealCheck
  | pdcWithLegs.AllocationDfaPredealCheck
  | pdcWithLegs.AllocationDfaSecPredealCheck
  | pdcWithLegs.AllocationEmirPredealCheck
  | pdcWithLegs.AllocationFatcaPredealCheck
  | pdcWithLegs.AllocationCassPredealCheck
  | pdcWithLegs.AllocationHireActPredealCheck
  | pdcWithLegs.AllocationHkmaPredealCheck
  | pdcWithLegs.AllocationCrossBorderPredealCheck
  | pdcWithLegs.AllocationImVmPredealCheck;

export type DeltaPredealCheck =
  | kyc.DeltaKycPredealCheck
  | mifid.DeltaMifidPredealCheck
  | risk.RiskDeltaPredealCheck
  | pdcWithLegs.DeltaCostAndChargesPredealCheck
  | pdcWithLegs.DeltaDfaPredealCheck
  | pdcWithLegs.DeltaDfaSecPredealCheck
  | pdcWithLegs.DeltaEmirPredealCheck
  | pdcWithLegs.DeltaFatcaPredealCheck
  | pdcWithLegs.DeltaCassPredealCheck
  | pdcWithLegs.DeltaHireActPredealCheck
  | pdcWithLegs.DeltaHkmaPredealCheck
  | pdcWithLegs.DeltaCrossBorderPredealCheck
  | pdcWithLegs.DeltaImVmPredealCheck;

export type AllocationOrDeltaPredealCheck =
  | AllocationOrDeltaPredealChecksWithLegs
  | AllocationOrDeltaPredealCheckWithoutLegs;

export const allocationOrDeltaPredealCheckWithLegsTypes =
  pdcWithLegs.allocationOrDeltaPredealCheckWithLegsTypes;

export const onlyListedAllocationOrDeltaPredealCheckWithLegsTypes =
  pdcWithLegs.onlyListedAllocationOrDeltaPredealCheckWithLegsTypes;

export const predealChecksWithoutLegsTypes = common.predealChecksWithoutLegsTypes;

export type Derogation = {
  pdcUuid: string;
  pdcDerogation: DerogationKey;
} & Pick<Partial<RiskPredealCheck>, 'riskId' | 'derogationComment'>;

export function isAllocationOrDeltaPredealCheckWithLegs(
  predealCheck: PredealCheck,
): predealCheck is AllocationOrDeltaPredealChecksWithLegs {
  return (
    !!allocationOrDeltaPredealCheckWithLegsTypes.find(type => type === predealCheck.type) &&
    (predealCheck.level === 'ALLOCATION' || predealCheck.level === 'DELTA')
  );
}

export function isDfaPredealCheck(predealCheck: PredealCheck): predealCheck is DfaPredealCheck {
  return predealCheck.type === 'DFA';
}

export function isAllocationPredealCheck(
  predealCheck: PredealCheck,
): predealCheck is AllocationPredealCheck {
  return predealCheck.level === 'ALLOCATION';
}

export function isDeltaPredealCheck(predealCheck: PredealCheck): predealCheck is DeltaPredealCheck {
  return predealCheck.level === 'DELTA';
}

export function isRequestLevelExceptPttPredealCheck(
  predealCheck: PredealCheck,
): predealCheck is
  | RequestKycPredealCheck
  | RequestMifidPredealCheck
  | risk.RequestRiskPredealCheck {
  return (
    predealCheck.level === 'REQUEST' &&
    !!predealChecksWithoutLegsTypes.find(type => predealCheck.type === type)
  );
}

export function isPttPredealCheck(predealCheck: PredealCheck): predealCheck is PttPredealCheck {
  return predealCheck.type === 'PTT';
}
