import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function SettlementMethodElection() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );

  if (!isElsProduct(product)) {
    return null;
  }

  const { uuid, settlementMethodElection } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onSettlementMethodElectionChange = () => {
    dispatch(updateProduct(uuid, { settlementMethodElection: !settlementMethodElection }));
  };

  return (
    <ElsBlocField
      label={{ type: 'tooltip', content: 'SME', tooltip: 'Settlement Method Election' }}
      renderInput={readOnly => (
        <Switch
          disabled={readOnly}
          switchClassName="text-center"
          data-e2e="neos-extra-features-els-compound"
          checked={!!settlementMethodElection}
          onChange={() => onSettlementMethodElectionChange()}
        />
      )}
    />
  );
}
