import type { Tenor } from '@/neos/business/referenceData/tenors/tenorModel';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { NumericInput } from '../../../../../share/numericInput';
import type { BarrierModel } from './getBarriersModel';

interface ParamsMaturityAndStrike {
  maturities: string[];
  tenors: Tenor[];
  isEls: boolean;
  onBarrierMaturityChanged: (index: number, maturity: string | undefined) => void;
  onBarrierStrikeChanged: (index: number, limitStrikeValue: number | undefined) => void;
  onBarrierStrikeUnitChanged: (() => void) | undefined;
  onTenorChanged(index: number, tenor: string | undefined): void;
}

interface MaturityAndStrikeComponentProps {
  index: number;
  barrier: BarrierModel;
  params: ParamsMaturityAndStrike;
  readOnly: boolean;
}

export function MaturityAndStrike({
  index,
  readOnly,
  barrier: { maturity, limitStrikeValue, limitStrikeUnit },
  params: {
    maturities,
    tenors,
    isEls,
    onTenorChanged,
    onBarrierMaturityChanged,
    onBarrierStrikeChanged,
    onBarrierStrikeUnitChanged,
  },
}: MaturityAndStrikeComponentProps) {
  return (
    <>
      <FlowDatePicker
        readOnly={readOnly}
        date={maturity || ''}
        hideCalendarIcon
        maturities={maturities}
        tenors={tenors}
        maturitiesCategory={isEls || maturities.length === 0 ? 'TENORS' : 'LISTED'}
        onChangeTenor={tenor => onTenorChanged(index, tenor)}
        onChange={(date: string) => onBarrierMaturityChanged(index, date)}
        data-e2e="neos-extra-features-barrier-maturity"
      />
      <NumericInput
        readOnly={readOnly}
        value={limitStrikeValue}
        unit={limitStrikeUnit}
        onBlur={(value: number) => onBarrierStrikeChanged(index, value)}
        onUnitClick={onBarrierStrikeUnitChanged}
        data-e2e="neos-extra-features-barrier-strike"
      />
    </>
  );
}
