import { createIntegrateReceivedBookingStepThunk } from '@/neos/business/bookingSteps/integrateReceivedBookingStepThunk';
import { currentUserPreferencesThunks } from '@/neos/business/currentUserPreferences/userPreferencesDefaultingThunks';
import { createDefaultStrategyTypeRequestThunk } from '@/neos/business/rfq/thunks/defaultStrategyTypeRequestedThunk';
import { createUpdateProductAndReferenceWithImportedBasketDetailsThunk } from '@/neos/business/thunks/updateProductAndReferenceWithImportedBasketDetailsThunk';
import { createImportBasketCompositionThunk } from '@/neos/business/thunks/importBasketCompositionThunk';
import { createAddOtcAllocationThunk } from '@/neos/components/rfq/postNego/allocs/otcAllocations/addOtcAllocationThunk';
import { createUpdateNovationFeeThunk } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/novationsFields/updateNovationFeeThunk';
import { createUpdateSecondaryAmountDueThunk } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/updateSecondaryAmountDueThunk';
import { createUpdateSecondaryInfoThunk } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/updateSecondaryInfoThunk';
import { createUpdateStepInInfoThunk } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/updateStepInInfoThunk';
import { createPreconfirmationEmailPreviewRequestedThunk } from '@/neos/components/rfq/preconfirmation/preconfirmationModal/preconfirmationEmailPreviewRequestedThunk';
import { createSendPreconfirmationEmailRequestedThunk } from '@/neos/components/rfq/preconfirmation/preconfirmationModal/sendPreconfirmationEmailRequestedThunk';
import { createActivateStrategyFlexThunk } from '../../components/rfq/strategies/strategy/strategyDefinition/optionFlexCells/createActivateStrategyFlexThunk';
import { alertThunks } from '../alert/thunks';
import { createIntegrateReceivedBlotterPreconfThunk } from '../blotter/blotterPreconf/integrateReceivedBlotterPreconfThunk';
import { createAddRfqFromNotificationToBlotterThunk } from '../blotter/thunks/addBlotterRfqToBlotter';
import { createCopyCurrentTypeaheadInActiveTypeahead } from '../blotter/thunks/copyCurrentTypeaheadInActiveTypeahead';
import { createIntegrateReceivedBlotterRfqsThunk } from '../blotter/thunks/integreateReceiveBlotterRfqs';
import { createRequestBlotterThunk } from '../blotter/thunks/requestBlotter';
import { executionThunks } from '../order/execution/thunks';
import { createAddAllocationThunk } from '../order/execution/thunks/addAllocationThunk';
import { orderThunks } from '../order/thunks';
import { createPreConfirmationNotificationReceivedThunk } from '../preConfirmationStep/preConfirmationNotificationReceivedThunk';
import { createExternalPreconfirmationEmailSendThunk } from '../preconfirmation/external/externalPreconfirmationEmailSendThunks';
import { createIntegrateExternalPreconfirmationThunk } from '../preconfirmation/external/integrateExternalPreconfirmationThunk';
import { createExternalPreconfirmationPreviewAttachmentThunks } from '../preconfirmation/external/preconfirmationPreviewAttachmentThunks';
import { createIntegratePreconfirmationThunk } from '../preconfirmation/integratePreconfirmationThunk';
import { createIntegrateInternalPreconfirmationThunk } from '../preconfirmation/internal/integrateInternalPreconfirmationThunk';
import { createInternalPreconfirmationEmailSendThunk } from '../preconfirmation/internal/internalPreconfirmationEmailSendThunks';
import { createInternalPreconfirmationPreviewAttachmentThunks } from '../preconfirmation/internal/internalPreconfirmationPreviewAttachmentThunks';
import { predealChecksThunks } from '../predealCheck/thunks';
import { createGetPrimeBrokerListThunk } from '../referenceData/primeBroker/getPrimeBrokerListThunk';
import { referenceDataThunks } from '../referenceData/referenceDataThunks';
import { createSimilarActivitiesSecondaryTypesOnLegsThunk } from '../rfq/strategy/leg/thunks/createSimilarActivitiesSecondaryTypesThunk';
import { createResetSecondaryTypesOnLegsThunk } from '../rfq/strategy/leg/thunks/resetSecondaryTypesThunk';
import { rfqThunks } from '../rfq/thunks';
import { neosUiThunks } from '../ui/thunks';
import { createAddTabThunk } from './addTab';
import { createApplyNextUserActionThunk } from './applyNextUserActionThunk';
import { createHandleAutoCloseTabThunk } from './autoCloseTabThunk';
import { createAutoSelectDeltaAdjOrRefTabThunk } from './autoSelectDeltaAdjOrRefTabThunk';
import { createCloseTabThunk } from './closeTab';
import { createClsImportScheduleDataThunk } from './clsImportScheduleDataThunk';
import { createCreateLegsSecondaryEventThunk } from './createLegsSecondaryEventThunk';
import { createDeleteTransactionThunk } from './deleteTransaction';
import { elsThunks } from './elsThunks';
import { createEnsureAllocExtraCounterpartsLoadedThunk } from './ensureAllocExtraCounterpartsLoadedThunk';
import { createEnsureUniqueCounterpartsLoadedThunk } from './ensureUniqueCounterpartsLoadedThunk';
import { createFinalizeRfqSavingDoneThunk } from './finalizeRfqSavingDoneThunk';
import { createFinalizeTransactionsLoadThunk } from './finalizeTransactionsLoadThunk';
import { createHandleErrorsThunk } from './handleErrors';
import { createHandleTransactionCancelErrorThunk } from './handleTransactionCancelError';
import { createHandleTransactionSaveFailureThunk } from './handleTransactionSaveFailure';
import { createIntegrateNextUserActions } from './integrateNextUserActions';
import { createIntegratePredealChecksRefreshThunk } from './integratePredealChecksRefresh';
import { createLoadTransactionFirstStepThunk } from './loadTransactionFirstStep';
import { createLoadTransactionsThunk } from './loadTransactions';
import { createNeosBlurProductUnderlyingThunk } from './neosBlurProductUnderlying';
import { createNeosCancelRfqThunk } from './neosCancelRfq';
import { createNeosChangeBarrierUnderlyingThunk } from './neosChangeBarrierUnderlying';
import { createNeosChangeRfqUnderlyingThunk } from './neosChangeRfqUnderlying';
import { createNeosChangeStrategiesTypesThunk } from './neosChangeStrategiesTypes';
import { createNeosChangeStrategyTopUnderlyingThunk } from './neosChangeStrategyTopUnderlying';
import { createNeosCloneRfqThunk } from './neosCloneRfq';
import { createNeosCloneStrategyThunk } from './neosCloneStrategy';
import { createNeosDisplayRefWarningThunk } from './neosDisplayRefWarning';
import { createNeosHideCompositionLegsModalThunk } from './neosHideCompositionLegsModalThunk';
import { createNeosIntegrateRfqDeltaDifferenceNotificationThunk } from './neosIntegrateRfqDeltaDifferenceNotification';
import { createNeosIntegrateRfqNotificationThunk } from './neosIntegrateRfqNotification';
import { createNeosIntegrateRfqTraderQuotesDifferenceNotificationThunk } from './neosIntegrateRfqTraderQuotesDifferenceNotification';
import { createNeosLoadNotificationRfqThunk } from './neosLoadNotificationRfq';
import { createNeosLoadRfqThunk } from './neosLoadRfq';
import { createNeosLoadUnderlyingInfoAndChangeThunk } from './neosLoadUnderlyingInfoAndChange';
import { createNeosReplaceCurrentByNotificationRfqThunk } from './neosReplaceCurrentByNotificationRfq';
import { createNeosSaveRfqThunk } from './neosSaveRfq';
import { createNeosSwitchNegoStepThunk } from './neosSwitchNegoStep';
import { createNeosToggleStrategyShowLegPricesThunk } from './neosToggleStrategyShowLegPrices';
import { createPredealCheckNotificationReceivedThunk } from './predealCheckNotificationReceivedThunk';
import { createRequestBulkCopyQuoteRecapToClipboardThunk } from './requestBulkCopyQuoteRecapToClipboard';
import { createRequestBulkCopyTradeRecapToClipboardThunk } from './requestBulkCopyTradeRecapToClipboard';
import {
  createRequestBulkManualPricingThunk,
  createRequestManualPricingFromRfqThunk,
} from './requestManualPricing';
import { createRequestMissingUnderlyingsThunk } from './requestMissingUnderlyingsThunk';
import { createRequestResubscribeThunk } from './requestResubscribeThunk';
import { createRequestTransactionSaveThunk } from './requestTransactionSave';
import { createRequestTransactionSaveWithVersionThunk } from './requestTransactionSaveWithVersion';
import { createSaveTransactionThunk } from './saveTransaction';
import { createSelectNeosApplicationThunk } from './selectNeosApplication';
import { createSubscribeToNeosNotifications } from './subscribeToNeosNotifications';
import { createHandleBasketUnderlyingsReceivedThunk } from '@/neos/business/thunks/handleBasketUnderlyingsReceivedThunk';
import { createUpdateOtcAllocSizeThunk } from '@/neos/business/thunks/otcAllocationSizeThunk.ts';

export const neosThunks = {
  ...rfqThunks,
  ...neosUiThunks,
  ...predealChecksThunks,
  ...referenceDataThunks,
  ...alertThunks,
  ...orderThunks,
  ...executionThunks,
  ...elsThunks,
  createResetSecondaryTypesOnLegsThunk,
  createSimilarActivitiesSecondaryTypesOnLegsThunk,
  createIntegrateReceivedBookingStepThunk,
  createNeosCloneStrategyThunk,
  createSelectNeosApplicationThunk,
  createHandleAutoCloseTabThunk,
  createCloseTabThunk,
  createNeosSaveRfqThunk,
  createNeosCancelRfqThunk,
  createHandleErrorsThunk,
  createLoadTransactionsThunk,
  createSaveTransactionThunk,
  createDeleteTransactionThunk,
  createNeosToggleStrategyShowLegPricesThunk,
  createHandleTransactionSaveFailureThunk,
  createHandleTransactionCancelErrorThunk,
  createNeosChangeRfqUnderlyingThunk,
  createLoadTransactionFirstStepThunk,
  createFinalizeTransactionsLoadThunk,
  createRequestTransactionSaveThunk,
  createRequestTransactionSaveWithVersionThunk,
  createAddTabThunk,
  createNeosCloneRfqThunk,
  createNeosLoadRfqThunk,
  createNeosDisplayRefWarningThunk,
  createRequestBulkCopyQuoteRecapToClipboardThunk,
  createRequestBulkCopyTradeRecapToClipboardThunk,
  createIntegratePredealChecksRefreshThunk,
  createNeosChangeStrategiesTypesThunk,
  createDefaultStrategyTypeRequestThunk,
  createNeosIntegrateRfqNotificationThunk,
  createNeosIntegrateRfqDeltaDifferenceNotificationThunk,
  createNeosIntegrateRfqTraderQuotesDifferenceNotificationThunk,
  createNeosReplaceCurrentByNotificationRfqThunk,
  createNeosLoadNotificationRfqThunk,
  createRequestBulkManualPricingThunk,
  createRequestManualPricingFromRfqThunk,
  createAddBlotterRfqToBlotterThunk: createAddRfqFromNotificationToBlotterThunk,
  createRequestBlotterThunk,
  createCreateSecondaryEventThunk: createCreateLegsSecondaryEventThunk,
  createIntegrateNextUserActions,
  createApplyNextUserActionThunk,
  createNeosBlurProductUnderlyingThunk,
  createNeosChangeBarrierUnderlyingThunk,
  createRequestResubscribeThunk,
  createRequestMissingUnderlyingsThunk,
  createEnsureUniqueCounterpartsLoadedThunk,
  createEnsureAllocExtraCounterpartsLoadedThunk,
  createNeosLoadUnderlyingInfoAndChangeThunk,
  createNeosChangeStrategyTopUnderlyingThunk,
  createAutoSelectDeltaAdjOrRefTabThunk,
  createSubscribeToNeosNotifications,
  createNeosHideCompositionLegsModalThunk,
  createCopyCurrentTypeaheadInActiveTypeahead,
  createNeosSwitchNegoStepThunk,
  createPredealCheckNotificationReceivedThunk,
  createFinalizeRfqSavingDoneThunk,
  createIntegrateExternalPreconfirmationThunk,
  createIntegrateInternalPreconfirmationThunk,
  createExternalPreconfirmationPreviewAttachmentThunks,
  createInternalPreconfirmationPreviewAttachmentThunks,
  createExternalPreconfirmationEmailSendThunk,
  createInternalPreconfirmationEmailSendThunk,
  createIntegratePreconfirmationThunk,
  createIntegrateReceivedBlotterRfqsThunk,
  createIntegrateReceivedBlotterPreconfThunk,
  createActivateStrategyFlexThunk,
  createGetPrimeBrokerListThunk,
  createUpdateOtcAllocSizeThunk,
  createUpdateSecondaryInfoThunk,
  createUpdateStepInInfoThunk,
  createUpdateSecondaryAmountDueThunk,
  createUpdateNovationFeeThunk,
  createAddAllocationThunk,
  createAddOtcAllocationThunk,
  createPreconfirmationEmailPreviewRequestedThunk,
  createSendPreconfirmationEmailRequestedThunk,
  createClsImportScheduleDataThunk,
  createPreConfirmationNotificationReceivedThunk,
  ...currentUserPreferencesThunks,
  createImportBasketCompositionThunk,
  createUpdateProductAndReferenceWithImportedBasketDetailsThunk,
  createHandleBasketUnderlyingsReceivedThunk,
};
