import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { neosThunks } from '@/neos/business/thunks';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { FlowDatePickerWithOffset } from '@/neos/components/share/datePicker/FlowDatePickerWithOffset';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { useDispatch, useSelector } from 'react-redux';
import {
  type IsSettlementDateCellsEditableModel,
  getIsSettlementDateCellsEditableModel,
} from './getIsSettlementDateCellsEditableModel';
import {
  type SettlementDateCellsModel,
  getSettlementDateCellsModel,
} from './getSettlementDateCellsModel';

export interface SettlementDateCellsOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

type SettlementDateCellsProps = SettlementDateCellsOwnProps;

export const SettlementDateCells = ({
  rfqId,
  strategyId,
  isReadOnlyRfq,
}: SettlementDateCellsProps) => {
  const dispatch = useDispatch();

  const datePickerWithOffsetToggle = useFeatureToggle('neos.datepicker.with.offset');

  function onSettlementDateChange(selectedDate: string) {
    dispatch(neosThunks.createUpdateSettlementDateThunk(rfqId, legId, selectedDate));
  }

  function onSettlementOffsetChange(selectedOffset: number) {
    dispatch(neosThunks.createUpdateSettlementDateOffsetThunk(rfqId, legId, selectedOffset));
  }

  const { settlementDate, settlementDateOffset, isDisplayed, legId }: SettlementDateCellsModel =
    useSelector((state: AppState) => getSettlementDateCellsModel(state, strategyId, selectors));

  const isEditable: IsSettlementDateCellsEditableModel = useSelector((state: AppState) =>
    getIsSettlementDateCellsEditableModel(state, { strategyId, rfqId }, selectors),
  );

  if (!isDisplayed) {
    return null;
  }

  return (
    <section>
      <ErrorHighlight errorField={'settlementDate'} related={{ rfqId, strategyId, legId }}>
        {datePickerWithOffsetToggle ? (
          <FlowDatePickerWithOffset
            data-e2e="neos-strategy-settlement-date"
            date={settlementDate ?? ''}
            onChange={onSettlementDateChange}
            onOffsetChange={onSettlementOffsetChange}
            currentOffset={settlementDateOffset}
            readOnly={isReadOnlyRfq || !isEditable}
            hideCalendarIcon
          />
        ) : (
          <FlowDatePicker
            data-e2e="neos-strategy-settlement-date"
            date={settlementDate ?? ''}
            maturities={[]}
            onChange={onSettlementDateChange}
            readOnly
            hideCalendarIcon
          />
        )}
      </ErrorHighlight>
    </section>
  );
};
