import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import {
  initialStockLoanHedge,
  isElsProduct,
  type StockLoanComponent,
  type StockLoanHedge,
} from '@/neos/business/neosModel';
import { idCreator } from '@/util/id';

export function createAddNewElsStockLoanComponentThunk(productId: string): Thunk {
  return function addNewElsStockLoanComponentThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsProduct(product)) {
      return;
    }

    const newLine: StockLoanComponent = {
      uuid: idCreator.createId(),
      allIn: {
        value: undefined,
        unit: '%',
        type: 'REF_PERCENT',
      },
      borrowPortfolio: undefined,
      dividendRequirement: {
        value: undefined,
        unit: '%',
        type: 'REF_PERCENT',
      },
      fees: {
        value: undefined,
        unit: 'bp',
        type: 'BIPS',
      },
      lendPortfolio: undefined,
      quantity: undefined,
      underlyingId: undefined,
    };

    const oldStockLoanHedge: StockLoanHedge = product.stockLoanHedge ?? initialStockLoanHedge;

    const dispatchables: Dispatchable[] = [
      actionCreators.neos.productCrudActions.update(product.uuid, {
        stockLoanHedge: {
          ...oldStockLoanHedge,
          stockLoanComponents: oldStockLoanHedge.stockLoanComponents.concat(newLine),
        },
      }),
    ];

    dispatch(dispatchables);
  };
}
