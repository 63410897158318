import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import { assertProductIsEls } from '../../../../neos/business/neosModel';

export function createRemoveAllEquitySchedulePeriodThunk(strategyId: string): Thunk {
  return function removeAllEquitySchedulePeriodThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();

    const product = selectors.getStrategyProduct(appState, strategyId, selectors);
    assertProductIsEls(product);
    const action: Action = productCrudActions.update(product.uuid, {
      equityPeriods: [],
    });
    dispatch(action);
  };
}
