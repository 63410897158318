import { crudDomain } from '@/util/crudUtils';
import type { Reference, ReferenceKey } from './referenceModel';

const compositeIdSeparator = ' | ';

function getReferenceId({ rfqId, underlyingId }: ReferenceKey): string {
  return `${rfqId}${compositeIdSeparator}${underlyingId}`;
}

function getReferenceKey(id: string): ReferenceKey {
  const [rfqId, underlyingId] = id.split(compositeIdSeparator);
  return { rfqId, underlyingId };
}

export const referenceDomain = crudDomain<Reference, ReferenceKey>('reference-data', {
  keyToString: getReferenceId,
  stringToKey: getReferenceKey,
});
