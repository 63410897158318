import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import { DefaultingScope, initialStockLoanHedge, isElsProduct } from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';

export function createToggleElsLendAndBorrowFeatureThunk(rfqId: string, productId: string): Thunk {
  return function toggleElsLendAndBorrowFeatureThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsProduct(product)) {
      return;
    }

    const dispatchables: Dispatchable[] = [];

    if (product.stockLoanHedge === undefined) {
      dispatchables.push(
        actionCreators.neos.productCrudActions.update(product.uuid, {
          stockLoanHedge: initialStockLoanHedge,
        }),
        actionCreators.neos.createDefaultRfqRequestedAction(
          rfqId,
          new DefaultingOptions({
            overrideScopes: [DefaultingScope.LEND_BORROW],
            enrichScopes: [DefaultingScope.UNDERLYING],
          }),
        ),
      );
    } else {
      dispatchables.push(
        actionCreators.neos.productCrudActions.update(product.uuid, {
          stockLoanHedge: undefined,
          hedgeComment: undefined,
        }),
      );
    }

    dispatch(dispatchables);
  };
}
