import { DefaultingOptions } from '../rfqActions';

import type { Thunk } from '@/bootstrap/thunks';
import type { ChainOptions, RetryOnErrorOptions } from '@/neos/business/neosActions';
import { DefaultingScope } from '../models';

export function createDefaultStrategyTypeRequestThunk(
  rfqId: string,
  strategyIds: string[],
  strategyType: string,
  chainOptions?: ChainOptions,
): Thunk {
  return function neosChangeStrategiesTypesThunk(
    dispatch,
    getState,
    {
      selectors: { isFeatureToggleEnabled },
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
    },
  ) {
    const state = getState();

    const overrideScopes = [
      DefaultingScope.TRADER_DIFFUSION,
      DefaultingScope.DELIVERY_TYPE,
      DefaultingScope.DETERMINATION_METHOD,
      DefaultingScope.UNDERLYING,
      DefaultingScope.OPTION_STYLE,
      DefaultingScope.CAP,
      DefaultingScope.NEGOTIATED_SIZE,
      DefaultingScope.NOTIONAL,
      DefaultingScope.DELTAS,
      DefaultingScope.UNITS,
      DefaultingScope.EXPECTED_N,
      DefaultingScope.SWAP_CURRENCY,
      DefaultingScope.TRADED_AWAY_FEEDBACK,
      DefaultingScope.OBSERVABLE_TYPE,
      DefaultingScope.ROLE_DEFINITION,
      DefaultingScope.EFFECTIVE_DATE,
    ];
    const enrichScopes = [
      DefaultingScope.FOREX,
      DefaultingScope.SALES_DIFFUSION,
      DefaultingScope.TRADE_DATE,
    ];

    if (strategyType === 'DIVIDEND_FUTURE') {
      overrideScopes.push(DefaultingScope.DIVIDEND_FUTURE_DELTA);
    }

    if (strategyType === 'ELS') {
      if (isFeatureToggleEnabled(state, 'neos.els.scope.Interest.Rate.Index.enabled')) {
        overrideScopes.push(DefaultingScope.INTEREST_RATE_INDEX);
        overrideScopes.push(DefaultingScope.CALCULATION_METHOD);
      }

      overrideScopes.push(DefaultingScope.STRIKE_DATE);
      overrideScopes.push(DefaultingScope.MATURITY_DATE);
      overrideScopes.push(DefaultingScope.RESET_FREQUENCY);
      overrideScopes.push(DefaultingScope.RATE_RESET);
      enrichScopes.push(DefaultingScope.SPOT_NET);
    }

    const options = new DefaultingOptions({
      isSettlementOtcOverride: true,
      overrideScopes,
      enrichScopes,
    });

    options.strategiesFilterer = strategies =>
      strategies.filter(strategy => strategyIds.includes(strategy.uuid));

    const retryOnErrorOptions: RetryOnErrorOptions = {
      retries: 1,
    };
    dispatch(createDefaultRfqRequestedAction(rfqId, options, chainOptions, retryOnErrorOptions));
  };
}
