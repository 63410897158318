import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

interface LegMid {
  id: string;
  mid: number | undefined;
}

export interface MidModel {
  mids: LegMid[];
}
export function getMidModel(state: AppState, strategyId: string, selectors: Selectors): MidModel {
  const compositionLegs = selectors.getStrategyCompositionLegsData(state, strategyId, selectors);
  const mids: LegMid[] = compositionLegs.map(({ uuid, fairPriceId }) => {
    const { mid } = fairPriceId ? selectors.getFairPrice(state, fairPriceId) : { mid: undefined };
    return {
      id: uuid,
      mid,
    };
  });

  return { mids };
}
