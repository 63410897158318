import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { type FC, useRef, useState } from 'react';
import { Modal, Tooltip } from 'react-bootstrap';

import { exportDataToExcel, importExcelFileContent } from '@/util/excel/excel';
import type { ImportedCompositionLegsData } from '@/neos/business/rfq/thunks/rfqApplyImportedCompositionLegsThunk';
import { ErrorHighlight } from '../../../../share/errorHighlight';
import { HideAfter } from '../../../../share/hideAfter/HideAfter';
import { NumericInput } from '../../../../share/numericInput';
import type { TypeaheadValue } from '../../../../share/typeahead';
import { UnderlyingInput } from '../common';
import type { CompositionLeg, CompositionLegsModalModel } from './getCompositionLegsModalModel';
import { Mid } from './quotes/mid/Mid.container';
import { SalesAsk } from './quotes/salesAsk/SalesAsk.container';
import { SalesBid } from './quotes/salesBid/SalesBid.container';
import { TraderAsk } from './quotes/traderAsk/TraderAsk.container';
import { TraderBid } from './quotes/traderBid/TraderBid.container';

import styles from './CompositionLegsModal.module.scss';

export interface CompositionLegsModalOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface CompositionLegsModalStateProps {
  showModal: boolean;
  model: CompositionLegsModalModel;
}

export interface CompositionLegsModalDispatchProps {
  hideModal(): void;
  onUnderlyingAdded(underlyingId: string): void;
  onFormDefaulted(): void;
  onLegRemoved(legId: string): void;
  onLegUnderlyingChanged(productId: string, underlyingId: string | undefined): void;
  onWeightChanged(productId: string, weight: number | undefined): void;
  onSizeChanged(legId: string, size: number | undefined): void;
  onSpotChanged(underlyingId: string, spot: number | undefined): void;
  onExpectedNChanged(productId: string, expectedN: number | undefined): void;
  onImportData(data: ImportedCompositionLegsData[]): void;
  onCloseDataWarning(): void;
  onCloseIndexWarning(): void;
  onCloseMaturityWarning(): void;
  onHideImportedCountMessage(): void;
}

export type CompositionLegsModalProps = CompositionLegsModalOwnProps &
  CompositionLegsModalStateProps &
  CompositionLegsModalDispatchProps;

export const CompositionLegsModalComponent: FC<CompositionLegsModalProps> = ({
  showModal,
  hideModal,
  onUnderlyingAdded,
  onFormDefaulted,
  onLegRemoved,
  onLegUnderlyingChanged,
  onWeightChanged,
  onSizeChanged,
  onSpotChanged,
  onExpectedNChanged,
  onImportData,
  onCloseDataWarning,
  onCloseIndexWarning,
  onCloseMaturityWarning,
  onHideImportedCountMessage,
  strategyId,
  rfqId,
  model,
}: CompositionLegsModalProps) => {
  const [underlyingId, setUnderlyingId] = useState('');
  const handleUnderlyingId = (underlyingId: string) => {
    setUnderlyingId(underlyingId);
  };

  const isUnderlyingAddButtonDisabled =
    !underlyingId ||
    model.compositionLegs.map(compositionLeg => compositionLeg.underlyingId).includes(underlyingId);

  return (
    <Modal
      show={showModal}
      onHide={() => hideModal()}
      onKeyPress={(event: any) => event.charCode === 13 && hideModal()}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="ps-32px">{model.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImportExportButtons />
        <div className="d-flex justify-content-between ms-32px mb-3">
          <div
            className="d-grid"
            style={{ gridTemplateColumns: 'minmax(100px, 200px) fit-content(100%)' }}
          >
            <UnderlyingInput
              componentId={`neos-underlying-${strategyId}`}
              underlyingId={underlyingId}
              bloombergCode={undefined}
              underlyingTypes={model.underlyingTypes}
              data-e2e="neos-composition-add-underlying-input"
              onChange={(val: TypeaheadValue | undefined) =>
                val?.value && handleUnderlyingId(val?.value)
              }
            />
            <button
              className="btn btn-primary"
              disabled={isUnderlyingAddButtonDisabled}
              onClick={() => onUnderlyingAdded(underlyingId)}
              data-e2e="neos-composition-add-underlying-btn"
            >
              Add
            </button>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => onFormDefaulted()}
            data-e2e="neos-composition-defaulting"
          >
            Apply Defaulting
          </button>
        </div>

        <div className="d-grid gap-1 overflow-auto" style={{ gridTemplateColumns: '60% 38%' }}>
          <div className="d-grid gap-1 mb-3" style={{ gridTemplateColumns: '32px repeat(5, 1fr)' }}>
            <label></label>
            <label className="fw-bold">Underlying</label>
            <label className="fw-bold">Weight</label>
            <label className="fw-bold">Size</label>
            <label className="fw-bold">Spot</label>
            <label className="fw-bold">Exp N</label>
            {model.compositionLegs.map(compositionLeg => (
              <GridRow compositionLeg={compositionLeg} key={compositionLeg.legId} />
            ))}
          </div>
          <div
            className={`d-grid gap-1 mb-3 ${styles['composition-legs-prices']}`}
            style={{
              gridTemplateColumns: `1.5fr repeat(${model.areSalesPricesDisplayed ? 4 : 2}, 1fr)`,
            }}
          >
            {model.areSalesPricesDisplayed && <SalesBid rfqId={rfqId} strategyId={strategyId} />}
            <TraderBid rfqId={rfqId} strategyId={strategyId} />
            <Mid rfqId={rfqId} strategyId={strategyId} />
            <TraderAsk rfqId={rfqId} strategyId={strategyId} />
            {model.areSalesPricesDisplayed && <SalesAsk rfqId={rfqId} strategyId={strategyId} />}
          </div>
        </div>

        <div>
          {model.lastImportTime && (
            <HideAfter
              key={model.lastImportTime}
              delay={6000}
              onHide={() => onHideImportedCountMessage()}
            >
              <div key={model.lastImportTime} className="alert alert-sm alert-success mb-1">
                <i className="icon me-2">info</i>
                {model.lastImportRowCount} line{model.lastImportRowCount === 1 ? '' : 's'}
                successfully imported.
              </div>
            </HideAfter>
          )}
          {model.missingDataWarningMessage && (
            <div className="alert alert-sm alert-outline-warning mb-1">
              <i className="icon icon-sm me-2">warning</i>
              {model.missingDataWarningMessage}
              <NeosTooltip
                placement="top"
                overlay={
                  <Tooltip id="close-composition-warning" style={{ zIndex: 100000 }}>
                    Close
                  </Tooltip>
                }
              >
                <button
                  className="btn btn-icon btn-flat-primary ms-auto text-warning"
                  onClick={onCloseDataWarning}
                >
                  <i className="icon icon-sm">clear</i>
                </button>
              </NeosTooltip>
            </div>
          )}
          {model.displayCompoImportHasMultipleIndicesWarning && (
            <div className="alert alert-sm alert-outline-warning mb-1">
              <i className="icon icon-sm me-2">warning</i>
              Imported index leg is ignored. File includes multiple index underlyings.
              <NeosTooltip
                placement="top"
                overlay={
                  <Tooltip id="close-index-composition-warning" style={{ zIndex: 100000 }}>
                    Close
                  </Tooltip>
                }
              >
                <button
                  className="btn btn-icon btn-flat-primary ms-auto text-warning"
                  onClick={onCloseIndexWarning}
                >
                  <i className="icon icon-sm">clear</i>
                </button>
              </NeosTooltip>
            </div>
          )}
          {model.displayCompoImportHasMultipleMaturitiesWarning && (
            <div className="alert alert-sm alert-outline-warning mb-1">
              <i className="icon icon-sm me-2">warning</i>
              Imported maturity is ignored. File contains different values.
              <NeosTooltip
                placement="top"
                overlay={
                  <Tooltip id="close-index-composition-warning" style={{ zIndex: 100000 }}>
                    Close
                  </Tooltip>
                }
              >
                <button
                  className="btn btn-icon btn-flat-primary ms-auto text-warning"
                  onClick={onCloseMaturityWarning}
                >
                  <i className="icon icon-sm">clear</i>
                </button>
              </NeosTooltip>
            </div>
          )}
          {model.warningMessages.map(message => {
            return (
              <div key={message} className="alert alert-sm alert-outline-warning mb-1">
                <i className="icon icon-sm me-2">warning</i>
                {message}
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );

  function GridRow({ compositionLeg }: { compositionLeg: CompositionLeg }) {
    return (
      <>
        {compositionLeg.canDeleteLeg ? (
          <button
            className="btn btn-icon btn-flat-primary"
            onClick={() => onLegRemoved(compositionLeg.legId)}
          >
            <i className="icon icon-md">delete_forever</i>
          </button>
        ) : (
          <div></div>
        )}
        <ErrorHighlight
          key={compositionLeg.legId}
          errorField={'underlying'}
          related={{ rfqId, legId: compositionLeg.legId, strategyId }}
        >
          <UnderlyingInput
            componentId={`neos-composition-underlying-${compositionLeg.legId}`}
            underlyingId={compositionLeg.underlyingId}
            bloombergCode={compositionLeg.bloombergCode}
            underlyingTypes={model.underlyingTypes}
            positionFixed={true}
            data-e2e="neos-strategy-definition-underlying"
            onChange={(val: TypeaheadValue | undefined) =>
              onLegUnderlyingChanged(compositionLeg.productId, val?.value)
            }
            className="menu-in-modal"
          />
        </ErrorHighlight>
        <NumericInput
          unit="%"
          value={compositionLeg.weight}
          onBlur={value => onWeightChanged(compositionLeg.productId, value)}
          maximumFractionDigits={2}
          onlyPositiveNumbers
        />
        <NumericInput
          value={compositionLeg.size}
          unit={compositionLeg.sizeUnit}
          onBlur={value => onSizeChanged(compositionLeg.legId, value)}
          maximumFractionDigits={2}
        />
        <NumericInput
          value={compositionLeg.spot}
          readOnly={compositionLeg.spotDisabled}
          onBlur={value => onSpotChanged(compositionLeg.underlyingId!, value)}
        />
        <NumericInput
          value={compositionLeg.expectedN}
          onBlur={value => onExpectedNChanged(compositionLeg.productId, value)}
        />
      </>
    );
  }

  function ImportExportButtons() {
    const uploadInputRef = useRef<HTMLInputElement>(null);
    return (
      <div className="d-flex ms-32px mb-3">
        <button className="btn btn-flat-primary" onClick={() => uploadInputRef.current?.click()}>
          <i className="icon me-2">attach_file</i> Import data
          <input
            ref={uploadInputRef}
            id="inputUpload"
            className="d-none"
            type="file"
            accept=".xlsx"
            onChange={e => importExcelFileContent(e, onImportData)}
          />
        </button>
        <button
          className="btn btn-flat-primary"
          onClick={() => exportDataToExcel(model.exportedContent, model.title)}
        >
          <i className="icon me-2">file_download</i> Export data
        </button>
      </div>
    );
  }
};
