import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type {
  DividendLegPeriodDates,
  EquityLegPeriodDates,
  RateLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';

export function createImportScheduleLegsDataThunk(
  strategyId: string,
  importedData: string[][],
): Thunk {
  return function importScheduleLegsDataThunk(
    dispatch,
    getState,
    { selectors: { getFeature }, thunks: { neos: neosThunks }, actionCreators },
  ) {
    const state = getState();
    const dispatchables: Dispatchable[] = [];

    const hasRateOverNightFeature = getFeature(state.featureState, {
      strategyId,
      type: 'RATE_OVERNIGHT',
    })?.rateOvernight;

    dispatch(
      actionCreators.neos.strategyUiCrudActions.update(strategyId, {
        showImportScheduleError: false,
      }),
    );
    dispatchables.push(neosThunks.createRemoveAllEquitySchedulePeriodThunk(strategyId));
    dispatchables.push(neosThunks.createRemoveAllRateSchedulePeriodThunk(strategyId));
    dispatchables.push(
      neosThunks.createRemoveAllDividendSchedulePeriodThunk({
        type: 'DIVIDEND_COMPONENT',
        strategyId,
      }),
    );

    importedData.forEach(line => {
      const equityDates: EquityLegPeriodDates = {
        startDate: convertFieldToDateStringOrEmpty(line[0]),
        endDate: convertFieldToDateStringOrEmpty(line[1]),
        paymentDate: convertFieldToDateStringOrEmpty(line[2]),
      };
      dispatchables.push(neosThunks.createAddEquitySchedulePeriodThunk(strategyId, equityDates));

      const rateOverNightDates: RateLegPeriodDates = {
        firstFixingDate: convertFieldToDateStringOrEmpty(line[3]),
        fixingDate: convertFieldToDateStringOrEmpty(line[4]),
        startDate: convertFieldToDateStringOrEmpty(line[5]),
        endDate: convertFieldToDateStringOrEmpty(line[6]),
        paymentDate: convertFieldToDateStringOrEmpty(line[7]),
      };
      const rateWithoutOverNightDates: RateLegPeriodDates = {
        fixingDate: convertFieldToDateStringOrEmpty(line[3]),
        startDate: convertFieldToDateStringOrEmpty(line[4]),
        endDate: convertFieldToDateStringOrEmpty(line[5]),
        paymentDate: convertFieldToDateStringOrEmpty(line[6]),
      };
      dispatchables.push(
        neosThunks.createAddRateSchedulePeriodThunk(
          strategyId,
          hasRateOverNightFeature ? rateOverNightDates : rateWithoutOverNightDates,
        ),
      );

      const dividendDates: DividendLegPeriodDates = {
        startDate: convertFieldToDateStringOrEmpty(line[7]),
        endDate: convertFieldToDateStringOrEmpty(line[8]),
        paymentDate: convertFieldToDateStringOrEmpty(line[9]),
        theoreticalPeriodPaymentDate: convertFieldToDateStringOrEmpty(line[10]),
      };
      dispatchables.push(
        neosThunks.createAddDividendSchedulePeriodThunk(strategyId, dividendDates),
      );
    });
    dispatch(dispatchables);
  };
}

function convertFieldToDateStringOrEmpty(x: string | undefined): string {
  if (x && Date.parse(x)) {
    return x;
  }
  return '';
}
