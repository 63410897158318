import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { RateLegPeriodDates } from '../rfq/strategy/leg/product/elsProductOnyxModel';

export function createClsImportScheduleDataThunk(
  strategyId: string,
  importedData: string[][],
): Thunk {
  return function clsImportScheduleDataThunk(
    dispatch,
    _,
    { thunks: { neos: neosThunks }, actionCreators },
  ) {
    const dispatchables: Dispatchable[] = [];

    dispatch(
      actionCreators.neos.strategyUiCrudActions.update(strategyId, {
        showImportScheduleError: false,
      }),
    );
    dispatchables.push(neosThunks.createRemoveAllRateSchedulePeriodThunk(strategyId));

    importedData.forEach(line => {
      const rateDates: RateLegPeriodDates = {
        fixingDate: convertFieldToDateStringOrEmpty(line[0]),
        startDate: convertFieldToDateStringOrEmpty(line[1]),
        endDate: convertFieldToDateStringOrEmpty(line[2]),
        paymentDate: convertFieldToDateStringOrEmpty(line[3]),
      };
      if (
        rateDates.fixingDate ||
        rateDates.startDate ||
        rateDates.endDate ||
        rateDates.paymentDate
      ) {
        dispatchables.push(neosThunks.createAddRateSchedulePeriodThunk(strategyId, rateDates));
      }
    });
    dispatch(dispatchables);
  };
}

function convertFieldToDateStringOrEmpty(x: string | undefined): string {
  if (x && Date.parse(x)) {
    return x;
  }
  return '';
}
