import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isListedProduct } from '../neosModel';

export function isUsListed(state: AppState, strategyId: string, selectors: Selectors): boolean {
  const isFeatureEnabled = selectors.isFeatureToggleEnabled(state, 'US.listed.enabled');

  if (!isFeatureEnabled) {
    return false;
  }
  const usMarkets = selectors.getUsMarkets(state.referenceData);

  const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);

  return (
    isListedProduct(product) &&
    !!usMarkets.find(
      m =>
        (product.marketExchangeId !== undefined && product.marketExchangeId === m.galaxyCode) ||
        (product.marketMicCode !== undefined && product.marketMicCode === m.micCode),
    )
  );
}
