import { thunks } from '@/bootstrap/thunks';
import type {
  AsianOption,
  AsianSpreadOption,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useDispatch } from 'react-redux';

type ConstatDatesProps = {
  product: AsianOption | AsianSpreadOption;
  areDatesDisplayed: boolean;
};

export function ConstatDates({ areDatesDisplayed, product }: ConstatDatesProps) {
  const dispatch = useDispatch();
  if (!areDatesDisplayed) {
    return;
  }

  return (
    <>
      <h6 className="mt-2">Constat dates</h6>

      <table className="table table-sm table-bordered fit-content">
        <thead>
          <tr>
            <th className="p-0" style={{ width: '32px' }}>
              <button
                className="btn btn-flat-primary btn-icon"
                aria-label="addPeriod"
                data-e2e="asianOptions-period-dates-add-btn"
                onClick={() => {
                  dispatch(thunks.neos.createAddNewAsianOptionPeriodDateThunk(product.uuid));
                }}
              >
                <i className="icon">add</i>
              </button>
            </th>
            <th className="minWidth85px width160px">Date</th>
            <th className="minWidth85px width160px">Weight</th>
          </tr>
        </thead>

        <tbody>
          {product.period?.dates?.map((line, index) => {
            return (
              <tr key={line.uuid}>
                <td className="p-0" style={{ width: '32px' }}>
                  <button
                    className="btn btn-flat-primary btn-icon"
                    onClick={() => {
                      dispatch(
                        thunks.neos.createDeleteAsianOptionPeriodDateThunk(product.uuid, index),
                      );
                    }}
                  >
                    <i className="icon">delete_forever</i>
                  </button>
                </td>
                <td className="p-0 minWidth85px width160px">
                  <FlowDatePicker
                    onChange={value => {
                      dispatch(
                        thunks.neos.createUpdateAsianOptionPeriodDateThunk(
                          product.uuid,
                          index,
                          'date',
                          value,
                        ),
                      );
                    }}
                    maturities={[]}
                    date={line.date ?? ''}
                    hideCalendarIcon
                    className="errorable-bloc"
                    inputClassName="form-control"
                    popperPlacement="top"
                  />
                </td>
                <td className="p-0 minWidth85px width160px">
                  <NumericInput
                    value={line.weight}
                    onBlur={value => {
                      dispatch(
                        thunks.neos.createUpdateAsianOptionPeriodDateThunk(
                          product.uuid,
                          index,
                          'weight',
                          value,
                        ),
                      );
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
