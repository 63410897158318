import { DeltaAdjusted } from './deltaAdjusted/DeltaAdjusted.container';
import { DeltaSummary } from './deltaSummary/DeltaSummary.container';
import { Forex } from './forex/Forex.container';
import { RefDeltaTabs } from './refDeltaTabs/RefDeltaTabs.container';
import { UnderlyingReference } from './underlyingReference/UnderlyingReference.container';

import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { useSelector } from 'react-redux';
import styles from './Reference.module.scss';
import { SettlementCurrency } from './SettlementCurrency/SettlementCurrency';

export interface ReferenceOwnProps {
  rfqId: string;
}

export interface ReferenceMapStateToProps {
  isDeltaAdjustedPanelVisible: boolean;
  isDeltaSummaryVisible: boolean;
  deltaSummaries: string[];
  isEls: boolean;
  isElsFeatureToggleEnabled: boolean;
}

export interface ReferenceDispatchProps {
  onCopy: (text: string) => void;
}

type ReferenceProps = ReferenceMapStateToProps & ReferenceOwnProps & ReferenceDispatchProps;

export const ReferenceStyled = ({
  rfqId,
  isDeltaAdjustedPanelVisible,
  isDeltaSummaryVisible,
  deltaSummaries,
  isEls,
  isElsFeatureToggleEnabled,
  onCopy,
}: ReferenceProps) => {
  const isFutureIndexRfq = useSelector(
    (state: AppState) =>
      !!selectors.isMasterStrategyIndexFutureOrFutureRoll(state, rfqId, selectors),
  );
  const isDeltaSummaryDisplayed = !isFutureIndexRfq && isDeltaSummaryVisible;
  const isNotElsStrategyWithToggle = !(isEls && isElsFeatureToggleEnabled);

  return (
    <section className={styles['reference']}>
      <div className={`${styles['ref-delta-tabs']} flex-column`}>
        <div className="d-flex">
          {isNotElsStrategyWithToggle && <RefDeltaTabs rfqId={rfqId} />}
          <div
            className={styles['grid-refs']}
            style={{
              gridTemplateColumns: `fit-content(100%) fit-content(100%) auto ${
                isDeltaSummaryDisplayed ? 'auto' : ''
              }`,
            }}
          >
            {isDeltaAdjustedPanelVisible ? (
              <DeltaAdjusted rfqId={rfqId} />
            ) : (
              <>
                {isNotElsStrategyWithToggle && (
                  <>
                    <UnderlyingReference rfqId={rfqId} isFutureIndexRfq={isFutureIndexRfq} />
                    {isDeltaSummaryDisplayed && <DeltaSummary rfqId={rfqId} />}
                  </>
                )}
                {!isFutureIndexRfq && <Forex rfqId={rfqId} />}
                <SettlementCurrency rfqId={rfqId} />
              </>
            )}
          </div>
        </div>
        {!!deltaSummaries.length && (
          <div className="d-flex align-items-center bg-lvl1 shadow-sm mt-2">
            <div className="d-flex mx-4 gap-2">
              Delta Summary
              <button
                className="btn btn-link btn-sm btn-icon"
                onClick={() => onCopy(deltaSummaries.join('\n'))}
              >
                <i className="icon icon-xs">content_copy</i>
              </button>
            </div>

            <div className="py-2">
              {deltaSummaries.map((deltaSummary, index) => (
                <div key={`${deltaSummary}-${index}`}>{deltaSummary}</div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
