import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { ElsCurve } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsCurve';
import { ResetFrequencyField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ResetFrequencyField';
import { NormalSelectWithUnit } from '@/neos/components/share/NormalSelectWithUnit/NormalSelectWithUnit';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RateTenor } from '../../interestRate/RateTenor';
import { ElsFeaturesContext, commonElsBlocClasses } from '../ElsFeatures';
import { useUpdateSectionHeight } from '../useUpdateSectionHeight';
import { Basis } from './Basis';
import { BrokenPeriod } from './BrokenPeriod';
import { CalculationMethod } from './CalculationMethod';
import { ElsBlocField } from './ElsBlocField';
import { LookBackPeriod } from './LookBackPeriod';
import { getElsProductFields } from './getElsProductFields';

interface RateBlocProps {
  canBeHidden: boolean;
}

export function RateBloc({ canBeHidden }: RateBlocProps) {
  const { rfqId, strategyId, parentClassNames, labelClassNames } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const {
    observationShift,
    paymentDelay,
    lockout,
    onObservationShiftChanged,
    onPaymentDelayChanged,
    onLockoutChanged,
  } = useSelector((state: AppState) =>
    getElsProductFields(rfqId, strategyId, state, selectors, actionCreators, thunks, dispatch),
  );

  const { ref } = useUpdateSectionHeight(strategyId, 'Rate Convention');

  function hideSection() {
    dispatch(thunks.neos.createToggleElsSectionThunk('Rate Convention', false));
  }

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <h5 className="d-inline-block p-1 m-0 mb-1">Rate Convention</h5>
          {canBeHidden && (
            <button className="btn btn-icon btn-flat-primary" onClick={hideSection}>
              <i className="icon">clear</i>
            </button>
          )}
        </div>
        <div className="d-flex flex-wrap">
          <ElsCurve />
          <RateTenor
            strategyId={strategyId}
            labelClassNames={labelClassNames}
            parentClassNames={parentClassNames}
          />

          <CalculationMethod
            rfqId={rfqId}
            strategyId={strategyId}
            labelClassNames={labelClassNames}
            parentClassNames={parentClassNames}
          />

          <LookBackPeriod
            rfqId={rfqId}
            strategyId={strategyId}
            labelClassNames={labelClassNames}
            parentClassNames={parentClassNames}
          />

          <ElsBlocField
            label={{
              type: 'helpText',
              content: 'Observation Shift',
              helpText: `Start Date and End Date of the rate leg will be shifted backward by number business days specified in the field,\n
              Overnight fixing rate date = Start date shifted backward`,
            }}
            renderInput={readOnly => (
              <NormalSelectWithUnit
                readOnly={readOnly}
                addEmptyValue
                options={[...Array(11).keys()].map(i => ({ label: `${i}`, value: `${i}` }))}
                value={observationShift?.toString()}
                onChange={n => onObservationShiftChanged(n ? Number.parseInt(n) : undefined)}
                data-e2e="rate-bloc-observation-shift"
                unit="BD"
                unitTooltip="Business Days"
                orientation="right"
              />
            )}
          />

          <ElsBlocField
            label={{
              type: 'helpText',
              content: 'Lockout',
              helpText:
                'The last n overnight rate fixings are frozen at the value of fixing on the first date of the lockout period',
            }}
            renderInput={readOnly => (
              <NormalSelectWithUnit
                readOnly={readOnly}
                addEmptyValue
                options={[...Array(11).keys()].map(i => ({ label: `${i}`, value: `${i}` }))}
                value={lockout?.toString()}
                onChange={n => onLockoutChanged(n ? Number.parseInt(n) : undefined)}
                data-e2e="rate-bloc-lockout"
                unit="BD"
                unitTooltip="Business Days"
                orientation="right"
              />
            )}
          />

          <ElsBlocField
            label={{
              type: 'helpText',
              content: 'Payment Delay',
              helpText:
                'Payment delay should be kept at 0 in order to have matching interest end dates and payment dates; interest leg as a whole is already shifted by effective date offset. The payment date is shifted forward, by the specified number of business days, from the end date of the interest period',
            }}
            renderInput={readOnly => (
              <NormalSelectWithUnit
                readOnly={readOnly}
                addEmptyValue
                options={[...Array(11).keys()].map(i => ({ label: `${i}`, value: `${i}` }))}
                value={paymentDelay?.toString()}
                onChange={n => onPaymentDelayChanged(n ? Number.parseInt(n) : undefined)}
                data-e2e="rate-bloc-payment-delay"
                unit="BD"
                unitTooltip="Business Days"
                orientation="right"
              />
            )}
          />

          <ResetFrequencyField />

          <Basis />

          <BrokenPeriod />
        </div>
      </div>
    </div>
  );
}
