import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

interface TraderAskLeg {
  legId: string;
  quoteId: string;
  unit: '';
  traderAsk: number | undefined;
  askIsTransparent: boolean;
}

export interface TraderAskModel {
  isEnabled: boolean;
  legs: TraderAskLeg[];
}
export function getTraderAskModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): TraderAskModel {
  const compositionLegs = selectors.getStrategyCompositionLegsData(state, strategyId, selectors);
  const legsQuotes: TraderAskLeg[] = compositionLegs.map(({ uuid, quoteId }) => {
    const { traderAsk } = selectors.getQuote(state, quoteId);
    const askIsTransparent =
      selectors.getLegWay(state, rfqId, strategyId, uuid, selectors) === 'BUY';
    return {
      legId: uuid,
      quoteId,
      unit: '',
      traderAsk,
      askIsTransparent,
    };
  });

  const isEnabled = selectors.areTraderPricesEnabled(state, rfqId, strategyId, selectors);
  return { isEnabled, legs: legsQuotes };
}
