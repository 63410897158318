import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { constantToSentence } from '@/util/string/stringUtils';
import { useRef, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { forkJoin, type Observable } from 'rxjs';

export type MultipleTypeaheadOrigin = 'COUNTERPART' | 'UNDERLYING' | 'STATUS' | 'STRATEGY_TYPE';

const TypeaheadOriginPosition: { [key in MultipleTypeaheadOrigin]: number } = {
  STATUS: 1,
  STRATEGY_TYPE: 2,
  UNDERLYING: 3,
  COUNTERPART: 4,
};

export interface MultipleTypeaheadValue {
  label: string;
  value: string;
  origin: MultipleTypeaheadOrigin;
}

export interface MultipleAsyncTypeaheadApi {
  fetchMatchingOptions: (prefix: string) => Observable<MultipleTypeaheadValue[]>;
  searchMatchingCounterparts: (name: string) => Observable<MultipleTypeaheadValue[]>;
}

export interface MultipleAsyncTypeaheadProps {
  id?: string;
  api: MultipleAsyncTypeaheadApi;
  values: MultipleTypeaheadValue[];
  disabled?: boolean;
  placeholder?: string;
  staticOptions: MultipleTypeaheadValue[];
  onChange: (option: MultipleTypeaheadValue[]) => void;
  ['data-e2e']?: string;
  children?: any;
}

export function MultipleAsyncTypeahead(props: MultipleAsyncTypeaheadProps) {
  const typeaheadRef = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<MultipleTypeaheadValue[]>([]);
  const isSearchCounterpartsByNameFeatureToggled = useFeatureToggle(
    'neos.blotter.search.counterparts.by.name',
  );
  const onSearch = (userInput: string) => {
    const filteredStaticOptions = props.staticOptions.filter(option =>
      option.label.toLocaleUpperCase().startsWith(userInput.toLocaleUpperCase()),
    );
    const apiCalls = [props.api.fetchMatchingOptions(userInput)];

    if (isSearchCounterpartsByNameFeatureToggled) {
      apiCalls.push(props.api.searchMatchingCounterparts(userInput));
    }

    return forkJoin(apiCalls).subscribe(results => {
      setOptions(filteredStaticOptions.concat(...results));
    });
  };

  const {
    id,
    api,
    children,
    disabled,
    onChange,
    values,
    placeholder,
    staticOptions,
    ...restProps
  } = props;

  return (
    <div ref={typeaheadRef} {...restProps}>
      <AsyncTypeahead
        id={id}
        isLoading={false}
        delay={1000}
        minLength={1}
        options={options.sort(
          (a, b) => TypeaheadOriginPosition[a.origin] - TypeaheadOriginPosition[b.origin],
        )}
        multiple
        onSearch={onSearch}
        onChange={selected => onChange(selected as MultipleTypeaheadValue[])}
        selected={values ? values : []}
        disabled={disabled}
        placeholder={placeholder}
        renderToken={(option, props) => {
          if (typeof option === 'string') {
            return <span></span>;
          }

          const value = option as MultipleTypeaheadValue;
          return (
            <span
              key={id}
              title={constantToSentence(value.origin)}
              className={`badge bg-${getBadgeClass(
                value.origin,
              )} badge-dismissible me-1 align-self-center`}
            >
              <button
                className="btn"
                onClick={() => {
                  props?.onRemove?.(option);
                }}
              >
                <i className="icon">close</i>
              </button>
              {value.label}
            </span>
          );
        }}
        renderMenuItemChildren={option => {
          if (typeof option === 'string') {
            return <span></span>;
          }

          const value = option as MultipleTypeaheadValue;
          return (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '25px auto',
                gap: '4px',
                lineHeight: '15px',
              }}
            >
              <span
                title={constantToSentence(value.origin)}
                className={`badge bg-${getBadgeClass(value.origin)}`}
              >
                {value.origin.slice(0, 1)}
              </span>
              <label>{value.label}</label>
            </div>
          );
        }}
      />
    </div>
  );
}

function getBadgeClass(multipleTypeaheadOrigin: MultipleTypeaheadOrigin) {
  switch (multipleTypeaheadOrigin) {
    case 'COUNTERPART':
      return 'primary';
    case 'STATUS':
      return 'info';
    case 'UNDERLYING':
      return 'warning';
    case 'STRATEGY_TYPE':
      return 'danger';
  }
}
