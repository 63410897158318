import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { Way } from '../../../../neos/business/neosModel';

export function getLegWay(
  state: AppState,
  rfqId: string,
  strategyId: string,
  legId: string,
  selectors: Selectors,
): Way | undefined {
  const { clientWay } = selectors.getRfqData(state, rfqId);
  const { weight: strategyWeight } = selectors.getStrategyData(state, strategyId);
  const { weight: legWeight } = selectors.getLegData(state, legId);
  return services.computeLegWay(strategyWeight, legWeight, clientWay);
}
