import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import { isClsProduct, isElsProduct } from '../../../../neos/business/neosModel';

export function createRemoveAllRateSchedulePeriodThunk(strategyId: string): Thunk {
  return function removeAllRateSchedulePeriodThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();

    const product = selectors.getStrategyProduct(appState, strategyId, selectors);

    if (!isElsProduct(product) && !isClsProduct(product)) {
      throw new Error('product is neither an els nor a cls');
    }
    const action: Action = productCrudActions.update(product.uuid, {
      ratePeriods: [],
    });
    dispatch(action);
  };
}
