import type { Thunk } from '@/bootstrap/thunks';
import {
  type StockLoanComponent,
  type StockLoanHedge,
  initialStockLoanHedge,
  isElsProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import { updateInArray } from '@/util/array/arrayUtils';

export function createUpdateElsStockLoanComponentThunk<K extends keyof StockLoanComponent>(
  productId: string,
  index: number,
  key: K,
  value: StockLoanComponent[K],
): Thunk {
  return function updateElsStockLoanComponentThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsProduct(product)) {
      return;
    }

    const stockLoanComponent = product.stockLoanHedge?.stockLoanComponents?.at(index);

    if (stockLoanComponent === undefined) {
      return;
    }

    const newBasketCompositionElement: StockLoanComponent = {
      ...stockLoanComponent,
      [key]: value,
    };

    const oldStockLoanHedge: StockLoanHedge = product.stockLoanHedge ?? initialStockLoanHedge;

    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        stockLoanHedge: {
          ...oldStockLoanHedge,
          stockLoanComponents: updateInArray(
            oldStockLoanHedge.stockLoanComponents,
            index,
            newBasketCompositionElement,
          ),
        },
      }),
    );
  };
}
