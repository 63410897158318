import type { AppState } from '@/bootstrap/state';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { uniq } from 'lodash';
import { counterpartsDomain } from './counterpartsDomain';
import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel.ts';

export const counterpartsSelectors = {
  getCounterparts,
  getCounterpartsBySales,
};

function getCounterparts(state: AppState, rfqId: string): Counterpart[] | undefined {
  const { salesInitId, salesValoId } = state.actorsSetup[rfqId];
  const salesIds = uniq([salesInitId, salesValoId].filter(isDefined));
  return getCounterpartsBySales(state, salesIds);
}

function getCounterpartsBySales(state: AppState, salesIds: string[]): Counterpart[] | undefined {
  return counterpartsDomain.selectors.find(state.counterparts, salesIds);
}
