import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { LegRecomputeChangedScope } from '@/neos/business/rfq/models';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  TraderBidComponent,
  type TraderBidDispatchProps,
  type TraderBidOwnProps,
  type TraderBidStateProps,
} from './TraderBid';
import { getTraderBidModel } from './getTraderBidModel';

export const TraderBid = connect(mapStateToProps, mapDispatchToProps)(TraderBidComponent);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: TraderBidOwnProps,
): TraderBidStateProps {
  return {
    model: getTraderBidModel(state, rfqId, strategyId, selectors),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: TraderBidOwnProps,
): TraderBidDispatchProps {
  return {
    onLegTraderBidChanged(quoteId: string, legId: string, value?: number) {
      dispatch(
        neosThunks.createRfqUpdateLegQuoteThunk({
          rfqId,
          strategyId,
          quoteId,
          legId,
          quotePatch: { traderBid: value },
          scope: LegRecomputeChangedScope.TRADER_PRICE,
        }),
      );
    },
  };
}
