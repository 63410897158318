import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { LegRecomputeScope, isElsProduct } from '../../../../../../../../neos/business/neosModel';
import { switchUnit } from './ExecFeesUnitSwitcher';

export function ExecFeesIn() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const execFeesFeature = useAppSelector(state =>
    selectors.getFeature(state.featureState, {
      strategyId,
      type: 'EXEC_FEES',
    }),
  );

  if (!isElsProduct(product)) {
    return null;
  }

  const onExecFeesInChange = (newExecFeesIn: number | undefined) => {
    dispatch(
      actionCreators.neos.featureCrudActions.update(
        { strategyId, type: 'EXEC_FEES' },
        {
          execFeesIn: {
            ...(execFeesFeature?.execFeesIn ?? {
              unit: 'bp',
              type: 'BIPS',
            }),
            value: newExecFeesIn,
          },
        },
      ),
    );
    dispatch(
      actionCreators.neos.createRecomputeRfqRequestedAction(
        rfqId,
        LegRecomputeScope.CHANGE_EXEC_FEE,
      ),
    );
  };

  const onExecFeesInUnitClick = () => {
    const newUnit = switchUnit(execFeesFeature?.execFeesIn?.type ?? 'BIPS');
    dispatch(
      actionCreators.neos.featureCrudActions.update(
        { strategyId, type: 'EXEC_FEES' },
        { execFeesIn: { ...execFeesFeature?.execFeesIn, ...newUnit } },
      ),
    );
  };

  return (
    <ElsBlocField
      label="Exec Fees In"
      renderInput={readOnly => (
        <NumericInput
          readOnly={readOnly}
          value={execFeesFeature?.execFeesIn?.value}
          onBlur={(val: number | undefined) => onExecFeesInChange(val)}
          onUnitClick={onExecFeesInUnitClick}
          unit={execFeesFeature?.execFeesIn?.unit ?? 'bp'}
          data-e2e="fees-block-exec-fees-in"
        />
      )}
    />
  );
}
