import type { Thunk } from '@/bootstrap/thunks';
import {
  type BasketCompositionDetails,
  assertProductIsElsBasket,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { ExecFees } from '@/neos/business/rfq/strategy/leg/product/productOnyxModel';
import { LegRecomputeScope } from '@/neos/business/rfq/models';

export function createUpdateBasketCompositionFeesUnit(
  rfqId: string,
  productId: string,
  newExecFeesUnit: Omit<ExecFees, 'value'>,
  field: keyof Pick<BasketCompositionDetails, 'execFeesIn' | 'execFeesOut'>,
): Thunk {
  return function updateBasketCompositionFeesUnit(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const product = selectors.getProduct(getState(), productId);
    assertProductIsElsBasket(product);

    const { basketComposition } = product.basketUnderlying;

    dispatch(
      actionCreators.neos.productCrudActions.update(productId, {
        basketUnderlying: {
          ...product.basketUnderlying,
          basketComposition: getUpdatedBasketComposition(basketComposition, newExecFeesUnit, field),
        },
      }),
      actionCreators.neos.createRecomputeRfqRequestedAction(
        rfqId,
        LegRecomputeScope.CHANGE_EXEC_FEE,
      ),
    );
  };
}

function getUpdatedBasketComposition(
  basketComposition: BasketCompositionDetails[],
  newExecFeesUnit: Omit<ExecFees, 'value'>,
  field: keyof Pick<BasketCompositionDetails, 'execFeesIn' | 'execFeesOut'>,
): BasketCompositionDetails[] {
  return basketComposition.map(basketCompositionDetail => {
    const newBasketCompositionDetail = structuredClone(basketCompositionDetail);
    newBasketCompositionDetail[field] = {
      ...newBasketCompositionDetail[field],
      ...newExecFeesUnit,
    };

    return newBasketCompositionDetail;
  });
}
