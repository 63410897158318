import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Tooltip } from 'react-bootstrap';
import { ErrorHighlight } from '../../../../../share/errorHighlight';

import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { type ExecFeesValue, availableExecFeesValues } from '../../../../../../business/neosModel';
import { CheckBoxWithErrorHandling } from '../elsFeatures/RateBloc/CheckBoxWithErrorHandling';
import { getElsFeatureFields } from '../elsFeatures/RateBloc/getElsFeatureFields';

import { actionCreators } from '@/bootstrap/actions';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { CalculationMethod } from '../elsFeatures/RateBloc/CalculationMethod';
import { LookBackPeriod } from '../elsFeatures/RateBloc/LookBackPeriod';
import { ResetFrequencySelect } from '../elsFeatures/RateBloc/ResetFrequencySelect';

const execFeesValueFormatter: { [k in ExecFeesValue]: string } = {
  NONE: 'None',
  IN: 'In',
  OUT: 'Out',
  IN_AND_OUT: 'In/Out',
};

export interface OwnProps {
  rfqId: string;
  strategyId: string;
}

export const OldEls = ({ rfqId, strategyId }: OwnProps) => {
  const dispatch = useDispatch();
  const {
    equityBullet,
    rateBullet,
    execFees,
    resetFrequency,
    onEquityBulletChanged,
    onRateBulletChanged,
    onExecFeesTypeChangedFromOldEls,
    onResetFrequencyChanged,
    isEls,
    elsToggle,
  } = useSelector((state: AppState) =>
    getElsFeatureFields(strategyId, state, selectors, thunks, actionCreators, dispatch),
  );

  const isLookBackAndCalculationMethodDisplayed = useFeatureToggle(
    'neos.els.lookback.calculation.method.enabled',
  );

  if (
    (!equityBullet.isDisplayed || elsToggle) &&
    (!rateBullet.isDisplayed || elsToggle) &&
    (!resetFrequency.isDisplayed || !(!isEls || (isEls && !elsToggle))) &&
    (!execFees.isDisplayed || elsToggle)
  ) {
    return null;
  }
  return (
    <div className="d-flex mb-1 ms-1 border p-1 fit-content">
      {equityBullet.isDisplayed && !elsToggle && (
        <div className="me-1">
          <NeosTooltip
            placement="top"
            overlay={<Tooltip id={'equity-bullet-label'}>{'Equity Bullet'}</Tooltip>}
          >
            <span className="fw-bold">Eqty B.</span>
          </NeosTooltip>
          <CheckBoxWithErrorHandling
            rfqId={rfqId}
            strategyId={strategyId}
            fieldValue={equityBullet.value}
            fieldName="equityBullet"
            onChangeFunction={onEquityBulletChanged}
          />
        </div>
      )}
      {rateBullet.isDisplayed && !elsToggle && (
        <div className="me-1">
          <NeosTooltip
            placement="top"
            overlay={<Tooltip id={'rate-bullet-label'}>{'Rate Bullet'}</Tooltip>}
          >
            <span className="fw-bold">Rate B.</span>
          </NeosTooltip>
          <CheckBoxWithErrorHandling
            rfqId={rfqId}
            strategyId={strategyId}
            fieldValue={rateBullet.value}
            fieldName="rateBullet"
            onChangeFunction={onRateBulletChanged}
          />
        </div>
      )}

      {resetFrequency.isDisplayed && (!isEls || (isEls && !elsToggle)) && (
        <div className="me-1">
          <NeosTooltip
            placement="top"
            overlay={<Tooltip id={'reset-frequency-label'}>{'Reset Frequency'}</Tooltip>}
          >
            <span className="fw-bold">Reset Frqcy</span>
          </NeosTooltip>
          <ResetFrequencySelect
            rfqId={rfqId}
            strategyId={strategyId}
            resetFrequency={resetFrequency.value}
            onChange={newResetFrequency => onResetFrequencyChanged(newResetFrequency, rfqId)}
          />
        </div>
      )}

      {execFees.isDisplayed && !elsToggle && (
        <div className="me-1">
          <span className="fw-bold">Exec Fees</span>
          <ErrorHighlight errorField={'execFees'} related={{ rfqId, strategyId }}>
            <NeosSelect<ExecFeesValue>
              style={{ minWidth: '110px' }}
              value={execFees.value}
              onChange={onExecFeesTypeChangedFromOldEls}
              data-e2e="neos-extra-features-els-execfee"
              options={availableExecFeesValues.map(value => ({
                value,
                label: execFeesValueFormatter[value],
              }))}
            />
          </ErrorHighlight>
        </div>
      )}

      {isEls && isLookBackAndCalculationMethodDisplayed && (
        <>
          <LookBackPeriod
            rfqId={rfqId}
            parentClassNames="me-1"
            labelClassNames="fw-bold"
            strategyId={strategyId}
          />
          <CalculationMethod
            rfqId={rfqId}
            parentClassNames="me-1"
            labelClassNames="fw-bold"
            strategyId={strategyId}
          />
        </>
      )}
    </div>
  );
};
