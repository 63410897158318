import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';

export function createUpdateSettlementDateThunk(rfqId: string, legId: string, date: string): Thunk {
  return function updateSettlementDateThunk(
    dispatch,
    getState,
    { selectors, actionCreators: { neos: as }, thunks },
  ) {
    const appState = getState();
    const legData = selectors.getLegData(appState, legId);
    const settlementData = legData?.settlement;
    if (settlementData) {
      const newSettlementData = {
        ...settlementData,
        date: { value: date, offset: undefined },
      };

      const isSettlementDateToggleEnabled = selectors.isFeatureToggleEnabled(
        appState,
        'neos.cls.settlement.value.date.enabled',
      );
      const elsFeatureToggleEnabled = selectors.isFeatureToggleEnabled(
        appState,
        'neos.els.update.enabled',
      );
      const isEls = selectors.isElsStrategy(appState, legData.strategyId, selectors);
      const isCls = selectors.isClsStrategy(appState, legData.strategyId, selectors);
      const isClsToggleEnabled = selectors.isFeatureToggleEnabled(appState, 'neos.cls.enabled');

      const overrideScopes: DefaultingScope[] = [];

      if ((isEls && elsFeatureToggleEnabled) || (isCls && isClsToggleEnabled)) {
        overrideScopes.push(DefaultingScope.FIXED_DAY_TYPE);
      }
      if (isEls && isSettlementDateToggleEnabled) {
        overrideScopes.push(DefaultingScope.DIVIDEND_PAYMENT_DATE);
      }

      const defaultingOptions = new DefaultingOptions({
        enrichScopes: [DefaultingScope.SETTLEMENT_OTC],
        overrideScopes,
      });

      const productId = legData.productId;

      dispatch(
        as.legDataCrudActions.update(legId, {
          settlement: newSettlementData,
        }),
        thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, productId, true),
        thunks.neos.changeClsIsScheduleObsoleteThunk(rfqId, productId, true),
        as.createDefaultRfqRequestedAction(rfqId, defaultingOptions),
      );
    }
  };
}
