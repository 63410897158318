import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isElsProduct } from '../../../../neos/business/neosModel';

export interface TransactionActionsModel {
  isPostNegoEnabled: boolean;
  isElsEnabled: boolean;
  isInternalEls: boolean;
}

export function getTransactionActionsModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): TransactionActionsModel {
  const isAllocEnabled = selectors.isFeatureToggleEnabled(state, 'neos.allocs.enabled');
  const isElsFeatureToggleEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.els.update.enabled',
  );

  const isOtcAllocEnabled = selectors.isFeatureToggleEnabled(state, 'neos.otc.allocs.enabled');

  const hasListedStrategies = !!selectors.getListedExecutionRfqStrategies(state, rfqId, selectors)
    .length;

  const hasListedDeltas = !!selectors.getListedExecutionDeltaStrategies(state, rfqId, selectors)
    .length;

  const hasOtcStrategies = !!selectors.getOtcExecutionRfqStrategies(state, rfqId, selectors).length;

  const hasOtcDeltas = !!selectors.getOtcExecutionDeltaStrategies(state, rfqId, selectors).length;

  const products = selectors.getRfqProducts(state, rfqId, selectors);
  const hasElsProduct = products.some(isElsProduct);

  const isElsEnabled = isElsFeatureToggleEnabled && hasElsProduct;

  const isInternalEls = selectors.isRfqInternalElsAndToggled(state, rfqId, selectors);

  return {
    isPostNegoEnabled:
      (isAllocEnabled && (hasListedStrategies || hasListedDeltas)) ||
      (isOtcAllocEnabled && (hasOtcStrategies || hasOtcDeltas)),
    isElsEnabled,
    isInternalEls,
  };
}
