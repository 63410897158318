import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Thunk } from '@/bootstrap/thunks';
import type { RelatedExchange } from '@/neos/business/referenceData/relatedExchange/relatedExchangeModel';
import type {
  Counterpart,
  Currency,
  PredealCheckAvailableDerogation,
  SalesGroup,
  StrategyConfiguration,
  Tenor,
  TraderGroup,
  User,
} from '../../../neos/business/neosModel';
import type { OnyxStrategyDefinition } from '../../../neos/business/neosOnyxModel';
import type { PredealCheckType } from '../predealCheck/predealCheckModel';
import type { OnyxMarket, OnyxMarketEliot } from './markets/marketsModel';
import type { SalesLocations } from './salesLocation/salesLocationsModel';

export function createReferenceDataReceivedThunk(
  currencies: Currency[],
  predealChecksDerogations: Record<PredealCheckType, PredealCheckAvailableDerogation[]>,
  salesGroups: SalesGroup[],
  salesValos: User[],
  strategyDefinitions: OnyxStrategyDefinition[],
  strategyConfiguration: StrategyConfiguration,
  traderGroups: TraderGroup[],
  traders: User[],
  salesLocations: SalesLocations[],
  tenors: Tenor[],
  counterparts: Counterpart[],
  markets: OnyxMarket[],
  relatedExchangeFields: RelatedExchange[],
  marketsEliot: OnyxMarketEliot[],
): Thunk {
  return function referenceDataReceivedThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createReferenceDataFetchedAction },
      },
    },
  ) {
    const state = getState();
    const isSalesValoEmpty = salesValos.length === 0;
    const salesValoDefaultedIfEmpty = isSalesValoEmpty
      ? getDefaultSalesValo(state, selectors)
      : salesValos;

    if (selectors.isFeatureToggleEnabled(state, 'options.futures.enabled')) {
      strategyConfiguration.editableStrategies.push('PUT_ON_FUTURE', 'CALL_ON_FUTURE');
    }

    dispatch(
      createReferenceDataFetchedAction(
        currencies,
        predealChecksDerogations,
        salesGroups,
        salesValoDefaultedIfEmpty,
        strategyDefinitions,
        strategyConfiguration,
        traderGroups,
        traders,
        salesLocations,
        tenors,
        counterparts,
        markets,
        relatedExchangeFields,
        marketsEliot,
      ),
    );
  };
}

function getDefaultSalesValo(state: AppState, { getCurrentUser }: Selectors): User[] {
  const { login, sesameId, lastname, firstname } = getCurrentUser(state);

  const defaultSalesValo: User = {
    id: sesameId,
    firstname,
    lastname,
    email: login,
    groupId: null,
  };

  return [defaultSalesValo];
}
