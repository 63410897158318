import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isElsProduct } from '../../../../../../../neos/business/neosModel';

export type EffectiveDateCellsModel = {
  effectiveDate: string | undefined;
  effectiveDateOffset: number | undefined;
  isDisplayed: boolean;
};

export function getEffectiveDateCellsModel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): EffectiveDateCellsModel {
  const isFeatureEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.els.effectiveDate.enabled',
  );

  const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);

  const isDisplayed = isElsProduct(product) && isFeatureEnabled;

  return {
    effectiveDate: isDisplayed ? product.effectiveDate : undefined,
    effectiveDateOffset: isDisplayed ? product.effectiveDateOffset : undefined,
    isDisplayed,
  };
}
