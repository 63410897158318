import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { NegotiationMode } from '../../../../../../../neos/business/neosModel';
import {
  type NegoTypeCellDispatchProps,
  type NegoTypeCellMapStateProps,
  type NegoTypeCellOwnProps,
  NegoTypeCellUnconnected,
} from './NegoTypeCell';
import { getNegoTypeCellModel } from './getNegoTypeCellModel';

export const NegoTypeCell = connect(mapStateToProps, mapDispatchToProps)(NegoTypeCellUnconnected);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: NegoTypeCellOwnProps,
): NegoTypeCellMapStateProps {
  return getNegoTypeCellModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: NegoTypeCellOwnProps,
): NegoTypeCellDispatchProps {
  return {
    onNegotiationTypeChanged(negoType: NegotiationMode) {
      dispatch(
        neosThunks.createChangeStrategiesNegotiationModeThunk(rfqId, [strategyId], negoType),
      );
    },
  };
}
