import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { FC, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  OtcAllocPdcComponent,
  type OtcAllocPdcDispatchProps,
  type OtcAllocPdcOwnProps,
  type OtcAllocPdcStateProps,
} from './OtcAllocPdc';

export const OtcAllocPdc: FC<OtcAllocPdcOwnProps> = ownProps => {
  const stateProps = useSelector<AppState, OtcAllocPdcStateProps>(state => {
    const allocPdc = selectors.getAllNeosAllocationPredealChecks(state, ownProps.rfqId, selectors);

    const riskPdc = allocPdc.find(
      pdc =>
        pdc.counterpartyId === ownProps.counterpartId &&
        (pdc.type === 'CREDIT_CHECK_ASK' || pdc.type === 'CREDIT_CHECK_BID'),
    );
    const othersPdc = allocPdc.filter(
      pdc =>
        pdc.counterpartyId === ownProps.counterpartId &&
        pdc.type !== 'CREDIT_CHECK_ASK' &&
        pdc.type !== 'CREDIT_CHECK_BID',
    );

    return { riskPdc, othersPdc };
  });

  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = mapDispatchProps(dispatch, ownProps);

  return <OtcAllocPdcComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchProps(
  dispatch: ExtendedDispatch,
  ownProps: PropsWithChildren<OtcAllocPdcOwnProps>,
): OtcAllocPdcDispatchProps {
  return {
    onClick: () => {
      dispatch(
        neosActionCreators.rfqUiCrudActions.update(ownProps.rfqId, {
          showPredealChecksModal: true,
        }),
      );
    },
  };
}
