import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { formatFromCamelToStartCase } from '@/util/format';
import { Fragment } from 'react';
import { FormSelect, Tooltip } from 'react-bootstrap';
import {
  type LimitObservationType,
  type NeosBarrierType,
  availableBarrierTypes,
} from '../../../../../../business/neosModel';
import type { TypeaheadValue } from '../../../../../share/typeahead';
import { UnderlyingInput } from '../../common';
import { CloseButton } from '../closeButton';
import type { BarriersModel } from './getBarriersModel';

import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { DoubleDatePicker } from '@/neos/components/share/datePicker';
import { NumericInput } from '@/neos/components/share/numericInput';
import { formatDoubleDate } from '@/util/date/dateFormatHelper';
import { NeosBlurInput } from '../../../../../share/blurComponent/NeosBlurInput';
import { MaturityAndStrike } from './MaturityAndStrike';
import { AddBarrierButton } from './addBarrierButton';

export interface OwnProps {
  rfqId: string;
  strategyId: string;
}

export interface StateProps {
  model: BarriersModel;
}

const gridTemplateColumns = '32px 150px 100px 90px minmax(90px, 100px) 120px 80px 80px 300px';

export interface DispatchProps {
  onBarrierRemoved: (index: number) => void;
  onBarrierTypeChanged: (index: number, type: NeosBarrierType | undefined) => void;
  onBarrierMaturityChanged: (index: number, maturity: string | undefined) => void;
  onBarrierStrikeChanged: (index: number, limitStrikeValue: number | undefined) => void;
  onBarrierStrikeUnitChanged: () => void;
  onBarrierObservationTypeChanged: (
    index: number,
    limitObservationType: LimitObservationType | undefined,
  ) => void;
  onBarrierUnderlyingChanged: (index: number, underlyingIdOrName: string | undefined) => void;

  onTenorChanged(index: number, tenor: string | undefined): void;

  onBarrierRebateChanged(index: number, rebate: number | undefined): void;

  onBarrierGapChanged(index: number, gap: number | undefined): void;

  onBarrierLimitWindowDatesChanged(
    index: number,
    limitWindowDates: [string, string] | undefined,
  ): void;
}

type BarrierProps = OwnProps & StateProps & DispatchProps;

export function Barriers({
  strategyId,
  rfqId,
  model,
  onBarrierRebateChanged,
  onBarrierGapChanged,
  onBarrierLimitWindowDatesChanged,

  onBarrierTypeChanged,
  onBarrierUnderlyingChanged,
  onBarrierRemoved,
  onBarrierMaturityChanged,
  onBarrierStrikeChanged,
  onBarrierStrikeUnitChanged,
  onBarrierObservationTypeChanged,
  onTenorChanged,
}: BarrierProps) {
  if (!model.isDisplayed) {
    return <div />;
  }

  const { barriers, maturities, tenors, isEls, isReadonly } = model;

  const params = {
    maturities,
    tenors,
    isEls,
    onBarrierMaturityChanged,
    onBarrierStrikeChanged,
    onBarrierStrikeUnitChanged,
    onTenorChanged,
  };

  return (
    <>
      <div className="d-flex p-1 m-1 border fit-content">
        <div className="d-flex flex-column">
          <div className="d-grid fw-bold align-items-center gap-1" style={{ gridTemplateColumns }}>
            <AddBarrierButton rfqId={rfqId} strategyId={strategyId} />
            <span>Barrier Type</span>
            <span>Underlying</span>
            <span>Maturity</span>
            <span>Strike</span>
            <NeosTooltip
              placement="top"
              overlay={<Tooltip id={'observation-type-label'}>{'Limit Observation Type'}</Tooltip>}
            >
              <span>Obs. Type</span>
            </NeosTooltip>

            <span>Rebate</span>
            <span>Gap</span>
            <span>Limit window dates</span>
          </div>
          <div className="d-grid gap-1" style={{ gridTemplateColumns }}>
            {barriers.map(barrier => (
              <Fragment key={barrier.barrierIndex}>
                <span data-e2e="neos-extra-features-barrier-trash">
                  <button
                    disabled={isReadonly}
                    className="btn btn-icon btn-flat-primary"
                    onClick={() => onBarrierRemoved(barrier.barrierIndex)}
                  >
                    <i className="icon icon-md">delete_forever</i>
                  </button>
                </span>
                <NeosSelect
                  readOnly={isReadonly}
                  value={barrier.neosBarrierType}
                  onChange={value => onBarrierTypeChanged(barrier.barrierIndex, value)}
                  data-e2e="neos-extra-features-barrier-type"
                  addEmptyOption
                  options={availableBarrierTypes.map(barrierType => ({
                    value: barrierType,
                    label: barrierType,
                  }))}
                />

                {barrier.hasCustomUnderlying ? (
                  <NeosBlurInput
                    readOnly={isReadonly}
                    className="input-sm"
                    value={barrier.underlyingName ?? ''}
                    data-e2e="neos-extra-features-barrier-underlying"
                    onBlur={val => {
                      onBarrierUnderlyingChanged(barrier.barrierIndex, val?.target?.value);
                    }}
                  />
                ) : (
                  <UnderlyingInput
                    readOnly={isReadonly}
                    componentId={`barrier_${strategyId}_${barrier.barrierIndex}`}
                    data-e2e="neos-extra-features-barrier-underlying"
                    underlyingId={barrier.underlyingId}
                    bloombergCode={barrier.bloombergCode}
                    onChange={(val: TypeaheadValue | undefined) =>
                      onBarrierUnderlyingChanged(barrier.barrierIndex, val?.value)
                    }
                  />
                )}
                <MaturityAndStrike
                  barrier={barrier}
                  index={barrier.barrierIndex}
                  params={params}
                  readOnly={isReadonly}
                />

                <FormSelect
                  readOnly={isReadonly}
                  value={barrier.limitObservationType ?? ''}
                  onChange={event =>
                    onBarrierObservationTypeChanged(
                      barrier.barrierIndex,
                      (event.target.value as LimitObservationType) || undefined,
                    )
                  }
                  data-e2e="neos-extra-features-barrier-obs-type"
                >
                  {model.availableLimitObservationTypes.map(obervationType => (
                    <option key={obervationType ?? 'undefined_choice'} value={obervationType}>
                      {obervationType ? formatFromCamelToStartCase(obervationType) : ''}
                    </option>
                  ))}
                </FormSelect>
                <NumericInput
                  readOnly={isReadonly}
                  value={barrier.rebate}
                  data-e2e="neos-extra-features-barrier-rebate"
                  onBlur={value => {
                    onBarrierRebateChanged(barrier.barrierIndex, value);
                  }}
                />
                <NumericInput
                  readOnly={isReadonly}
                  value={barrier.gap}
                  data-e2e="neos-extra-features-barrier-gap"
                  onBlur={value => {
                    onBarrierGapChanged(barrier.barrierIndex, value);
                  }}
                />

                <DoubleDatePicker
                  readOnly={isReadonly}
                  data-e2e="neos-extra-features-barrier-limit-window-dates"
                  dateFrom={barrier.limitWindowDate?.startDate ?? ''}
                  dateTo={barrier.limitWindowDate?.endDate ?? ''}
                  onDateRangeChange={dates => {
                    const index = barrier.barrierIndex;
                    const formattedDates = formatDoubleDate(dates);
                    onBarrierLimitWindowDatesChanged(index, formattedDates);
                  }}
                />
              </Fragment>
            ))}
          </div>
        </div>
        <CloseButton strategyId={strategyId} type={'BARRIERS'} />
      </div>
    </>
  );
}
