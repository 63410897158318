import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { DefaultingScope, isElsProduct } from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import { useDispatch, useSelector } from 'react-redux';

export function GenerateButton({ strategyId, rfqId }: { strategyId: string; rfqId: string }) {
  const dispatch = useDispatch();

  const product = useSelector((state: AppState) =>
    selectors.getStrategyMasterProduct(state, strategyId, selectors),
  );
  const isReadonlyAtWorfklow = useAppSelector(appState =>
    selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors),
  );

  const feature = useSelector((state: AppState) =>
    selectors.getFeature(state.featureState, {
      strategyId,
      type: 'DIVIDEND_COMPONENT',
    }),
  );
  const dividendPaymentDate = feature?.dividendPaymentDateType;
  const isTrs = isElsProduct(product) && product.elsType === 'TRS';

  const canElsScheduleBeGenerated = useAppSelector(state =>
    selectors.canElsScheduleBeGenerated(state, strategyId, selectors),
  );
  const isCLSGenerateButtonEnabled = useAppSelector(state =>
    selectors.canClsScheduleBeGenerated(state, strategyId, selectors),
  );

  const isELSGenerateButtonEnabled =
    canElsScheduleBeGenerated && (!isTrs || dividendPaymentDate !== undefined);

  const isReadonly =
    !(isCLSGenerateButtonEnabled || isELSGenerateButtonEnabled) || isReadonlyAtWorfklow;

  const generateScheduleDates = () => {
    dispatch(thunks.neos.changeClsIsScheduleObsoleteThunk(rfqId, product.uuid, false));
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, false));
    dispatch(
      neosActionCreators.createDefaultRfqRequestedAction(
        rfqId,
        new DefaultingOptions({
          overrideScopes: [DefaultingScope.SCHEDULE],
          enrichScopes: [DefaultingScope.UNDERLYING],
        }),
      ),
    );
  };

  return (
    <button
      disabled={isReadonly}
      className={`btn btn-primary sgbs-btn-primary ${isReadonly && 'disabled'}`}
      onClick={() => !isReadonly && generateScheduleDates()}
      data-e2e="els-schedule-generate"
    >
      Generate
    </button>
  );
}
