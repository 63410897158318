import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Currency } from '@/neos/business/referenceData/currency/currencyModel';
import type { LegData } from '@/neos/business/rfq/strategy/leg/legData/legDataModel';
import type { DisplayTimeZone } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import type { SelectOption } from '@/neos/components/share/NeosSelect/NeosSelect';
import {
  DATE_LONG_TIME_FORMAT,
  DATE_SHORT_KEBAB_FORMAT,
  dateStringToIsoString,
  formatDateInTimeZone,
} from '@/util/date/dateFormatHelper';

export interface GetBrokerBlocModel {
  brokerFields: Partial<LegData>;
  legId: string;
  brokerId: string | undefined;
  availableBrokers: SelectOption[];
  currencies: Currency[];
  datetime:
    | {
        date: string;
        time: string;
      }
    | undefined;
  timezone: DisplayTimeZone;
}

export function getBrokerBlocModel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): GetBrokerBlocModel {
  const {
    uuid: legId,
    brokerId,
    brokerCommission,
    brokerCommissionCcy,
    brokerInfoVenue,
    brokerInfoExecutionVenue,
    brokerInfoTradingDateTime,
    brokerInfoTradingVenueTransactionId,
  } = selectors.getStrategyLeg(state, strategyId);
  const rfqId = selectors.getRfqIdByStrategyId(state, strategyId) ?? '';

  const availableBrokers = selectors.getBrokersList(state.referenceData).map(broker => {
    return {
      label: broker.name,
      value: broker.id.toString(),
    };
  });
  const { brokerNotInPreferences } = selectors.getRfqData(state, rfqId);

  if (brokerNotInPreferences) {
    availableBrokers.push({
      label: brokerNotInPreferences.name,
      value: brokerNotInPreferences.id.toString(),
    });
  }
  const timezone = selectors.getDisplayTimeZone(state.ui.userPreferences);

  return {
    brokerFields: {
      brokerCommission,
      brokerCommissionCcy,
      brokerInfoVenue,
      brokerInfoExecutionVenue,
      brokerInfoTradingDateTime,
      brokerInfoTradingVenueTransactionId,
    },
    legId,
    brokerId,
    availableBrokers,
    currencies: selectors.getCurrencyList(state.referenceData),
    datetime: brokerInfoTradingDateTime
      ? {
          date: formatDateInTimeZone(
            timezone,
            dateStringToIsoString(brokerInfoTradingDateTime),
            DATE_SHORT_KEBAB_FORMAT,
          ),
          time: formatDateInTimeZone(
            timezone,
            dateStringToIsoString(brokerInfoTradingDateTime),
            DATE_LONG_TIME_FORMAT,
          ),
        }
      : undefined,
    timezone,
  };
}
