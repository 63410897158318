import type { Thunk } from '@/bootstrap/thunks';
import type { RetryOnErrorOptions } from '@/neos/business/neosActions';
import { DefaultingScope } from '../models';
import { DefaultingOptions } from '../rfqActions';

export function createRfqDefaultBarrierTenorMaturityThunk(
  rfqId: string,
  strategyId: string,
  tenor: string | undefined,
  indexBarrier: number,
): Thunk {
  return function rfqDefaultBarrierTenorMaturityThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createUpdateBarrierOfFeatureThunk },
      },
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
    },
  ) {
    const retryOnErrorOptions: RetryOnErrorOptions = {
      retries: 1,
    };
    const defaultingOptions = new DefaultingOptions({
      overrideScopes: [DefaultingScope.TENOR_DATE],
    });

    const chainOptions = {};

    dispatch(
      createUpdateBarrierOfFeatureThunk({ strategyId, type: 'BARRIERS' }, indexBarrier, {
        maturity: undefined,
        maturityTenor: tenor,
      }),
    );

    dispatch(
      createDefaultRfqRequestedAction(rfqId, defaultingOptions, chainOptions, retryOnErrorOptions),
    );
  };
}
