import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { createRfqDefaultBarrierTenorMaturityThunk } from '@/neos/business/rfq/thunks/rfqDefaultBarrierTenorMaturity';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type {
  BarriersFeatureKey,
  LimitObservationType,
  NeosBarrierType,
} from '../../../../../../business/neosModel';
import {
  Barriers as Component,
  type DispatchProps,
  type OwnProps,
  type StateProps,
} from './Barriers';
import { getBarriersModel } from './getBarriersModel';

function mapStateToProps(appState: AppState, { strategyId }: OwnProps): StateProps {
  return {
    model: getBarriersModel(appState, strategyId, selectors),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: OwnProps,
): DispatchProps {
  const featureKey: BarriersFeatureKey = { strategyId, type: 'BARRIERS' };
  return {
    onBarrierTypeChanged: (index: number, neosBarrierType: NeosBarrierType | undefined) =>
      dispatch(
        neosThunks.createUpdateBarrierOfFeatureThunk(featureKey, index, { neosBarrierType }),
      ),
    onBarrierMaturityChanged: (index: number, maturity: string | undefined) =>
      dispatch(neosThunks.createUpdateBarrierOfFeatureThunk(featureKey, index, { maturity })),
    onBarrierStrikeChanged: (index: number, limitStrikeValue: number | undefined) =>
      dispatch(
        neosThunks.createUpdateBarrierOfFeatureThunk(featureKey, index, { limitStrikeValue }),
      ),
    onBarrierStrikeUnitChanged: () => {
      dispatch(neosThunks.createRfqChangeBarrierStrikeUnitThunk(rfqId, strategyId));
    },
    onBarrierObservationTypeChanged: (
      index: number,
      limitObservationType: LimitObservationType | undefined,
    ) =>
      dispatch(
        neosThunks.createUpdateBarrierOfFeatureThunk(featureKey, index, { limitObservationType }),
      ),
    onBarrierUnderlyingChanged: (index: number, underlyingIdOrName: string | undefined) =>
      dispatch(
        neosThunks.createNeosChangeBarrierUnderlyingThunk(
          rfqId,
          featureKey,
          index,
          underlyingIdOrName,
        ),
      ),
    onBarrierRemoved: (index: number) =>
      dispatch(neosThunks.createRemoveBarrierOfFeatureThunk(featureKey, index)),
    onTenorChanged(index: number, tenor: string | undefined) {
      dispatch(
        createRfqDefaultBarrierTenorMaturityThunk(rfqId, strategyId, tenor, index),
        createLogAnalyticsAction('NEOS RFQ', 'Select Tenor', tenor),
      );
    },
    onBarrierGapChanged(index: number, gap: number | undefined) {
      dispatch(neosThunks.createUpdateBarrierOfFeatureThunk(featureKey, index, { gap }));
    },
    onBarrierRebateChanged(index: number, rebate: number | undefined) {
      dispatch(neosThunks.createUpdateBarrierOfFeatureThunk(featureKey, index, { rebate }));
    },
    onBarrierLimitWindowDatesChanged(index: number, limitWindowDate: [string, string]) {
      dispatch(
        neosThunks.createUpdateBarrierOfFeatureThunk(featureKey, index, {
          limitWindowDate: { startDate: limitWindowDate[0], endDate: limitWindowDate[1] },
        }),
      );
    },
  };
}

export const Barriers = connect(mapStateToProps, mapDispatchToProps)(Component);
