import { TopLevelErrorToaster } from '@/neos/components/TopLevelErrorToaster';
import { If } from '@/neos/components/share/if/if';
import { useAppSelector } from '@/bootstrap/hooks';

export function TopLevelErrorMessage() {
  const isSignalRConnexionErrorDisplayed = useAppSelector(
    state => state.ui.topLevelError.isSignalRConnexionErrorDisplayed,
  );
  const isToggleFeatureErrorDisplayed = useAppSelector(
    state => state.ui.topLevelError.isToggleFeatureErrorDisplayed,
  );
  const firstErrorPositionPixel = 3;
  const featureToggleErrorPositionPixel = isSignalRConnexionErrorDisplayed
    ? 33
    : firstErrorPositionPixel;

  return (
    <>
      <If condition={isSignalRConnexionErrorDisplayed}>
        <TopLevelErrorToaster
          title="Notifications are currently not working"
          message="The SignalR notification service is not working, please contact your support"
          position={firstErrorPositionPixel}
        />
      </If>
      <If condition={isToggleFeatureErrorDisplayed}>
        <TopLevelErrorToaster
          title="Could not retrieve your enabled feature toggles"
          message="Feature toggles could not be retrieved, please contact your support"
          position={featureToggleErrorPositionPixel}
        />
      </If>
    </>
  );
}
