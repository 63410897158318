import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import type { Counterpart } from '../../../business/neosModel';
import { CounterpartPopover } from '../../share/counterpartPopover';
import { ErrorHighlight } from '../../share/errorHighlight';
import { If } from '../../share/if/if';
import type { DisplayUser } from '../../share/userUtils/userUtils';
import styles from './ActorsSetup.module.scss';
import { SalesInitLocation } from './salesInitLocation/SalesInitLocation.container';

export interface ActorsSetupOwnProps {
  rfqId: string;
}

export interface ActorsSetupMapStateToProps {
  counterpartPopoverInfo: Counterpart | undefined;
  availableSalesValos: DisplayUser[];
  salesInitId: string;
  selectedSalesValo: string | undefined;
  salesInitName: string | undefined;
  availableClients: Counterpart[];
  selectedClient: string | undefined;
  isReadonlyRfq: boolean;
  isDisabled: boolean;
  userInitFieldName: string;
  isInternalEls: boolean;
  isElsIdb: boolean;
}

export interface ActorsSetupDispatchProps {
  onSalesValoChanged(salesInitId: string | undefined, salesValoId: string | undefined): void;

  onCounterpartChanged(counterpartId: number | undefined): void;
}

export type ActorsSetupProps = ActorsSetupOwnProps &
  ActorsSetupMapStateToProps &
  ActorsSetupDispatchProps;

export const ActorsSetupComponent = (props: ActorsSetupProps) => {
  return (
    <div className={`${styles['actorsSetup']}`}>
      <div>
        <label>{props.userInitFieldName}</label>
        <span>{props.salesInitName}</span>
      </div>
      <SalesInitLocation rfqId={props.rfqId} />
      <If condition={!(props.isElsIdb || props.isInternalEls)}>
        <div>
          <label>Sales Valo</label>
          <NeosSelectWithAutocomplete
            data-e2e="neos-rfq-actors-setup-available-sales-valos"
            isReadOnly={props.isReadonlyRfq || props.availableSalesValos.length === 1}
            value={props.selectedSalesValo}
            onChange={selected => {
              props.onSalesValoChanged(props.salesInitId, selected?.value || undefined);
            }}
            addEmptyOption
            options={props.availableSalesValos.map(({ id, fullName }) => ({
              value: id,
              label: fullName,
            }))}
          />
        </div>
      </If>

      <If condition={!props.isInternalEls}>
        <div>
          <label>Client</label>
          <CounterpartPopover counterpart={props.counterpartPopoverInfo}>
            {/* span to prevent not forwarded ref error by ErrorHighlight in CounterpartPopover, see https://github.com/react-bootstrap/react-bootstrap/issues/2208#issuecomment-301737531 */}
            <span className="w-100">
              <ErrorHighlight errorField={'salesCounterparty'} related={{ rfqId: props.rfqId }}>
                {/* span to prevent not forwarded ref error by NeosSelect in ErrorHighlight, see https://github.com/react-bootstrap/react-bootstrap/issues/2208#issuecomment-301737531 */}
                <span className="w-100">
                  <NeosSelectWithAutocomplete
                    isLabelBold
                    data-e2e="neos-rfq-actors-setup-available-clients"
                    isReadOnly={props.isReadonlyRfq}
                    value={props.selectedClient}
                    onChange={selected => {
                      props.onCounterpartChanged(
                        selected?.value ? parseInt(selected?.value, 10) : undefined,
                      );
                    }}
                    addEmptyOption
                    isDisabled={props.isDisabled}
                    options={props.availableClients.map(({ id, name, eliotCode, mnemo }) => ({
                      value: id.toString(),
                      label: name,
                      data: [eliotCode, mnemo],
                    }))}
                  />
                </span>
              </ErrorHighlight>
            </span>
          </CounterpartPopover>
        </div>
      </If>
    </div>
  );
};
