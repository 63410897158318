import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { type Els, isElsProduct, type Way } from '../../../../../neos/business/neosModel';

type GlobalActionsModelPart1 = {
  hasBookingSteps: boolean;
  isMidOutOfTraderPrices: boolean;
  areTraderPricesOutOfSalesPrices: boolean;
  doesSalesPricesMismatchExecutedPrices: boolean;
  isTraderDeltaFarFromFairDelta: boolean;
  isReadonlyRfq: boolean;
  isQuoteTypeDisplayed: boolean;
  isMixedRfq: boolean;
  isSecondaryRfq: boolean;
  areSecondaryFeatureTogglesEnabled: boolean;
  isEls: boolean;
  isTrader: boolean;
  isSalesLinkDisplayed: boolean;
  haveEffectiveSettlementDatesSameOffset: boolean | undefined;
};

export type GlobalActionsModel = GlobalActionsModelPart1 & {
  isPreConfWarningDisplayed: boolean;
  isSpotWarningDisplayed: boolean;
};

export function getGlobalActionsModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): GlobalActionsModelPart1 {
  const { strategyIds, clientWay } = selectors.getRfqData(state, rfqId);
  const doesSalesPricesMismatchExecutedPrices = strategyIds.some(strategyId => {
    const { legIds, weight: strategyWeight } = selectors.getStrategyData(state, strategyId);
    return legIds.some(legId => {
      const { weight: ratio } = selectors.getLegData(state, legId);
      const order = selectors.getOrderByLegId(state.orderData, rfqId, legId);
      const averagePrice = order?.averagePrice;
      const isStatusAfterOrderBeingCrossed = selectors.isStatusAfterOrderBeingCrossed(
        state,
        rfqId,
        selectors,
      );
      const isListedStrategy = selectors.isListedStrategy(state, strategyId, selectors);
      const { salesAsk, salesBid, salesAskType, salesBidType } = selectors.getQuote(state, legId);

      const quotePriceToCompare = getQuoteToCompare(
        clientWay,
        strategyWeight,
        ratio,
        salesAsk,
        salesBid,
      );

      const averagePriceWarningEnabled = isListedStrategy && isStatusAfterOrderBeingCrossed;
      const isAveragePriceTooltipSalesAskEnabled =
        averagePriceWarningEnabled &&
        clientWay === 'BUY' &&
        salesAskType === 'CCY' &&
        quotePriceToCompare !== averagePrice?.value;
      const isAveragePriceTooltipSalesBidEnabled =
        averagePriceWarningEnabled &&
        clientWay === 'SELL' &&
        salesBidType === 'CCY' &&
        quotePriceToCompare !== averagePrice?.value;

      return isAveragePriceTooltipSalesBidEnabled || isAveragePriceTooltipSalesAskEnabled;
    });
  });

  const hasBookingSteps = selectors.hasRFQBookingSteps(state, rfqId);

  const products = selectors.getRfqProducts(state, rfqId, selectors);
  const isEls =
    selectors.isFeatureToggleEnabled(state, 'neos.els.update.enabled') &&
    products.some(p => isElsProduct(p));
  const product = isEls ? (products[0] as Els) : undefined;

  return {
    isReadonlyRfq: selectors.isReadOnlyRfq(state, rfqId),
    hasBookingSteps,
    isMidOutOfTraderPrices: isAnyMidOutOfTraderPrices(state, rfqId, selectors),
    areTraderPricesOutOfSalesPrices: isAnyTraderPricesOutOfSalesPrices(state, rfqId, selectors),
    doesSalesPricesMismatchExecutedPrices,
    isTraderDeltaFarFromFairDelta: isAnyTraderDeltaFarFromFairDelta(state, rfqId, selectors),
    isQuoteTypeDisplayed: isQuoteTypeDisplayed(state, rfqId, selectors),
    isMixedRfq: selectors.isMixedRfq(state, rfqId),
    isSecondaryRfq: selectors.isSecondaryRfq(state, rfqId),
    areSecondaryFeatureTogglesEnabled: selectors.areEveryRfqProductsSecondaryFeatureTogglesEnabled(
      state,
      rfqId,
      selectors,
    ),
    isEls,
    isTrader: selectors.isTrader(state),
    isSalesLinkDisplayed: isSalesLinkDisplayed(state, rfqId, selectors),
    haveEffectiveSettlementDatesSameOffset:
      isEls && product
        ? haveEffectiveSettlementDatesSameOffset(state, product, selectors)
        : undefined,
  };
}

export function isSalesLinkDisplayed(state: AppState, rfqId: string, selectors: Selectors) {
  return selectors.getSalesLink(state, rfqId) === false;
}

export function isQuoteTypeDisplayed(appState: AppState, rfqId: string, selectors: Selectors) {
  return (
    selectors.getIsIndicativeTradableDisplayed(appState.ui.userPreferences) ||
    selectors.getRfqProducts(appState, rfqId, selectors).some(p => isElsProduct(p))
  );
}

export function isAnyTraderPricesOutOfSalesPrices(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const strategiesQuoteIds = selectors.getStrategiesQuoteIds(state, rfqId, selectors);
  return strategiesQuoteIds.some(quoteId => {
    const { traderAsk, traderBid, salesAsk, salesBid } = selectors.getQuote(state, quoteId);

    if (isDefined(traderAsk) && isDefined(salesAsk)) {
      if (salesAsk < traderAsk) {
        return true;
      }
    }

    if (isDefined(traderBid) && isDefined(salesBid)) {
      if (salesBid > traderBid) {
        return true;
      }
    }

    return false;
  });
}

export function isAnyMidOutOfTraderPrices(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const fairPriceQuoteIds = selectors.getStrategiesFairPriceQuoteIds(state, rfqId, selectors);
  return fairPriceQuoteIds.some(({ fairPriceId, quoteId }) => {
    if (!fairPriceId) {
      return false;
    }

    const { mid } = selectors.getFairPrice(state, fairPriceId);
    const { traderAsk, traderBid } = selectors.getQuote(state, quoteId);

    if (isDefined(traderBid) && isDefined(traderAsk) && isDefined(mid)) {
      return mid < traderBid || mid > traderAsk;
    }

    return false;
  });
}

export function isAnyTraderDeltaFarFromFairDelta(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
) {
  const fairPriceQuoteIds = selectors.getStrategiesFairPriceQuoteIds(state, rfqId, selectors);
  return fairPriceQuoteIds.some(({ fairPriceId, quoteId }) => {
    if (!fairPriceId) {
      return false;
    }

    const { delta: fairDelta } = selectors.getFairPrice(state, fairPriceId);
    const { delta: traderDelta } = selectors.getQuote(state, quoteId);

    return isDefined(fairDelta) && isDefined(traderDelta) && Math.abs(traderDelta - fairDelta) > 5;
  });
}

export function getQuoteToCompare(
  clientWay: Way | undefined,
  strategyWeight: number,
  ratio: number,
  salesAsk: number | undefined,
  salesBid: number | undefined,
): number | undefined {
  const weight = strategyWeight * ratio;
  if (clientWay === 'BUY' && weight >= 0) {
    return salesAsk;
  }
  if (clientWay === 'BUY' && weight < 0) {
    return salesBid;
  }
  if (clientWay === 'SELL' && weight >= 0) {
    return salesBid;
  }
  if (clientWay === 'SELL' && weight < 0) {
    return salesAsk;
  }
  return undefined;
}

function haveEffectiveSettlementDatesSameOffset(
  state: AppState,
  product: Els,
  selectors: Selectors,
) {
  const { settlement } = selectors.getLegData(state, product.legId);
  return product.effectiveDateOffset === settlement?.date?.offset;
}
