import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { Dispatchable } from '@/bootstrap/thunks';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import { neosThunks } from '@/neos/business/thunks';
import { useAppSelector } from '@/bootstrap/hooks';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { useDispatch } from 'react-redux';
import {
  SwapCurrencyCells as Component,
  type SwapCurrencyCellsOwnProps,
} from './SwapCurrencyCells';
import { getSwapCurrencyCellsModel } from './getSwapCurrencyCellsModel';

export function SwapCurrencyCells(props: SwapCurrencyCellsOwnProps) {
  const dispatch = useDispatch();
  const model = useAppSelector(state =>
    getSwapCurrencyCellsModel(state, props.rfqId, props.strategyId, selectors),
  );
  const isInitiatedByTrader = useAppSelector(state =>
    selectors.isRfqInitiatedByTrader(state, props.rfqId),
  );
  const interestRateIndexToggle = useFeatureToggle('neos.els.scope.Interest.Rate.Index.enabled');
  const isEls = useAppSelector(state =>
    selectors.isElsStrategy(state, props.strategyId, selectors),
  );
  const isElsBasket = useAppSelector(state =>
    selectors.isStrategyElsBasket(state, props.rfqId, selectors),
  );

  const onSwapCurrencyChanged = (productId: string, swapCurrency: string | undefined) => {
    const overrideScopes = [
      DefaultingScope.CALCULATION_METHOD,
      DefaultingScope.BREAK_FEE,
      DefaultingScope.DIVIDEND_CURRENCY,
      DefaultingScope.INTEREST_RATE_INDEX,
      DefaultingScope.UNITS,
    ];

    if (isInitiatedByTrader) {
      overrideScopes.push(DefaultingScope.BROKER_COMMISSION_CURRENCY);
    }
    if (isElsBasket) {
      overrideScopes.push(
        DefaultingScope.UNDERLYING,
        DefaultingScope.NOTIONAL,
        DefaultingScope.NEGOTIATED_SIZE,
      );
    }

    const dispatchables: Array<Dispatchable> = [
      neosThunks.createUpdateProductAndResetDependenciesThunk(
        props.rfqId,
        props.strategyId,
        productId,
        'swapCurrency',
        swapCurrency,
      ),
      neosThunks.createFetchCurvesListBasedOnCurrencyThunk(props.rfqId, [props.strategyId]),
    ];

    if (isEls) {
      const options = new DefaultingOptions({
        overrideScopes,
        enrichScopes: [DefaultingScope.FOREX],
        isSettlementOtcOverride: true,
      });
      if (interestRateIndexToggle) {
        dispatchables.push(
          actionCreators.neos.createDefaultRfqRequestedAction(props.rfqId, options),
        );
      }
    }
    dispatch(dispatchables);
  };

  return <Component onSwapCurrencyChanged={onSwapCurrencyChanged} {...props} {...model} />;
}
