import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { isElsProduct } from '@/neos/business/neosModel';
import {
  type EquityFinanceType,
  equityFinanceTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

export function EquityFinanceTypeComponent() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();

  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const feature = useAppSelector(state =>
    selectors.getFeature(state.featureState, {
      strategyId,
      type: 'EQUITY_FINANCE',
    }),
  );

  if (!isElsProduct(product) || product.dealType !== 'EQUITY_FINANCE') {
    return null;
  }

  const onEquityFinanceTypeChange = (newEquityFinance: EquityFinanceType | undefined) => {
    dispatch(
      actionCreators.neos.featureCrudActions.upsert(
        {
          strategyId,
          type: 'EQUITY_FINANCE',
        },
        {
          type: 'EQUITY_FINANCE',
          strategyId,
          equityFinance: newEquityFinance,
        },
      ),
    );
  };

  return (
    <ElsBlocField
      label="Equity Finance"
      renderInput={readOnly => (
        <NeosSelect
          readOnly={readOnly}
          onChange={onEquityFinanceTypeChange}
          data-e2e="equity-finance-type"
          options={Object.entries(equityFinanceTypes).map(([value, label]) => ({
            label,
            value: value as EquityFinanceType,
          }))}
          value={feature?.equityFinance}
          addEmptyOption
        />
      )}
    />
  );
}
