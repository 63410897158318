import type { Thunk } from '@/bootstrap/thunks';
import type {
  BasketCompositionDetails,
  ElsBasket,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import { updateInArray } from '@/util/array/arrayUtils';

export function createUpdateElsBasketCompositionThunk<K extends keyof BasketCompositionDetails>(
  product: ElsBasket,
  basketCompositionIndex: number,
  key: K,
  value: BasketCompositionDetails[K],
): Thunk {
  return function updateElsBasketComposition(dispatch, _, { actionCreators }) {
    const newBasketCompositionElement: BasketCompositionDetails = {
      ...product.basketUnderlying.basketComposition[basketCompositionIndex],
      [key]: value,
    };

    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        basketUnderlying: {
          ...product.basketUnderlying,
          basketComposition: updateInArray(
            product.basketUnderlying.basketComposition,
            basketCompositionIndex,
            newBasketCompositionElement,
          ),
        },
      }),
    );
  };
}
