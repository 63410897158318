import type { Thunk } from '@/bootstrap/thunks';
import {
  type StockLoanHedge,
  initialStockLoanHedge,
  isElsProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel';

export function createUpdateElsStockLoanHedgeThunk<K extends keyof StockLoanHedge>(
  productId: string,
  key: K,
  value: StockLoanHedge[K],
): Thunk {
  return function updateElsStockLoanHedgeThunk(dispatch, getState, { actionCreators, selectors }) {
    const product = selectors.getProduct(getState(), productId);
    if (!isElsProduct(product)) {
      return;
    }

    const oldStockLoanHedge: StockLoanHedge = product.stockLoanHedge ?? initialStockLoanHedge;

    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        stockLoanHedge: {
          ...oldStockLoanHedge,
          [key]: value,
        },
      }),
    );
  };
}
