import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { EquityReset } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EquityBloc/EquityReset';
import { FinalPriceDetermination } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EquityBloc/FinalPriceDetermination';
import { InitialPriceDetermination } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EquityBloc/InitialPriceDetermination';
import { RefSpot } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EquityBloc/RefSpot';
import { RefSpotInSwapCcy } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EquityBloc/RefSpotInSwapCcy';
import { SpotConfirmedCheckbox } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EquityBloc/SpotConfirmedCheckbox';
import { If } from '@/neos/components/share/if/if';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { isElsBasketProduct, isElsProduct } from '../../../../../../../../neos/business/neosModel';
import { ElsFeaturesContext, commonElsBlocClasses } from '../ElsFeatures';
import { useUpdateSectionHeight } from '../useUpdateSectionHeight';
import { ElectionDate } from './ElectionDate';
import { ElectionFee } from './ElectionFee';
import { RefSpotNet } from './RefSpotNet';
import { SettlementMethodElection } from './SettlementMethodElection';

interface EquityProps {
  canBeHidden: boolean;
}

export function EquityBloc({ canBeHidden }: EquityProps) {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const { ref } = useUpdateSectionHeight(strategyId, 'Equity Leg');
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );

  if (!isElsProduct(product)) {
    return null;
  }

  function hideSection() {
    dispatch(thunks.neos.createToggleElsSectionThunk('Equity Leg', false));
  }

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <h5 className="d-inline-block p-1 m-0 mb-1">Equity</h5>
          <If condition={canBeHidden}>
            <button className="btn btn-icon btn-flat-primary" onClick={hideSection}>
              <i className="icon">clear</i>
            </button>
          </If>
        </div>

        <div className="d-flex flex-wrap">
          <EquityReset />
          <InitialPriceDetermination />
          <FinalPriceDetermination />
          {!isElsBasketProduct(product) && (
            <>
              <RefSpot />
              <RefSpotNet />
              <RefSpotInSwapCcy />
              <SpotConfirmedCheckbox underlyingId={product.underlyingId} />
            </>
          )}
          <SettlementMethodElection />
          <ElectionFee />
          <ElectionDate />
        </div>
      </div>
    </div>
  );
}
