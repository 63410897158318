import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { Workflow } from '@/neos/business/rfq/rfqOnyxModel';
import { neosThunks } from '@/neos/business/thunks';
import type { MultipleTypeaheadValue } from '@/neos/components/share/multipleAsyncTypeahead';
import { connect } from 'react-redux';
import { BlotterSearchComponent, type BlotterSearchDispatchProps } from './BlotterSearch';
import { type BlotterSearchPropsFromState, getBlotterSearchModel } from './getBlotterSearchModel';

function mapStateToProps(state: AppState): BlotterSearchPropsFromState {
  return getBlotterSearchModel(state, selectors);
}

function mapDispatchToProps(dispatch: ExtendedDispatch): BlotterSearchDispatchProps {
  return {
    changeBlotterFromDate: (from: string) =>
      dispatch(neosActionCreators.changeBlotterFromDate(from)),
    changeBlotterUnderlyingsOrStatusesOrCounterparts: (typeaheadValues: MultipleTypeaheadValue[]) =>
      dispatch(neosActionCreators.changeBlotterMultipleCriterias(typeaheadValues)),
    changeBlotterToDate: (to: string) => dispatch(neosActionCreators.changeBlotterToDate(to)),
    requestBlotterRfqs: () => dispatch(neosThunks.createRequestBlotterThunk()),
    changeBlotterWorkflows: (workflows: Workflow[]) =>
      dispatch(neosActionCreators.changeBlotterWorkflows(workflows)),
    requestBlotterWithFromToDates: (from: string, to: string) =>
      dispatch(neosThunks.createRequestBlotterThunk({ from, to })),
    cleanTypeaheadFilters: () => dispatch(neosActionCreators.changeBlotterMultipleCriterias([])),
    changeMaturityDate: (maturityDates: string) =>
      dispatch(neosActionCreators.changeBlotterMaturityDate(maturityDates)),
    changeMaturityDateRange: (maturityDateFrom: string, maturityDateTo: string) =>
      dispatch(neosActionCreators.changeBlotterMaturityDateRange(maturityDateFrom, maturityDateTo)),
  };
}

export const BlotterSearch = connect(mapStateToProps, mapDispatchToProps)(BlotterSearchComponent);
