import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { PredealCheck, RiskPredealCheck } from './predealCheckModel';

export const predealCheckSelectors = {
  getPredealCheck,
  getCreditCheckAskPdc,
  getCreditCheckBidPdc,
};

function getPredealCheck(state: AppState, predealCheckId: string): PredealCheck | undefined {
  return state.predealCheck[predealCheckId];
}

function getCreditCheckAskPdc(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): RiskPredealCheck | undefined {
  const riskAskPdc = selectors
    .getNeosPredealChecks(state, rfqId, selectors)
    .find(predealCheck => predealCheck.type === 'CREDIT_CHECK_ASK');

  if (!!riskAskPdc && isRiskPdc(riskAskPdc)) {
    return riskAskPdc;
  }

  return undefined;
}

function getCreditCheckBidPdc(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): RiskPredealCheck | undefined {
  const riskBidPdc = selectors
    .getNeosPredealChecks(state, rfqId, selectors)
    .find(predealCheck => predealCheck.type === 'CREDIT_CHECK_BID');

  if (!!riskBidPdc && isRiskPdc(riskBidPdc)) {
    return riskBidPdc;
  }

  return undefined;
}

function isRiskPdc(predealCheck: PredealCheck): predealCheck is RiskPredealCheck {
  return predealCheck.type === 'CREDIT_CHECK_ASK' || predealCheck.type === 'CREDIT_CHECK_BID';
}
