import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type {
  EquityLegPeriodDates,
  LegPeriod,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { assertProductIsEls } from '../../../../neos/business/neosModel';

export function createAddEquitySchedulePeriodThunk(
  strategyId: string,
  datesUpdate: EquityLegPeriodDates = {
    endDate: '',
    paymentDate: '',
    startDate: '',
  },
): Thunk {
  return function addEquitySchedulePeriodThunk(
    dispatch,
    getState,
    {
      selectors,
      services,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();

    const product = selectors.getStrategyProduct(appState, strategyId, selectors);

    assertProductIsEls(product);

    const newPeriod: LegPeriod<EquityLegPeriodDates> = {
      uuid: services.idCreator.createId(),
      dates: datesUpdate,
    };

    const action: Action = productCrudActions.update(product.uuid, {
      equityPeriods: [...(product.equityPeriods ?? []), newPeriod],
    });

    dispatch(action);
  };
}
