import type { DoubleDate } from '@/neos/components/share/datePicker/DoubleDatePicker';
import {
  addBusinessDays,
  addMinutes,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  isAfter as isAfterDateDns,
  isMonday,
  isWithinInterval as isWithinIntervalDateFns,
  parseISO,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import type { DisplayTimeZone } from '../../neos/business/ui/uiModel';

export const DATE_SHORT_KEBAB_FORMAT = 'yyyy-MM-dd';
export const DATE_LONG_KEBAB_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DATE_SHORT_LITTERAL_FORMAT = 'ddMMMyyyy';
export const DATE_LONG_LITTERAL_FORMAT = 'ddMMMyyyy HH:mm:ss';
export const DATE_TIME_FORMAT = 'h:mm a';
export const DATE_SHORT_TIME_FORMAT = 'HH:mm';
export const DATE_LONG_TIME_FORMAT = 'HH:mm:ss';
export const DATE_LONG_COMPACT_FORMAT = 'yyMMddHHmm';
export const DATE_FILE_FORMAT = 'yyyy-MM-dd HH-mm-ss';
export const DATE_CURRENT_TIME_FORMAT = 'MMMM do yyyy, h:mm:ss aaa';

export const DATE_INVALID = 'Invalid date';

const convert = (date: Date | string): Date => (date instanceof Date ? date : new Date(date));

export const toUtc = (date: Date | string): Date => {
  const converted = convert(date);
  const offset = converted.getTimezoneOffset();
  return addMinutes(converted, offset);
};

export const formatShort = (date: Date) => {
  return format(date, DATE_SHORT_KEBAB_FORMAT);
};
export function formatDoubleDate([start, end]: DoubleDate): [string, string] {
  const startDateString = start ? formatShort(start) : '';
  const endDateString = end ? formatShort(end) : '';

  return [startDateString, endDateString];
}

export const isAfter = (beforeDate: Date | string, afterDate: Date | string): boolean =>
  isAfterDateDns(convert(beforeDate), convert(afterDate));

export const utcNow = () => toUtc(new Date());

export const formatUtcNow = (formatValue = DATE_LONG_COMPACT_FORMAT): string =>
  format(utcNow(), formatValue);

export const formatNow = (formatValue = DATE_LONG_COMPACT_FORMAT): string =>
  format(new Date(), formatValue);

export const getToday = () => formatShort(new Date());
export const getYesterday = () => formatShort(subDays(new Date(), isMonday(new Date()) ? 3 : 1));
export const getTomorrow = () => formatShort(addBusinessDays(new Date(), 1));
export const getStartOfThisWeek = () => formatShort(startOfWeek(new Date(), { weekStartsOn: 1 }));

export const getStartOfLastWeek = () =>
  formatShort(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }));
export const getEndOfLastWeek = () =>
  formatShort(endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }));

export const getStartOfThisMonth = () => formatShort(startOfMonth(new Date()));
export const getStartOfLastMonth = () => formatShort(startOfMonth(subMonths(new Date(), 1)));
export const getEndOfLastMonth = () => formatShort(endOfMonth(subMonths(new Date(), 1)));

export const getTwoDaysAgo = () => formatShort(subDays(new Date(), 2));
export const getThreeDaysAgo = () => formatShort(subDays(new Date(), 3));

export const isWithinInterval = (date: string, [left, right]: [string, string]): boolean =>
  isWithinIntervalDateFns(parseISO(date), {
    start: parseISO(left),
    end: endOfDay(parseISO(right)),
  });

export const isDateInToday = (date: string): boolean => {
  const today = getToday();
  return isWithinInterval(date, [today, today]);
};

export const dateStringToIsoString = (dateString: string): string =>
  dateString.endsWith('Z') ? dateString : `${dateString}Z`;

export const formatNowInTimeZone = (
  displayTimeZone: DisplayTimeZone,
  formatValue = DATE_TIME_FORMAT,
): string =>
  displayTimeZone === 'GMT' ? `${formatUtcNow(formatValue)} GMT` : `${formatNow(formatValue)}`;

export const formatDateInTimeZone = (
  displayTimeZone: DisplayTimeZone,
  inputDate: string,
  formatValue = DATE_LONG_LITTERAL_FORMAT,
): string => {
  const converted = displayTimeZone === 'GMT' ? toUtc(inputDate) : convert(inputDate);
  return format(converted, formatValue);
};
