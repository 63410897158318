import type { BulletColor } from '../predealCheckBullet';

const orderedColorsList: BulletColor[] = [
  'red',
  'amber',
  'grey',
  'red-derogated',
  'green',
  'red-blur',
  'amber-blur',
  'grey-blur',
];

export const getAggregationColor = (colors: BulletColor[]): BulletColor => {
  for (const color of orderedColorsList) {
    if (colors.some(c => c === color)) {
      return color;
    }
  }
  return 'green-blur';
};
