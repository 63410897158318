import type { FC } from 'react';
import {
  type FieldInfo,
  GenericLegsQuotes,
} from '../../../prices/quotes/genericQuotes/GenericLegsQuotes';
import type { TraderBidModel } from './getTraderBidModel';

export interface TraderBidOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface TraderBidStateProps {
  model: TraderBidModel;
}

export interface TraderBidDispatchProps {
  onLegTraderBidChanged: (quoteId: string, legId: string, value?: number) => void;
}

export type TraderBidProps = TraderBidOwnProps & TraderBidStateProps & TraderBidDispatchProps;

export const TraderBidComponent: FC<TraderBidProps> = ({
  rfqId,
  strategyId,
  model: { legs, isEnabled },
  onLegTraderBidChanged,
}) => {
  const fieldInfo: FieldInfo = {
    color: 'BID',
    fieldName: 'traderBid',
    isTransparentKey: 'bidIsTransparent',
    legErrorField: 'legTraderPriceBid',
  };
  return (
    <section>
      <label>Trd Bid</label>
      <GenericLegsQuotes
        rfqId={rfqId}
        strategyId={strategyId}
        fieldInfo={fieldInfo}
        legsQuotes={legs}
        areTraderPricesEnabled={isEnabled}
        isUnitDisplayed={true}
        onLegChanged={onLegTraderBidChanged}
      />
    </section>
  );
};
