import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import {
  type StrategyScope,
  isElsProduct,
  isListedProduct,
} from '../../../../../../business/neosModel';

interface CommonStrategyActionsButtonModel {
  isSplittable: boolean;
  scope: StrategyScope;
  isToggleLegPricesVisible: boolean;
  isExtraFeatureEnabled: boolean;
  isReadonly: boolean;
  readOnlyNumberOfLegs: boolean;
  isElsMenuEnabled: boolean;
}

interface StrategyActionsButtonWithReadOnlyNumberOflegs extends CommonStrategyActionsButtonModel {
  readOnlyNumberOfLegs: true;
}

interface StrategyActionsButtonWithEditableNumberOflegs extends CommonStrategyActionsButtonModel {
  readOnlyNumberOfLegs: false;
  removableLegIds: string[];
}

export type StrategyActionsButtonModel =
  | StrategyActionsButtonWithReadOnlyNumberOflegs
  | StrategyActionsButtonWithEditableNumberOflegs;

export function getStrategyActionsButtonModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): StrategyActionsButtonModel {
  const { scope, strategyType, legIds } = selectors.getStrategyData(state, strategyId);
  const product = selectors.getStrategyProducts(state, strategyId, selectors)[0];

  const strategyDefinition = selectors.getStrategyDefinition(state.referenceData, strategyType);
  const readOnlyNumberOfLegs =
    strategyDefinition?.readOnlyNumberOfLegs ||
    selectors.hasACompositionLeg(state, strategyId, selectors);
  const { status } = selectors.getRfqData(state, rfqId);
  const isToggleLegPricesVisible =
    services.orderOf(status).isBefore('ORDER_ACCEPTED') && legIds.length > 1;
  const isAnyExtraFeatureEligible = selectors.isAnyExtraFeatureEligible(
    state.referenceData,
    strategyType,
  );
  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors);

  const isReadonly = selectors.isReadOnlyRfq(state, rfqId) || isReadonlyAtWorkflow;
  const partialModel: Omit<CommonStrategyActionsButtonModel, 'readOnlyNumberOfLegs'> = {
    isReadonly,
    isSplittable: !!strategyDefinition?.isSplittable,
    scope,
    isToggleLegPricesVisible,
    isExtraFeatureEnabled: !isListedProduct(product) && isAnyExtraFeatureEligible,
    isElsMenuEnabled:
      isElsProduct(product) && selectors.isFeatureToggleEnabled(state, 'neos.els.update.enabled'),
  };

  const [, ...removableLegIds] = legIds;

  return readOnlyNumberOfLegs
    ? { ...partialModel, readOnlyNumberOfLegs }
    : { ...partialModel, readOnlyNumberOfLegs, removableLegIds };
}
