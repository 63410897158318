import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { LegRecomputeChangedScope } from '@/neos/business/rfq/models';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  TraderAskComponent,
  type TraderAskDispatchProps,
  type TraderAskOwnProps,
  type TraderAskStateProps,
} from './TraderAsk';
import { getTraderAskModel } from './getTraderAskModel';

export const TraderAsk = connect(mapStateToProps, mapDispatchToProps)(TraderAskComponent);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: TraderAskOwnProps,
): TraderAskStateProps {
  return {
    model: getTraderAskModel(state, rfqId, strategyId, selectors),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: TraderAskOwnProps,
): TraderAskDispatchProps {
  return {
    onLegTraderAskChanged(quoteId: string, legId: string, value?: number) {
      dispatch(
        neosThunks.createRfqUpdateLegQuoteThunk({
          rfqId,
          strategyId,
          quoteId,
          legId,
          quotePatch: { traderAsk: value },
          scope: LegRecomputeChangedScope.TRADER_PRICE,
        }),
      );
    },
  };
}
