import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { LegRecomputeChangedScope } from '@/neos/business/rfq/models';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  SalesBidComponent,
  type SalesBidDispatchProps,
  type SalesBidOwnProps,
  type SalesBidStateProps,
} from './SalesBid';
import { getSalesBidModel } from './getSalesBidModel';

export const SalesBid = connect(mapStateToProps, mapDispatchToProps)(SalesBidComponent);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: SalesBidOwnProps,
): SalesBidStateProps {
  return {
    model: getSalesBidModel(state, rfqId, strategyId, selectors),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: SalesBidOwnProps,
): SalesBidDispatchProps {
  return {
    onLegSalesBidChanged(quoteId: string, legId: string, value?: number) {
      dispatch(
        neosThunks.createRfqUpdateLegQuoteThunk({
          rfqId,
          strategyId,
          quoteId,
          legId,
          quotePatch: { salesBid: value },
          scope: LegRecomputeChangedScope.SALES_PRICE,
        }),
      );
    },
  };
}
