import type { NegotiationMode } from '@/neos/business/neosModel';
import { MultiToggleButton } from '@/neos/components/share/multiToggleButton/MultiToggleButton';
import type { NegoTypeCellModel } from './getNegoTypeCellModel';

export interface NegoTypeCellOwnProps {
  rfqId: string;
  strategyId: string;
}

export type NegoTypeCellMapStateProps = NegoTypeCellModel;

export interface NegoTypeCellDispatchProps {
  onNegotiationTypeChanged(negotiationType: NegotiationMode): void;
}

type NegoTypeCellProps = NegoTypeCellOwnProps &
  NegoTypeCellMapStateProps &
  NegoTypeCellDispatchProps;

export function NegoTypeCellUnconnected(props: NegoTypeCellProps) {
  return (
    <section>
      {props.negoTypes.map(({ id, value, isEditable }) => (
        <MultiToggleButton<NegotiationMode>
          className="form-control"
          key={id}
          readOnly={!isEditable}
          selectedValue={value}
          valueFormatter={v => (v ? (v === 'LISTED' ? 'Listed' : v) : '')}
          availableValues={['OTC', 'LISTED']}
          onValueChange={newNegType => props.onNegotiationTypeChanged(newNegType)}
          data-e2e="neos-strategy-definition-nego-type"
        />
      ))}
    </section>
  );
}
