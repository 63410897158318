import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import {
  commonElsBlocClasses,
  ElsFeaturesContext,
} from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { useUpdateSectionHeight } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/useUpdateSectionHeight';
import { ElsLendAndBorrowComponents } from '@/neos/components/rfq/strategies/strategy/features/elsLendAndBorrow/ElsLendAndBorrowComponents';
import { ElsLendAndBorrowDescription } from '@/neos/components/rfq/strategies/strategy/features/elsLendAndBorrow/ElsLendAndBorrowDescription';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { If } from '@/neos/components/share/if/if';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

type ElsLendAndBorrowProps = {
  canBeHidden: boolean;
};

export function ElsLendAndBorrow({ canBeHidden }: ElsLendAndBorrowProps) {
  const dispatch = useDispatch();
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const { ref } = useUpdateSectionHeight(strategyId, 'LendAndBorrow');
  const product = useAppSelector(state =>
    selectors.getStrategyMasterProduct(state, strategyId, selectors),
  );
  const isDisplayed = isElsProduct(product);
  const isFeatureToggleEnabled = useFeatureToggle('neos.els.lend-and-borrow');

  function hideSection() {
    dispatch(thunks.neos.createToggleElsSectionThunk('LendAndBorrow', false));
  }

  if (!isDisplayed) {
    return null;
  }

  function onLendAndBorrowToggle() {
    dispatch(thunks.neos.createToggleElsLendAndBorrowFeatureThunk(rfqId, product.uuid));
  }

  const isToggled = product.stockLoanHedge !== undefined;

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <div
            onMouseDown={e => e.stopPropagation()}
            className="d-flex justify-content-between align-items-center"
          >
            <If condition={isFeatureToggleEnabled}>
              <Switch onChange={onLendAndBorrowToggle} checked={isToggled} />
            </If>

            <h5 className="d-inline-block p-1 m-0 mb-1">Lend & Borrow</h5>
          </div>
          {canBeHidden && (
            <button className="btn btn-icon btn-flat-primary" onClick={hideSection}>
              <i className="icon">clear</i>
            </button>
          )}
        </div>

        <If condition={isToggled}>
          <div>
            <ElsLendAndBorrowDescription product={product} rfqId={rfqId} />
            <ElsLendAndBorrowComponents product={product} strategyId={strategyId} rfqId={rfqId} />
          </div>
        </If>
      </div>
    </div>
  );
}
