import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { sortBy } from 'lodash';
import { connect } from 'react-redux';
import type { Counterpart, User } from '../../../business/neosModel';

import { selectors } from '@/bootstrap/selectors';
import { commonSelectors } from '@/common/business/commonSelectors';
import { neosThunks } from '@/neos/business/thunks';
import {
  type DisplayUser,
  toDisplayUser,
  tryGetUserFullName,
  userIdToFullName,
} from '../../share/userUtils/userUtils';
import {
  ActorsSetupComponent,
  type ActorsSetupDispatchProps,
  type ActorsSetupMapStateToProps,
  type ActorsSetupOwnProps,
} from './ActorsSetup';

export const ActorsSetup = connect(mapStateToProps, mapDispatchToProps)(ActorsSetupComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: ActorsSetupOwnProps,
): ActorsSetupMapStateToProps {
  const isTrader = selectors.isTrader(state);

  const selectedSalesValo = selectors.getSelectedSalesValoId(state, rfqId);
  const salesValos = selectors.getSalesValos(state.referenceData);
  const availableSalesValos = getAndFormatSalesValos(isTrader, salesValos, selectedSalesValo);

  const userName = commonSelectors.getUserName(state);
  const sesameId = commonSelectors.getSesameId(state);
  const actorsSetup = selectors.getActorsSetup(state, rfqId);
  const salesInitId = actorsSetup?.salesInitId ?? sesameId;

  const salesInitName = tryGetUserFullName(salesValos, salesInitId, userName);
  const counterpartPopoverInfo = selectors.getSalesCounterpart(state, rfqId, selectors);

  const { status, initiatedByTrader } = selectors.getRfqData(state, rfqId);

  const availableClients = getAndFormatAvailableClients(state, rfqId);
  const selectedClientId =
    selectors.getSelectedCounterpartId(state, rfqId) ||
    (availableClients.length === 1 ? availableClients[0].id : undefined);

  const userInitFieldName = `${initiatedByTrader ? 'Trader' : 'Sales'} init`;
  const isInternalEls = selectors.isRfqInternalElsAndToggled(state, rfqId, selectors);
  const isElsIdb = selectors.isRfqInitiatedByTrader(state, rfqId);

  return {
    counterpartPopoverInfo,
    availableSalesValos,
    salesInitId,
    selectedSalesValo,
    salesInitName,
    selectedClient: selectedClientId?.toString(),
    availableClients,
    isReadonlyRfq: selectors.isReadOnlyRfq(state, rfqId),
    isDisabled:
      selectors.isStatusOrderConfirmedOrAfter(state, rfqId, selectors) &&
      status !== 'AMEND_REQUESTED',
    userInitFieldName,
    isInternalEls,
    isElsIdb,
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: ActorsSetupOwnProps,
): ActorsSetupDispatchProps {
  return {
    onSalesValoChanged: (salesInitId: string, salesValoId: string | undefined) =>
      dispatch(neosThunks.createChangeSalesValoThunk(rfqId, salesInitId, salesValoId)),
    onCounterpartChanged: (counterpartId: number) =>
      dispatch(neosThunks.createChangeSalesCounterpartyThunk(rfqId, counterpartId)),
  };
}

function getAndFormatSalesValos(
  isTrader: boolean,
  salesValos: Record<string, User>,
  selectedSalesValo: string | undefined,
): DisplayUser[] {
  const salesValoList: DisplayUser[] = Object.values(salesValos).map(toDisplayUser);

  if (!isTrader && selectedSalesValo && !salesValos[selectedSalesValo]) {
    salesValoList.push({
      id: selectedSalesValo,
      fullName: userIdToFullName(selectedSalesValo),
    });
  }

  return sortBy(salesValoList, salesValo => salesValo.fullName.toLocaleLowerCase());
}

function getAndFormatAvailableClients(state: AppState, selectedTabId: string): Counterpart[] {
  const counterparts: Counterpart[] = selectors.getCounterparts(state, selectedTabId) ?? [];
  const capitalizedCounterparts: Counterpart[] = counterparts.map(capitalizeCounterpartName);
  return sortBy(capitalizedCounterparts, [({ name }: Counterpart) => name]);
}

function capitalizeCounterpartName(counterpart: Counterpart): Counterpart {
  return {
    ...counterpart,
    name: counterpart.name.toUpperCase(),
  };
}
