import type { Thunk } from '@/bootstrap/thunks';
import { LegRecomputeScope } from '@/neos/business/rfq/models';
import type { ExecFeesValue } from '@/neos/business/rfq/strategy/leg/product/productModel';

export function createChangeElsBasketExecFeeKindThunk(
  rfqId: string,
  productId: string,
  value: ExecFeesValue,
): Thunk {
  return function changeElsBasketExecFeeKindThunk(dispatch, _, { actionCreators, thunks }) {
    dispatch(thunks.neos.createUpdateElsBasketThunk(productId, 'execFees', value));
    dispatch(thunks.neos.createCleanBasketCompositionExecFees(productId, value));
    dispatch(
      actionCreators.neos.createRecomputeRfqRequestedAction(
        rfqId,
        LegRecomputeScope.CHANGE_EXEC_FEE,
      ),
    );
  };
}
