import type { AllocationPredealCheck } from '@/neos/business/predealCheck/predealCheckModel';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { PredealCheckBullet } from '../../../../predealChecks/predealCheckBullet';
import { convertToBulletColor, getAggregationColor } from '../../../../predealChecks/util';

export interface OtcAllocPdcOwnProps {
  counterpartId?: number;
  rfqId: string;
  type: 'RISK' | 'OTHER';
}

export interface OtcAllocPdcStateProps {
  riskPdc?: AllocationPredealCheck;
  othersPdc: AllocationPredealCheck[];
}

export interface OtcAllocPdcDispatchProps {
  onClick: () => void;
}

export type OtcAllocPdcProps = OtcAllocPdcOwnProps &
  OtcAllocPdcStateProps &
  OtcAllocPdcDispatchProps;

export const OtcAllocPdcComponent = (props: OtcAllocPdcProps) => {
  const riskColor = convertToBulletColor(props.riskPdc?.uiColor || 'UNKNOWN');
  const othersColor = getAggregationColor(
    props.othersPdc.map(pdc => convertToBulletColor(pdc.uiColor)),
  );

  const isLoading =
    props.riskPdc?.status === 'COMPUTING' ||
    (props.othersPdc.length > 0 && props.othersPdc.some(pdc => pdc.status === 'COMPUTING'));

  return props.counterpartId ? (
    <SimpleNeosTooltip message={props.type === 'RISK' ? 'Credit Check' : 'Regulation'}>
      <div onClick={props.onClick}>
        <PredealCheckBullet
          isSelectable={false}
          color={props.type === 'RISK' ? riskColor : othersColor}
          isElementCentered={false}
          isLoading={isLoading}
        />
      </div>
    </SimpleNeosTooltip>
  ) : null;
};
