import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  type DealType,
  dealTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useContext } from 'react';
import { FormSelect } from 'react-bootstrap';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';

export function DealTypeComponent() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, dealType } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onDealTypeChange = (newDealType: DealType | undefined) => {
    dispatch(updateProduct(uuid, { dealType: newDealType }));
  };

  return (
    <ElsBlocField
      label="Deal type"
      renderInput={readOnly => (
        <FormSelect
          readOnly={readOnly}
          value={dealType ?? ''}
          onChange={event => onDealTypeChange((event.target.value as DealType) || undefined)}
          data-e2e="product-details-deal-type"
        >
          <option value=""></option>
          {Object.entries(dealTypes).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </FormSelect>
      )}
    />
  );
}
