import { createDeleteDeltaStockListedAllocationThunk } from '@/neos/business/rfq/strategy/leg/deltaStockListedAllocation/deleteDeltaStockListedAllocationThunk';
import { createDeleteListedAllocationThunk } from '@/neos/business/rfq/strategy/leg/listedAllocation/deleteListedAllocationThunk';
import { createDeleteOtcAllocationThunk } from '@/neos/business/rfq/strategy/leg/otcAllocation/deleteOtcAllocationThunk';
import { productThunks } from '@/neos/business/rfq/strategy/leg/product/thunks';
import { createUpdateProductNoTaxCollectionThunk } from '@/neos/business/rfq/strategy/leg/thunks/updateProductNoTaxCollectionThunk';
import { createChangeJustificationLabelThunk } from '../otcAllocation/createChangeJustificationLabelThunk';
import { createAddLegThunk } from './addLegThunk';
import { createApplyStrategyDefinitionSameValueToLegsThunk } from './applyStrategyDefinitionSameValueToLegs';
import { createChangeLegRatioThunk } from './changeLegRatio';
import { createChangeProductUnderlyingThunk } from './changeProductUnderlying';
import { createCloneLegRfqThunk } from './cloneLegRfq';
import { createCloneStrategyLegsThunk } from './cloneStrategyLegs';
import { createFetchCurvesListBasedOnCurrencyThunk } from './curvesListThunk';
import { createDefaultLegsAsPerStrategyDefinitionThunk } from './defaultLegStrategyDefinition';
import { createDefaultProductLotSizeMarketThunk } from './defaultProductLotSizeMarket';
import { createDeleteLegsThunk } from './deleteLegsThunk';
import { createDispatchIfBeforeOrderAcceptedThunk } from './dispatchIfBeforeOrderAccepted';
import { createLoadLegsThunk } from './loadLegs';
import { createOptionFlexChangedThunk } from './optionFlexChange';
import { createSecondaryTypesOnLegsThunk } from './secondaryTypesThunk';
import {
  createUpdateClientPositionAndResetDependenciesThunk,
  createUpdateLegThunk,
} from './updateLeg';
import { createUpdateLegBookingIdThunk } from './updateLegBookingIdThunk';
import { createUpdateLegMaturityThunk } from './updateLegMaturity';
import { createUpdateLegMaturityTenorThunk } from './updateLegMaturityTenor';
import {
  createUpdateProductAndResetDependenciesThunk,
  createUpdateProductThunk,
} from './updateProduct';
import { createUpdateProductClsTypeThunk } from './updateProductClsType';
import { createUpdateProductEffectiveDateOffsetThunk } from './updateProductEffectiveDateOffsetThunk';
import { createUpdateProductEffectiveDateThunk } from './updateProductEffectiveDateThunk';
import { createUpdateProductElsTypeThunk } from './updateProductElsType';
import { createUpdateProductLotSizeMarketThunk } from './updateProductLotSizeMarket';

export const legThunks = {
  createCloneStrategyLegsThunk,
  createUpdateLegThunk,
  createUpdateClientPositionAndResetDependenciesThunk,
  createUpdateProductThunk,
  createUpdateProductNoTaxCollectionThunk,
  createOptionFlexChangedThunk,
  createUpdateProductAndResetDependenciesThunk,
  createDefaultLegsAsPerStrategyDefinitionThunk,
  createApplyStrategyDefinitionSameValueToLegsThunk,
  createUpdateProductLotSizeMarketThunk,
  createUpdateLegMaturityThunk,
  createChangeLegRatioThunk,
  createDefaultProductLotSizeMarketThunk,
  createChangeProductUnderlyingThunk,
  createCloneLegRfqThunk,
  createDispatchIfBeforeOrderAcceptedThunk,
  createLoadLegsThunk,
  createDeleteLegsThunk,
  createAddLegThunk,
  createUpdateProductElsTypeThunk,
  createUpdateLegMaturityTenorThunk,
  createUpdateProductClsTypeThunk,
  createSecondaryTypesOnLegsThunk,
  createFetchCurvesListBasedOnCurrencyThunk,
  createUpdateProductEffectiveDateThunk,
  createUpdateProductEffectiveDateOffsetThunk,
  createUpdateLegBookingIdThunk,
  createDeleteOtcAllocationThunk,
  createDeleteListedAllocationThunk,
  createDeleteDeltaStockListedAllocationThunk,
  createChangeJustificationLabelThunk,
  ...productThunks,
};
