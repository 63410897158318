import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { LabelWithHelp } from '@/neos/components/share/labelWithHelp/LabelWithHelp';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { type ReactElement, useContext } from 'react';

type LabelWithHelpText = { type: 'helpText'; content: string; helpText: string };
type LabelWithTooltip = { type: 'tooltip'; content: string; tooltip: string };

type ElsFieldProps = {
  label: string | LabelWithHelpText | LabelWithTooltip;
  renderInput: (readOnly: boolean) => ReactElement;
  minWidth?: 120 | 160 | 180;
};

export function ElsBlocField({ renderInput, label, minWidth }: ElsFieldProps) {
  const { parentClassNames, labelClassNames, isReadonlyAtCurrentWorkflow } =
    useContext(ElsFeaturesContext);

  const minWidthClass = minWidth !== undefined ? `min-${minWidth}` : '';

  return (
    <div
      className={`${parentClassNames ?? ''} ${minWidthClass} els-field`}
      onMouseDown={e => e.stopPropagation()}
    >
      {typeof label === 'string' ? (
        <label className={labelClassNames ?? ''}>{label}</label>
      ) : label.type === 'helpText' ? (
        <LabelWithHelp
          helpText={label.helpText}
          labelText={label.content}
          labelClassNames={labelClassNames}
        />
      ) : (
        <SimpleNeosTooltip message={label.tooltip}>
          <label className={labelClassNames ?? ''}>{label.content}</label>
        </SimpleNeosTooltip>
      )}

      {renderInput(isReadonlyAtCurrentWorkflow)}
    </div>
  );
}
