import { services } from '@/bootstrap/services';
import type { Thunk } from '@/bootstrap/thunks';
import { remove } from 'lodash';
import type { NeosActionCreators } from '../neosActionCreators';
import type { NotificationSubscriptionRequest } from '../neosModel';
import { getSavedUserPreferences } from '../ui/userPreferences/userPreferencesUiServices';
import {
  defaultUserPreferencesState,
  type UserPreferencesState,
} from '../ui/userPreferences/userPreferencesUiState';

export function createSubscribeToNeosNotifications(): Thunk {
  return function subscribeToNeosNotifications(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: { neos },
      thunks: {
        neos: { createRequestNotificationPermissionThunk },
      },
    },
  ) {
    const state = getState();

    const dataGroupNames = selectors.getCurrentUser(state).diffusionGroups.map(dg => dg.toString());

    cleanSavedNotificationGroupsPreferencesFromUserPreferences(dataGroupNames);

    const subscribeRequests: NotificationSubscriptionRequest[] = [
      { notificationTopic: 'BLOTTER', dataGroupNames },
    ];

    const isPreconfBlotterEnabled = selectors.isFeatureToggleEnabled(
      state,
      'neos.preconfirmation.blotter.enabled',
    );

    if (isPreconfBlotterEnabled) {
      subscribeRequests.push({
        notificationTopic: 'PRECONF_BLOTTER',
        dataGroupNames,
      });
    }

    subscribeRequests.push({
      notificationTopic: 'TRANSACTION_GROUP',
      dataGroupNames: selectors
        .getFilteredNotificationGroupsByPreferences(state, selectors)
        .map(group => group.toString()),
    });

    dispatch(
      getUserPreferencesFromLocalStorage(neos),
      neos.createRequestSubscriptionAction(subscribeRequests),
      createRequestNotificationPermissionThunk(),
    );
  };
}

function cleanSavedNotificationGroupsPreferencesFromUserPreferences(diffusionGroups: string[]) {
  const userPreferences = getSavedUserPreferences();

  if (!userPreferences?.notificationGroups?.length) {
    return;
  }

  const obsoleteGroups = userPreferences.notificationGroups.filter(
    notificationGroup => !diffusionGroups.includes(notificationGroup.id.toString()),
  );

  if (obsoleteGroups.length === 0) {
    return;
  }

  obsoleteGroups.forEach(obsoleteGroup => {
    remove(userPreferences.notificationGroups, item => item.id === obsoleteGroup.id);
  });

  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));
}

function getUserPreferencesFromLocalStorage(actionsCreators: NeosActionCreators) {
  const { getLocalStorageItem } = services.localStorage;

  const storedUserPreferences =
    getLocalStorageItem<UserPreferencesState>('userPreferences') ?? defaultUserPreferencesState;

  return actionsCreators.createUserPreferencesLoadedAction({
    ...storedUserPreferences,
    blotterDefaultTimerange:
      storedUserPreferences.blotterDefaultTimerange ??
      defaultUserPreferencesState.blotterDefaultTimerange,
    deltaToggle: storedUserPreferences.deltaToggle ?? defaultUserPreferencesState.deltaToggle,
    displayTimeZone:
      storedUserPreferences.displayTimeZone ?? defaultUserPreferencesState.displayTimeZone,
    fairGreeksToggle:
      storedUserPreferences.fairGreeksToggle ?? defaultUserPreferencesState.fairGreeksToggle,
    fairPricesToggle:
      storedUserPreferences.fairPricesToggle ?? defaultUserPreferencesState.fairPricesToggle,
    isIndicativeTradableDisplayed:
      storedUserPreferences.isIndicativeTradableDisplayed ??
      defaultUserPreferencesState.isIndicativeTradableDisplayed,
    notificationGroups:
      storedUserPreferences.notificationGroups ?? defaultUserPreferencesState.notificationGroups,
    quickButtonStrategyToggle:
      storedUserPreferences.quickButtonStrategyToggle ??
      defaultUserPreferencesState.quickButtonStrategyToggle,
    quickButtonUnderlyingToggle:
      storedUserPreferences.quickButtonUnderlyingToggle ??
      defaultUserPreferencesState.quickButtonUnderlyingToggle,
    salesPricesToggle:
      storedUserPreferences.salesPricesToggle ?? defaultUserPreferencesState.salesPricesToggle,
    showIbChatSection:
      storedUserPreferences.showIbChatSection ?? defaultUserPreferencesState.showIbChatSection,
    showQuickButtonsSection:
      storedUserPreferences.showQuickButtonsSection ??
      defaultUserPreferencesState.showQuickButtonsSection,
    volBidAskToggle:
      storedUserPreferences.volBidAskToggle ?? defaultUserPreferencesState.volBidAskToggle,
    showUserPreferencesModal: false,
    underlyingsShortcutsList:
      storedUserPreferences.underlyingsShortcutsList ??
      defaultUserPreferencesState.underlyingsShortcutsList,
    strategiesShortcutsList:
      storedUserPreferences.strategiesShortcutsList ??
      defaultUserPreferencesState.strategiesShortcutsList,
    searchedCounterparts:
      storedUserPreferences.searchedCounterparts ??
      defaultUserPreferencesState.searchedCounterparts,
    searchedBrokers:
      storedUserPreferences.searchedBrokers ?? defaultUserPreferencesState.searchedBrokers,
    isAlertSoundPlayed:
      storedUserPreferences.isAlertSoundPlayed ?? defaultUserPreferencesState.isAlertSoundPlayed,
    excelLanguage: storedUserPreferences.excelLanguage ?? defaultUserPreferencesState.excelLanguage,
  });
}
