import { getAllEnumValues } from '@/util/enum/enumValues';
import { MarginRules } from './marginRule/marginRuleModel';
import type { ReferenceData } from './referenceDataModel';

export type ReferenceDataState = Readonly<ReferenceData>;

export const defaultReferenceDataState: ReferenceDataState = {
  areReferenceDataReceived: false,
  salesValos: {},
  traders: {},
  currencies: [],
  salesGroups: [],
  traderGroups: [],
  strategyDefinitions: {},
  predealChecksDerogations: undefined,
  marginRules: getAllEnumValues(MarginRules),
  strategyConfiguration: { editableStrategies: [], viewableStrategies: [], readOnlyStrategies: [] },
  salesLocations: [],
  tenors: [],
  counterparts: [],
  usMarkets: [],
  markets: [],
  currencyCurvesList: {},
  primeBrokers: {},
  brokers: [],
  relatedExchangeFields: [],
  marketsEliot: [],
};
