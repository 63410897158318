import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { RelatedExchange } from '@/neos/business/referenceData/relatedExchange/relatedExchangeModel';
import { getBasketUnderlyingEpic } from '@/neos/business/referenceData/underlying/basketUnderlyingsRequestedEpic';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { combineEpics, type Epic, ofType } from 'redux-observable';
import { combineLatest, type Observable, of } from 'rxjs';
import { catchError, map, mergeMap, retry } from 'rxjs/operators';
import { wrapInChainableLoadingObservable } from '../epics/wrapInChainableLoadingObservable';
import type { OnyxError } from '../mappers/error';
import type {
  Counterpart,
  Currency,
  PredealCheckAvailableDerogation,
  PredealChecksDerogation,
  SalesGroup,
  StrategyConfiguration,
  TraderGroup,
  User,
} from '../neosModel';
import type { OnyxStrategyDefinition } from '../neosOnyxModel';
import type { PredealCheckType } from '../predealCheck/predealCheckModel';
import type { OnyxMarket, OnyxMarketEliot } from './markets/marketsModel';
import { makeReferenceDataApi } from './referenceDataApi';
import type { SalesLocations } from './salesLocation/salesLocationsModel';
import type { Tenor } from './tenors/tenorModel';
import { getUnderlyingEpic } from './underlying/underlyingEpics';

export interface ReferenceDataApi {
  getSalesValos: () => Observable<User[]>;
  getStrategyConfiguration: () => Observable<StrategyConfiguration>;
  getTraders: () => Observable<User[]>;
  getCurrencies: () => Observable<Currency[]>;
  getTraderGroups: () => Observable<TraderGroup[]>;
  getSalesGroups: () => Observable<SalesGroup[]>;
  getStrategiesDefinitions: () => Observable<OnyxStrategyDefinition[]>;
  getPredealChecksDerogations: () => Observable<PredealChecksDerogation>;
  getSalesLocations: () => Observable<SalesLocations[]>;
  getTenors: () => Observable<Tenor[]>;
  getMarkets: () => Observable<OnyxMarket[]>;
  getCounterparts: (sesameId: string) => Observable<Counterpart[]>;
  getRelatedExchangeFields: () => Observable<RelatedExchange[]>;
  getMarketsEliot: () => Observable<OnyxMarketEliot[]>;
}

export function getReferenceEpic(http: SgmeHttp): ThunkEpic {
  return combineEpics(
    getReferenceDataEpic(http),
    getUnderlyingEpic(http),
    getBasketUnderlyingEpic(http),
  );
}

export function getReferenceDataEpic(http: SgmeHttp) {
  const referenceDataApi: ReferenceDataApi = makeReferenceDataApi(http);
  return makeReferenceDataEpic(referenceDataApi, actionCreators, thunks);
}

export function makeReferenceDataEpic(
  api: ReferenceDataApi,
  { common: { createAppCrashedAction } }: ActionCreators,
  { createErrorToasterThunk, neos: { createReferenceDataReceivedThunk } }: Thunks,
): Epic {
  return action$ =>
    action$.pipe(
      ofType('REFERENCE_DATA_REQUESTED_ACTION'),
      mergeMap(({ chainOptions, tabId, sesameId }) => {
        const referenceData$ = buildReferenceDataCombineLatest(api, sesameId);

        return wrapInChainableLoadingObservable({
          tabIds: [tabId],
          apiObservable: referenceData$,
          observableOptions: {
            success: {
              on: ([
                currencies,
                predealChecksDerogations,
                salesGroups,
                salesValos,
                strategyDefinitions,
                strategyConfiguration,
                traderGroups,
                traders,
                salesLocations,
                tenors,
                counterparts,
                markets,
                relatedExchangeFields,
                marketsEliot,
              ]) => {
                return [
                  createReferenceDataReceivedThunk(
                    currencies,
                    predealChecksDerogations,
                    salesGroups,
                    salesValos,
                    strategyDefinitions,
                    strategyConfiguration,
                    traderGroups,
                    traders,
                    salesLocations,
                    tenors,
                    counterparts,
                    markets,
                    relatedExchangeFields,
                    marketsEliot,
                  ),
                ];
              },
            },
            error: {
              on: (error: OnyxError) => [
                createAppCrashedAction('mandatory-reference-data-requested', error),
                createErrorToasterThunk(
                  {
                    message: 'Error when retrieving Reference Data',
                  },
                  error,
                ),
              ],
            },
          },
          chainOptions,
        });
      }),
    );
}

function buildReferenceDataCombineLatest(
  api: ReferenceDataApi,
  sesameId: string,
): Observable<
  [
    Currency[],
    Record<PredealCheckType, PredealCheckAvailableDerogation[]>,
    SalesGroup[],
    User[],
    OnyxStrategyDefinition[],
    StrategyConfiguration,
    TraderGroup[],
    User[],
    SalesLocations[],
    Tenor[],
    Counterpart[],
    OnyxMarket[],
    RelatedExchange[],
    OnyxMarketEliot[],
  ]
> {
  const currencies$ = api.getCurrencies();
  const predealCheckDerogations$ = api.getPredealChecksDerogations();
  const salesGroups$ = api.getSalesGroups();
  const salesValo$ = api.getSalesValos().pipe(catchError(() => of([])));

  const strategyDefinitions$ = api.getStrategiesDefinitions();
  const strategyConfiguration$ = api.getStrategyConfiguration();
  const traderGroups$ = api.getTraderGroups();
  const traders$ = api.getTraders();
  const salesLocations$ = api.getSalesLocations();
  const tenor$ = api.getTenors();
  const counterpart$ = api.getCounterparts(sesameId);
  const market$ = api.getMarkets().pipe(
    retry(1),
    catchError(() => of([])),
  );
  const relatedExchangeFields$ = api.getRelatedExchangeFields();
  const marketsEliot$ = api.getMarketsEliot();

  // Due to rxjs limitation we have made 3 combineLatest because they throw after 6 elements
  const referenceData1$ = combineLatest(
    currencies$,
    predealCheckDerogations$,
    salesGroups$,
    salesValo$,
    strategyDefinitions$,
    market$,
  );

  const referenceData2$ = combineLatest([
    strategyConfiguration$,
    traderGroups$,
    traders$,
    salesLocations$,
    tenor$,
    counterpart$,
  ]);
  const referenceData3$ = combineLatest(relatedExchangeFields$, marketsEliot$);

  const combinedReferenceData$ = combineLatest(referenceData1$, referenceData2$, referenceData3$);
  // here we flat 3 combined reference data result
  return combinedReferenceData$.pipe(
    map(
      ([[curr, pdd, sg, sv, sd, mkt], [sc, tg, t, sl, te, cp], [relEx, mktEliot]]): [
        Currency[],
        Record<PredealCheckType, PredealCheckAvailableDerogation[]>,
        SalesGroup[],
        User[],
        OnyxStrategyDefinition[],
        StrategyConfiguration,
        TraderGroup[],
        User[],
        SalesLocations[],
        Tenor[],
        Counterpart[],
        OnyxMarket[],
        RelatedExchange[],
        OnyxMarketEliot[],
      ] => [curr, pdd, sg, sv, sd, sc, tg, t, sl, te, cp, mkt, relEx, mktEliot],
    ),
  );
}
