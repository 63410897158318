import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { useDispatch } from 'react-redux';
import { thunks } from '@/bootstrap/thunks';
import type { Els } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export interface ElsLendAndBorrowTppIdProps {
  rfqId: string;
  product: Els;
}

export function TppBookingId({ product, rfqId }: ElsLendAndBorrowTppIdProps) {
  const dispatch = useDispatch();
  const tppBookingInfo = useAppSelector(state =>
    selectors
      .selectBookingStepsByFilter(state, {
        referenceUuid: product.legId,
        rfqId,
        stepType: 'XONE_HEDGE',
        type: 'HEDGE',
      })
      .at(0),
  );

  function onTppBookingIdChanged(id: string | undefined) {
    dispatch(
      thunks.neos.createUpdateElsStockLoanHedgeThunk(
        product.uuid,
        'bookingApplication',
        id === undefined ? undefined : 'XONE',
      ),
    );
    dispatch(thunks.neos.createUpdateElsStockLoanHedgeThunk(product.uuid, 'bookingId', id));
  }

  return (
    <>
      <label className="p-1">Tpp Id</label>
      <NeosBookingId
        defaultBookingId={tppBookingInfo?.bookingId}
        bookingId={product.stockLoanHedge?.bookingId ?? tppBookingInfo?.bookingId}
        dataE2e={`els-product.stockLoanHedge?-pttId-${tppBookingInfo?.bookingId}`}
        bookingStatus={
          product.stockLoanHedge?.bookingId === undefined ? tppBookingInfo?.status : undefined
        }
        bookingTimeStamp={
          product.stockLoanHedge?.bookingId === undefined
            ? tppBookingInfo?.lastUpdateTime
            : undefined
        }
        message={
          product.stockLoanHedge?.bookingId === undefined ? tppBookingInfo?.message : undefined
        }
        onBookingIdChanged={onTppBookingIdChanged}
      />
    </>
  );
}
