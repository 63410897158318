import { NumericInput } from '@/neos/components/share/numericInput';
import type { ForexModel } from './getForexModel';

import styles from './Forex.module.scss';

export interface ForexTo {
  fromCurrency: string;
  fromCurrencyValue: number | undefined;
  isEditable: boolean;
}

export interface ForexValue {
  toCurrency: string;
  forexValues: Array<ForexTo | undefined>;
}

export interface ForexOwnProps {
  rfqId: string;
}

export interface ForexMapStateToProps {
  model: ForexModel;
}

export interface ForexDispatchProps {
  updateForexRate(value: number | undefined, fromCurrency: string, toCurrency: string): void;
}

export type ForexProps = ForexOwnProps & ForexMapStateToProps & ForexDispatchProps;

export const ForexComponent = (props: ForexProps) => (
  <section
    className={`${styles['forex']} ${
      props.model.isEls && props.model.isElsFeatureToggleEnabled ? '' : 'ps-4'
    } `}
  >
    <div>
      <label>Forex</label>
      <div className="readonly-values">
        {props.model.fromCurrencies.map(fromCurrency => (
          <span
            data-e2e={`neos-rfq-reference-forex-header-fromCurrency-${fromCurrency}`}
            key={fromCurrency}
          >
            {fromCurrency}
          </span>
        ))}
      </div>
    </div>

    {props.model.forexValues.map(({ toCurrency, forexValues }, toIndex) => (
      <div key={toCurrency}>
        <label
          className={`${styles['forex-column-header']} `}
          data-e2e={`neos-rfq-reference-forex-header-toCurrency-${toCurrency}`}
        >
          {toCurrency}
        </label>
        {forexValues.map((forexValue, fromIndex) => {
          return (
            <NumericInput
              key={`${toCurrency}[${fromIndex}]`}
              value={forexValue?.fromCurrencyValue}
              data-e2e={`neos-rfq-reference-forex-${fromIndex + 1}-${toIndex + 1}`}
              onBlur={value =>
                props.updateForexRate(value, forexValue?.fromCurrency || '', toCurrency)
              }
              readOnly={
                (forexValue ? !forexValue.isEditable : true) ||
                props.model.isReadonlyAtCurrentWorkflow
              }
              maximumFractionDigits={5}
            />
          );
        })}
      </div>
    ))}
  </section>
);
