import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import {
  type Els,
  initialStockLoanHedge,
  type StockLoanComponent,
  type StockLoanHedge,
} from '@/neos/business/rfq/models';
import { UnderlyingInput } from '@/neos/components/rfq/strategies/strategy/common';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useDispatch } from 'react-redux';

export type ElsLendAndBorrowGridRowProps = {
  index: number;
  stockLoanComponent: StockLoanComponent;
  rfqId: string;
  strategyId: string;
  product: Els;
};

export function ElsLendAndBorrowGridRow(gridRowProps: ElsLendAndBorrowGridRowProps) {
  const { product, index, stockLoanComponent, rfqId, strategyId } = gridRowProps;

  const underlyingTypes = useAppSelector(state =>
    selectors.getUnderlyingTypes(state, strategyId, selectors),
  );

  const dispatch = useDispatch();
  const underlyingId = stockLoanComponent.underlyingId;
  const underlyingDetails = useAppSelector(state =>
    selectors.getUnderlyingInfo(state, underlyingId),
  );

  function onDelete() {
    const oldStockLoanHedge: StockLoanHedge = product.stockLoanHedge ?? initialStockLoanHedge;
    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        stockLoanHedge: {
          ...oldStockLoanHedge,
          stockLoanComponents: oldStockLoanHedge.stockLoanComponents.toSpliced(index, 1),
        },
      }),
    );
  }

  return (
    <>
      <button className="btn btn-icon btn-flat-primary" onClick={onDelete}>
        <i className="icon icon-md">delete_forever</i>
      </button>

      <UnderlyingInput
        autoFocus={underlyingId === undefined && underlyingDetails?.bloombergCode === undefined}
        componentId={`neos-els-lendAndBorrow-underlying-${product.legId}-${gridRowProps.stockLoanComponent.uuid}`}
        underlyingId={underlyingId}
        bloombergCode={underlyingDetails?.bloombergCode}
        underlyingTypes={underlyingTypes}
        positionFixed
        data-e2e="neos-els-LendAndBorrow-underlying"
        onChange={event => {
          const newUnderlyingId = event?.value;
          dispatch(
            thunks.neos.createUpdateElsStockLoanComponentThunk(
              product.uuid,
              index,
              'underlyingId',
              newUnderlyingId,
            ),
          );
          dispatch(
            thunks.neos.createNeosLoadUnderlyingInfoAndChangeThunk(rfqId, newUnderlyingId, []),
          );
        }}
      />

      <NumericInput
        value={stockLoanComponent.quantity}
        onBlur={value => {
          dispatch(
            thunks.neos.createUpdateElsStockLoanComponentThunk(
              product.uuid,
              index,
              'quantity',
              value,
            ),
          );
        }}
        data-e2e="els-stockLoanComponent-quantity"
      />

      <NumericInput
        value={stockLoanComponent.fees?.value}
        unit={stockLoanComponent.fees?.unit}
        onBlur={value => {
          dispatch(
            thunks.neos.createUpdateElsStockLoanComponentThunk(product.uuid, index, 'fees', {
              ...stockLoanComponent.fees,
              value,
            }),
          );
        }}
      />

      <NumericInput
        value={stockLoanComponent.dividendRequirement?.value}
        unit={stockLoanComponent.dividendRequirement?.unit}
        onBlur={value => {
          dispatch(
            thunks.neos.createUpdateElsStockLoanComponentThunk(
              product.uuid,
              index,
              'dividendRequirement',
              {
                type: 'REF_PERCENT',
                unit: '%',
                value,
              },
            ),
          );
        }}
        data-e2e="els-stockLoanComponent-dividendRequirement"
      />

      <NumericInput
        value={stockLoanComponent.allIn?.value}
        unit={stockLoanComponent.allIn?.unit}
        onBlur={value => {
          dispatch(
            thunks.neos.createUpdateElsStockLoanComponentThunk(product.uuid, index, 'allIn', {
              type: 'REF_PERCENT',
              unit: '%',
              value,
            }),
          );
        }}
        data-e2e="els-stockLoanComponent-allIn"
      />

      <NeosBlurInput
        value={stockLoanComponent.lendPortfolio}
        onBlur={event => {
          dispatch(
            thunks.neos.createUpdateElsStockLoanComponentThunk(
              product.uuid,
              index,
              'lendPortfolio',
              event.target.value,
            ),
          );
        }}
        data-e2e="els-stockLoanComponent-lendPortfolio"
      />

      <NeosBlurInput
        value={stockLoanComponent.borrowPortfolio}
        onBlur={event => {
          dispatch(
            thunks.neos.createUpdateElsStockLoanComponentThunk(
              product.uuid,
              index,
              'borrowPortfolio',
              event.target.value,
            ),
          );
        }}
        data-e2e="els-stockLoanComponent-borrowPortfolio"
      />
    </>
  );
}
