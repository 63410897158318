import { ElsClsObsoleteScheduleWarning } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/ElsClsObsoleteScheduleWarning';
import { ELSScheduleExport } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/elsSchedule/ELSScheduleExport';
import { ELSScheduleImport } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/elsSchedule/ELSScheduleImport';
import { GenerateButton } from '../GenerateButton';

type ScheduleButtonsProps = {
  rfqId: string;
  strategyId: string;
};

export function ElsScheduleButtons({ rfqId, strategyId }: ScheduleButtonsProps) {
  return (
    <div className="d-flex justify-content-end gap-2">
      <ElsClsObsoleteScheduleWarning rfqId={rfqId} strategyId={strategyId} />
      <GenerateButton rfqId={rfqId} strategyId={strategyId} />
      <ELSScheduleImport strategyId={strategyId} />
      <ELSScheduleExport strategyId={strategyId} />
    </div>
  );
}
