import type { FC } from 'react';
import {
  type FieldInfo,
  GenericLegsQuotes,
} from '../../../prices/quotes/genericQuotes/GenericLegsQuotes';
import type { TraderAskModel } from './getTraderAskModel';

export interface TraderAskOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface TraderAskStateProps {
  model: TraderAskModel;
}

export interface TraderAskDispatchProps {
  onLegTraderAskChanged: (quoteId: string, legId: string, value?: number) => void;
}

export type TraderAskProps = TraderAskOwnProps & TraderAskStateProps & TraderAskDispatchProps;

export const TraderAskComponent: FC<TraderAskProps> = ({
  rfqId,
  strategyId,
  model: { legs, isEnabled },
  onLegTraderAskChanged,
}) => {
  const fieldInfo: FieldInfo = {
    color: 'ASK',
    fieldName: 'traderAsk',
    isTransparentKey: 'askIsTransparent',
    legErrorField: 'legTraderPriceAsk',
  };
  return (
    <section>
      <label>Trd Ask</label>
      <GenericLegsQuotes
        rfqId={rfqId}
        strategyId={strategyId}
        fieldInfo={fieldInfo}
        legsQuotes={legs}
        areTraderPricesEnabled={isEnabled}
        isUnitDisplayed={true}
        onLegChanged={onLegTraderAskChanged}
      />
    </section>
  );
};
