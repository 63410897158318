import { PredealCheckBullet } from '../predealCheckBullet';
import type { AggregatedPredealCheckModel } from './getAggregatedPredealCheckModel';

export interface AggregatedPredealCheckOwnProps {
  rfqId: string;
}

export type AggregatedPredealCheckStateProps = AggregatedPredealCheckModel;

export interface AggregatedPredealCheckDispatchProps {
  showPredealChecksModal: () => void;
  refreshPredealChecks: () => void;
}

export type AggregatedPredealCheckProps = AggregatedPredealCheckOwnProps &
  AggregatedPredealCheckStateProps &
  AggregatedPredealCheckDispatchProps;

export const AggregatedPredealCheckComponent = ({
  color,
  isRefreshEnabled,
  showPredealChecksModal,
  refreshPredealChecks,
}: AggregatedPredealCheckProps) => {
  return (
    <section className="d-flex">
      <button className="btn btn-primary text-nowrap" onClick={showPredealChecksModal}>
        <span className="me-2">PDC</span>
        <PredealCheckBullet
          color={color}
          isElementCentered={false}
          isSelectable={false}
          isLoading={false}
          isSmaller={true}
        />
      </button>
      <button
        className="btn btn-primary px-2"
        data-e2e="neos-rfq-refresh-pdc-button"
        onClick={refreshPredealChecks}
        disabled={!isRefreshEnabled}
      >
        <i className="icon icon-sm">cached</i>
      </button>
    </section>
  );
};
