import type { Thunk } from '@/bootstrap/thunks';
import { isElsBasketProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { ExecFees } from '@/neos/business/rfq/strategy/leg/product/productOnyxModel';
import { createZodObject } from '@/util/zod/zod-util';
import { z } from 'zod';
import { fromError } from 'zod-validation-error';
import { camelCase } from 'lodash';
import { parseValidExcelNumber } from '@/util/number/numberUtils.ts';
import type { ExcelLanguage } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';
import { elsBasketImportApproximateImportedKeys } from '@/neos/business/thunks/ElsBasketImportApproximateImportedKeys.ts';

export type ValidImportedBasketData = z.output<ReturnType<typeof getImportedDataSchema>>;

export function createImportBasketCompositionThunk(
  rfqId: string,
  productId: string,
  rawImportedData: unknown[],
): Thunk {
  return function importBasketCompositionThunk(
    dispatch,
    getState,
    { actionCreators, thunks, selectors },
  ) {
    const state = getState();
    const excelLanguage = selectors.selectExcelLanguage(state.ui.userPreferences);
    const product = selectors.getProduct(state, productId);

    if (!isElsBasketProduct(product)) {
      return;
    }
    const rawParsingResult = RawImportedDataSchema.safeParse(rawImportedData);

    if (!rawParsingResult.success) {
      const zodError = fromError(rawParsingResult.error, {
        prefix: 'Error trying to parse imported basket composition',
      }).toString();

      dispatch(actionCreators.common.createLogAction(zodError, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const importedDataWithSanitizedKeys: Record<string, string>[] = rawParsingResult.data.map(
      line =>
        Object.fromEntries(
          Object.entries(line).map(([key, value]) => {
            return [camelCase(key), value];
          }),
        ),
    );

    const approximatedImportedData = elsBasketImportApproximateImportedKeys(
      importedDataWithSanitizedKeys,
    );

    const parsingResult = getImportedDataSchema(excelLanguage).safeParse(approximatedImportedData);

    if (!parsingResult.success) {
      const zodError = fromError(parsingResult.error, {
        prefix: 'Error trying to parse imported basket composition',
      }).toString();

      dispatch(actionCreators.common.createLogAction(zodError, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const validatedData = parsingResult.data as ValidImportedBasketData;

    const importedBloombergCodes = validatedData.map(line => line.bloombergCode);

    const areSomeDuplicatedBloombergCodes = importedBloombergCodes.some((item, index) => {
      return importedBloombergCodes.indexOf(item) !== index;
    });

    if (areSomeDuplicatedBloombergCodes) {
      const message =
        'Error trying to import basket composition: Some Bloomberg codes are duplicated.';
      dispatch(actionCreators.common.createLogAction(message, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message,
          },
          undefined,
        ),
      );
      return;
    }

    const definedData = validatedData.filter(isBloombergCodeDefined);

    if (definedData.length > 0) {
      dispatch(
        actionCreators.neos.createBasketUnderlyingIdsRequestedAction(
          rfqId,
          product.uuid,
          definedData,
        ),
      );
    }
  };
}

const ExecFeesSchema = createZodObject<ExecFees>({
  value: z.number().optional(),
  unit: z.union([z.literal('bp'), z.literal('%'), z.literal('Cts')]).optional(),
  type: z.union([z.literal('BIPS'), z.literal('REF_PERCENT'), z.literal('CENTS')]).optional(),
});

const RawImportedDataSchema = z.array(z.record(z.string(), z.string()));

const getImportedDataSchema = (excelLanguage: ExcelLanguage) =>
  z.array(
    z.object({
      bloombergCode: z.string().min(1),
      quantity: z
        .string()
        .min(1)
        .transform((value: string | undefined, ctx: z.RefinementCtx) =>
          transformNumberIfDefined(value, ctx, excelLanguage),
        ),
      spot: z
        .string()
        .optional()
        .transform((value: string | undefined, ctx: z.RefinementCtx) =>
          transformNumberIfDefined(value, ctx, excelLanguage),
        ),
      spotUnit: z.string().optional(),
      spotNet: z
        .string()
        .optional()
        .transform((value: string | undefined, ctx: z.RefinementCtx) =>
          transformNumberIfDefined(value, ctx, excelLanguage),
        ),
      spotNetUnit: z.string().optional(),
      execFeesIn: z
        .string()
        .optional()
        .transform((value: string | undefined, ctx: z.RefinementCtx) =>
          transformNumberIfDefined(value, ctx, excelLanguage),
        ),
      execFeesInUnit: ExecFeesSchema.shape.unit.optional(),
      execFeesOut: z
        .string()
        .optional()
        .transform((value: string | undefined, ctx: z.RefinementCtx) =>
          transformNumberIfDefined(value, ctx, excelLanguage),
        ),
      execFeesOutUnit: ExecFeesSchema.shape.unit.optional(),
    }),
  );

function isBloombergCodeDefined(
  line: ValidImportedBasketData[number],
): line is ValidImportedBasketData[number] {
  return line.bloombergCode !== undefined;
}

function transformNumberIfDefined(
  value: string | undefined,
  ctx: z.RefinementCtx,
  excelLanguage: ExcelLanguage,
): number | undefined {
  if (value === undefined) {
    return value;
  }

  const parsedValue = parseValidExcelNumber(value, excelLanguage);
  if (parsedValue === null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `${value} format cannot be parsed`,
    });

    // This is a special symbol used to return early from the transform function.
    // It has type `never` so it does not affect the inferred return type.
    return z.NEVER;
  }
  return parsedValue;
}
