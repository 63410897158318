import type { Thunk } from '@/bootstrap/thunks';

export function createStrategyUiClonedThunk(
  strategyToCloneId: string,
  clonedStrategyId: string,
): Thunk {
  return function strategyUiClonedThunk(
    dispatch,
    getState,
    {
      selectors: { isShowLegPrices, getDisplayNegotiatedSize, getStrategyUi },
      actionCreators: {
        neos: { strategyUiCrudActions },
      },
    },
  ) {
    const state = getState().ui;

    const action = strategyUiCrudActions.patchOrInsert(clonedStrategyId, {
      showLegPrices: isShowLegPrices(state, strategyToCloneId),
      displayedSizeType: getDisplayNegotiatedSize(state, strategyToCloneId),
      displayCompositionLegsModal: false,
      displayCompoImportHasMultipleIndicesWarning: false,
      displayCompoImportHasMultipleMaturitiesWarning: false,
      displayMissingCompositionDataWarning: !!getStrategyUi(state, strategyToCloneId)
        ?.displayMissingCompositionDataWarning,
    });

    dispatch(action);
  };
}
