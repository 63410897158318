import type { FC } from 'react';
import {
  type FieldInfo,
  GenericLegsQuotes,
} from '../../../prices/quotes/genericQuotes/GenericLegsQuotes';
import type { SalesAskModel } from './getSalesAskModel';

export interface SalesAskOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface SalesAskStateProps {
  model: SalesAskModel;
}

export interface SalesAskDispatchProps {
  onLegSalesAskChanged: (quoteId: string, legId: string, value?: number) => void;
}

export type SalesAskProps = SalesAskOwnProps & SalesAskStateProps & SalesAskDispatchProps;

export const SalesAskComponent: FC<SalesAskProps> = ({
  rfqId,
  strategyId,
  model: { legs, isEnabled },
  onLegSalesAskChanged,
}) => {
  const fieldInfo: FieldInfo = {
    color: 'ASK',
    fieldName: 'salesAsk',
    isTransparentKey: 'askIsTransparent',
    legErrorField: 'legSalesPriceAsk',
  };
  return (
    <section>
      <label>Sls Ask</label>
      <GenericLegsQuotes
        rfqId={rfqId}
        strategyId={strategyId}
        fieldInfo={fieldInfo}
        legsQuotes={legs}
        areTraderPricesEnabled={isEnabled}
        isUnitDisplayed={true}
        onLegChanged={onLegSalesAskChanged}
      />
    </section>
  );
};
