import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { BulletColor } from '../predealCheckBullet';
import { convertToBulletColor } from '../util/convertToBulletColor';
import { getAggregationColor } from '../util/getAggregationColor';

export interface AggregatedPredealCheckModel {
  color: BulletColor;
  isRefreshEnabled: boolean;
}
export function getAggregatedPredealCheckModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): AggregatedPredealCheckModel {
  const predealChecks = selectors.getNeosPredealChecks(state, rfqId, selectors);
  const isRefreshEnabled =
    selectors.isFeatureToggleEnabled(state, 'onyx.neos.pdc.refresh') &&
    !selectors.isReadOnlyRfq(state, rfqId);

  if (!predealChecks.length) {
    return { color: 'unknown', isRefreshEnabled };
  }

  return {
    color: getAggregationColor(predealChecks.map(({ uiColor }) => convertToBulletColor(uiColor))),
    isRefreshEnabled,
  };
}
