import type { ChangeEvent } from 'react';
import * as XLSX from 'xlsx';
import { getDataFromClipboard } from '@/util/clipboard/clipboard.ts';

export function parseExcelFile<T>(
  fileData: string | ArrayBuffer | null,
  header: XLSX.Sheet2JSONOpts['header'] = undefined,
): T[] {
  const workbook = XLSX.read(fileData, { type: 'binary', cellText: false, cellDates: true });

  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  return XLSX.utils.sheet_to_json<T>(sheet, {
    raw: false,
    header,
    dateNF: 'yyyy-mm-dd',
  });
}

export async function importFromExcelClipboardData<T>(
  handleClipboardData: (data: T[]) => void,
  handleError: (error: string) => void,
) {
  const result = await getDataFromClipboard();

  if (result.status === 'FAILED') {
    handleError(result.message);
    return;
  }

  handleClipboardData(tsvToJson(result.data) as T[]);
}

export function exportDataToExcel<T extends Object>(data: T[], title: string): void {
  const headers = [Object.keys(data[0])];
  const ws = XLSX.utils.aoa_to_sheet(headers);
  XLSX.utils.sheet_add_json(ws, data, {
    header: Object.keys(data[0]),
    skipHeader: true,
    origin: -1,
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${title}.xlsx`, { bookType: 'xlsx', type: 'binary', cellDates: true });
}

export function importExcelFileContent<T>(
  event: ChangeEvent<HTMLInputElement>,
  onImportDataCallback: (data: T[]) => void,
  withHeader = false,
): void {
  const getContent = () => {
    event.persist();
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      const parsedData = parseExcelFile<T>(fileData, withHeader ? 1 : undefined);
      return onImportDataCallback(parsedData);
    };

    const selectedFile = event.target.files?.item(0);
    if (selectedFile) {
      reader.readAsBinaryString(selectedFile);
    }
    event.target.value = '';
  };

  getContent();
}

/*
  Transform tab separated values (tsv) with headers into json object
 */
function tsvToJson(tsvText: string): unknown[] {
  // Split all the text into seperate lines on new lines and carriage return feeds
  const allTextLines = tsvText.split(/\r\n|\n/);
  // Split per line on tabs and commas
  const headers = allTextLines[0].split(/\t/);
  const lines = [];

  for (let i = 1; i < allTextLines.length; i++) {
    const data = allTextLines[i].split(/\t/);

    if (data.length == headers.length) {
      const row: Record<string, string> = {};
      for (let j = 0; j < headers.length; j++) {
        row[headers[j]] = data[j];
      }
      lines.push(row);
    }
  }

  return lines;
}
