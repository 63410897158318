import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useContext } from 'react';
import { getElsRefSpotNetModel } from './getElsRefSpotNetModel';

export function RefSpotNet() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const refSpotNetModel = useAppSelector(state =>
    getElsRefSpotNetModel(state, rfqId, strategyId, selectors),
  );

  if (refSpotNetModel === undefined) {
    return null;
  }

  return (
    <ElsBlocField
      label="Ref Spot Net"
      renderInput={() => (
        <NumericInput
          style={{ width: '100px' }}
          unit={refSpotNetModel.unit}
          value={refSpotNetModel.value}
          readOnly
          data-e2e="els-section-equity-ref-spot-net"
        />
      )}
    />
  );
}
