import { NumericInput } from '@/neos/components/share/numericInput';
import type { FC } from 'react';
import type { MidModel } from './getMidModel';

export interface MidOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface MidStateProps {
  model: MidModel;
}

export type MidProps = MidOwnProps & MidStateProps;

export const MidComponent: FC<MidProps> = ({ model: { mids } }) => {
  return (
    <section>
      <label>Mid</label>
      {mids.map(({ id, mid }) => (
        <NumericInput key={id} value={mid} readOnly />
      ))}
    </section>
  );
};
