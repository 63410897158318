import type { Thunk } from '@/bootstrap/thunks';
import {
  isElsBasketProduct,
  type Underlying,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { ValidImportedBasketData } from '@/neos/business/thunks/importBasketCompositionThunk';

export function createHandleBasketUnderlyingsReceivedThunk(
  rfqId: string,
  productId: string,
  underlyings: Underlying[],
  validatedData: ValidImportedBasketData,
): Thunk {
  return function handleBasketUnderlyingsReceivedThunk(
    dispatch,
    getState,
    { thunks, selectors, actionCreators },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsBasketProduct(product)) {
      return;
    }

    const retrievedBloombergCodes = underlyings.map(element => element.bloombergCode);

    const missingBloombergCodes = validatedData
      .filter(line => {
        const bloombergCode = line.bloombergCode;
        return !retrievedBloombergCodes.includes(bloombergCode);
      })
      .map(line => line.bloombergCode);

    if (missingBloombergCodes.length === 0) {
      const underlyingIds = underlyings.map(underlying => underlying.id);

      dispatch(
        thunks.neos.createRequestMissingUnderlyingsThunk(
          [rfqId],
          {
            success: {
              dispatchables: [
                thunks.neos.createUpdateProductAndReferenceWithImportedBasketDetailsThunk(
                  rfqId,
                  productId,
                  underlyings,
                  validatedData,
                ),
              ],
            },
          },
          underlyingIds,
        ),
      );
    } else {
      const errorMessage = `Error when retrieving underlyings, some imported underlyings cannot be retrieved or does not exist: ${missingBloombergCodes.join(', ')}`;
      dispatch(actionCreators.common.createLogAction(errorMessage, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: errorMessage,
          },
          undefined,
        ),
      );
    }
  };
}
