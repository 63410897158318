import type { Thunk } from '@/bootstrap/thunks';

interface Parameters {
  rfqSourceId: string;
  rfqTargetId: string;
}
export function createUiRfqClonedThunk({ rfqTargetId, rfqSourceId }: Parameters): Thunk {
  return function uiRfqClonedThunk(
    dispatch,
    getState,
    {
      selectors: {
        getRfqData,
        getUiStrategies,
        getRfqUnderlyingUiKeys,
        getWasSetToMixedDelta,
        getIbChat,
      },
      actionCreators: {
        neos: {
          rfqUiCrudActions,
          strategyUiCrudActions,
          createStrategySelectedAction,
          createTabAddedAction,
          createNextUserActionsRequestedAction,
          underlyingUiCrudActions,
        },
      },
    },
  ) {
    const state = getState();
    const { strategyIds: strategyCloneIds } = getRfqData(state, rfqTargetId);
    const { strategyIds: strategyToCloneIds } = getRfqData(state, rfqSourceId);
    const ibChat = getIbChat(state.ui, rfqSourceId);

    const uiStrategiesToClone = getUiStrategies(state.ui, strategyToCloneIds);
    dispatch(
      createTabAddedAction(rfqTargetId),
      rfqUiCrudActions.patchOrInsert(rfqTargetId, { ibChat }),
      ...uiStrategiesToClone.map((uiStrategyToClone, index) => {
        const {
          legOverMessageDismissed,
          displayCompoImportHasMultipleMaturitiesWarning,
          displayMissingCompositionDataWarning,
          displayCompoImportHasMultipleIndicesWarning,
          ...clone
        } = uiStrategyToClone;
        return strategyUiCrudActions.upsert(strategyCloneIds[index], {
          ...clone,
          displayCompoImportHasMultipleMaturitiesWarning: false,
          displayMissingCompositionDataWarning: false,
          displayCompoImportHasMultipleIndicesWarning: false,
        });
      }),
      ...getRfqUnderlyingUiKeys(state.ui, rfqSourceId).map(({ underlyingId }) => {
        const wasSetToMixedDelta = getWasSetToMixedDelta(state.ui, rfqSourceId, underlyingId);
        return underlyingUiCrudActions.insert(
          { rfqId: rfqTargetId, underlyingId },
          { wasSetToMixedDelta },
        );
      }),
      createNextUserActionsRequestedAction(rfqTargetId),
      createStrategySelectedAction(rfqTargetId, strategyCloneIds[0]),
    );
  };
}
