import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { connect } from 'react-redux';
import { MidComponent, type MidOwnProps, type MidStateProps } from './Mid';
import { getMidModel } from './getMidModel';

export const Mid = connect(mapStateToProps)(MidComponent);

function mapStateToProps(state: AppState, { strategyId }: MidOwnProps): MidStateProps {
  return {
    model: getMidModel(state, strategyId, selectors),
  };
}
