import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  AggregatedPredealCheckComponent,
  type AggregatedPredealCheckDispatchProps,
  type AggregatedPredealCheckOwnProps,
  type AggregatedPredealCheckStateProps,
} from './AggregatedPredealCheck';
import { getAggregatedPredealCheckModel } from './getAggregatedPredealCheckModel';

export const AggregatedPredealCheck = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AggregatedPredealCheckComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: AggregatedPredealCheckOwnProps,
): AggregatedPredealCheckStateProps {
  return getAggregatedPredealCheckModel(state, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: AggregatedPredealCheckOwnProps,
): AggregatedPredealCheckDispatchProps {
  return {
    showPredealChecksModal: () => {
      dispatch(neosThunks.createShowPredealChecksModalThunks(rfqId));
    },
    refreshPredealChecks: () => {
      dispatch(neosThunks.createRequestNeosPredealCheckRefreshThunk(rfqId));
    },
  };
}
