import { DealTypeComponent } from './DealType';
import { EquityFinanceTypeComponent } from './EquityFinanceTypeComponent';
import { PartialReset } from './PartialReset';
import { ValuationFrequencyComponent } from './ValuationFrequency';
import { ValuationTypeComponent } from './ValuationType';

interface ProductDetailsProps {
  isElsDetails: boolean;
}

export function ProductDetailsBloc({ isElsDetails }: ProductDetailsProps) {
  if (!isElsDetails) {
    return null;
  }

  return (
    <div className="border p-1 card pb-3" style={{ width: '650px' }}>
      <div className="d-flex justify-content-between">
        <h5 className="d-inline-block p-1 m-0 mb-1">Product Details</h5>
      </div>
      <div className="d-flex flex-wrap  els-fields">
        <ValuationTypeComponent />
        <ValuationFrequencyComponent />
        <PartialReset />
        <DealTypeComponent />
        <EquityFinanceTypeComponent />
      </div>
    </div>
  );
}
