import { type FC, Fragment } from 'react';
import { TruncatedLabel } from '../../../../share/trancatedLabel/TruncatedLabel';
import type { PredealChecksTableModel } from './getPredealChecksTableModel';
import { PredealCheckCell } from './predealCheckCell';

import type { PredealCheckAllocationOrDeltaLevel } from '@/neos/business/predealCheck/predealCheckModel';
import styles from './PredealChecksTable.module.scss';

export interface PredealChecksTableOwnProps {
  rfqId: string;
  level: PredealCheckAllocationOrDeltaLevel;
  className?: string;
}

export interface PredealChecksTableStateProps {
  model: PredealChecksTableModel;
}

export interface PredealChecksTableDispatchProps {
  onExpandOrCollapse(isCollapsed: boolean): void;
}

type PredealChecksTableProps = PredealChecksTableOwnProps &
  PredealChecksTableStateProps &
  PredealChecksTableDispatchProps;

export const PredealChecksTableComponent: FC<PredealChecksTableProps> = ({
  rfqId,
  level,
  className = '',
  model,
  onExpandOrCollapse,
}) =>
  !model.displayed ? null : (
    <table className={`${styles['predeal-checks-table']} ${className}  border-bottom w-100 mb-2`}>
      <thead>
        <tr>
          <th>
            <button
              className="btn btn-flat-primary ps-1"
              onClick={() => onExpandOrCollapse(!model.isCollapsed)}
            >
              <i className="icon me-2">{model.isCollapsed ? 'expand_more' : 'expand_less'}</i>
              {model.levelTitle} Counterparties
            </button>
          </th>
          {model.predealCheckTypes.map(({ title, padding }) => (
            <th key={`header-${title}`} className={`${styles['predeal-check-header']} ${padding} `}>
              <div>
                <span>{title}</span>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {model.counterpartsNamesByIds.map(({ id: counterpartId, name }, counterpartIndex) => (
          <Fragment key={counterpartId}>
            <tr className={`${styles['predeal-checks-line']} `}>
              <td
                className={`${styles['counterpart']} `}
                data-e2e={`${model.levelTitle}-counterpart-${counterpartIndex}`}
              >
                <TruncatedLabel className="mb-0" label={name} />
              </td>
              {model.predealCheckTypes.map(({ type, title }) => (
                <td
                  data-e2e={`${model.levelTitle}-${title}-${counterpartIndex}`}
                  key={`counterpart-${counterpartId}-${type}`}
                >
                  <PredealCheckCell
                    counterpartId={counterpartId}
                    pdcLevel={level}
                    rowLevel="COUNTERPART"
                    type={type}
                    rfqId={rfqId}
                  />
                </td>
              ))}
            </tr>
            {!model.isCollapsed ? (
              model.legIdsByStrategyIds.map(({ strategyId, legIds }, strategyIndex) => (
                <Fragment key={strategyId}>
                  <tr className={`${styles['predeal-checks-line']} `}>
                    <td className="ps-2">Strategy {strategyIndex + 1}</td>
                    {model.predealCheckTypes.map(({ type, title }) => (
                      <td
                        data-e2e={`${model.levelTitle}-${title}-${counterpartIndex}-${strategyIndex}`}
                        key={`strategy-${counterpartId}-${strategyId}-${type}`}
                      >
                        <PredealCheckCell
                          rowLevel="STRATEGY"
                          rfqId={rfqId}
                          counterpartId={counterpartId}
                          pdcLevel={level}
                          strategyId={strategyId}
                          type={type}
                        />
                      </td>
                    ))}
                  </tr>
                  {legIds.map((legId, legIndex) => (
                    <tr
                      className={`${styles['predeal-checks-line']} ${
                        !(legIndex % 2) ? styles['predeal-checks-grey-line'] : ''
                      } `}
                      key={legId}
                    >
                      <td className="ps-3">Leg {legIndex + 1}</td>
                      {model.predealCheckTypes.map(({ type, title }) => (
                        <td
                          data-e2e={`${model.levelTitle}-${title}-${counterpartIndex}-${strategyIndex}-${legIndex}`}
                          key={`leg-${counterpartId}-${legId}-${type}`}
                        >
                          <PredealCheckCell
                            rowLevel="LEG"
                            type={type}
                            pdcLevel={level}
                            counterpartId={counterpartId}
                            rfqId={rfqId}
                            strategyId={strategyId}
                            legId={legId}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))
            ) : (
              <tr />
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
