import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { TransactionActions } from '@/neos/components/rfq/transactionActions/TransactionActions';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { ActorsSetup } from '../actorsSetup/ActorsSetup.container';
import { Notional } from '../notional/Notional.container';
import { Workflow } from '../workflow/Workflow';
import styles from './StepsCommonInfo.module.scss';

export interface StepsCommonInfoProps {
  rfqId: string;
}

export const StepsCommonInfo: FC<StepsCommonInfoProps> = ({ rfqId }) => {
  const isEls = useSelector((state: AppState) =>
    selectors.isRfqElsAndToggled(state, rfqId, selectors),
  );

  return (
    <div
      className={`${styles['steps-common-info']}   ${
        isEls ? styles['steps-common-info-els'] : ''
      } `}
    >
      <Workflow rfqId={rfqId} />
      <Notional rfqId={rfqId} />
      <ActorsSetup rfqId={rfqId} />
      <TransactionActions rfqId={rfqId} />
    </div>
  );
};
