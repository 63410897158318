import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import {
  type LegData,
  type LegField,
  brokerExecutionVenueTypes,
} from '@/neos/business/rfq/strategy/leg/legData/legDataModel';
import { getBrokerBlocModel } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/BrokersBloc/getBrokerBlocModel';
import {
  ElsFeaturesContext,
  commonElsBlocClasses,
} from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useUpdateSectionHeight } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/useUpdateSectionHeight';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

export function BrokersBloc() {
  const dispatch = useDispatch();
  const { strategyId } = useContext(ElsFeaturesContext);
  const { ref } = useUpdateSectionHeight(strategyId, 'Brokers');
  const { legId, brokerId, currencies, brokerFields, availableBrokers, datetime, timezone } =
    useAppSelector(state => getBrokerBlocModel(state, strategyId, selectors));

  function updateLegBrokerFields(fieldToUpdate: LegField, newValue: LegData[LegField]) {
    dispatch(thunks.neos.createUpdateLegThunk(strategyId, legId, fieldToUpdate, newValue));
  }

  function onDateTimeChange(newTime: string | undefined, newDate: string | undefined = undefined) {
    const today = new Date();
    const date = newDate !== undefined ? new Date(newDate) : new Date(datetime?.date || today);
    const [hours, minutes] =
      newDate === undefined && newTime !== undefined
        ? newTime.split(':')
        : datetime?.time
          ? datetime.time.split(':')
          : [0, 0];

    if (timezone === 'LOCAL') {
      date.setHours(Number(hours), Number(minutes), 0, 0);
    } else {
      date.setUTCHours(Number(hours), Number(minutes), 0, 0);
    }
    updateLegBrokerFields('brokerInfoTradingDateTime', date.toISOString());
  }

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <h5 className="d-inline-block p-1 m-0 mb-1">Brokers</h5>
        </div>
        <div className="d-flex flex-wrap gap-1">
          <ElsBlocField
            label="Broker"
            renderInput={readOnly => (
              <NeosSelect
                readOnly={readOnly}
                value={brokerId}
                onChange={value => updateLegBrokerFields('brokerId', value)}
                data-e2e="els-section-broker-id"
                addEmptyOption
                options={availableBrokers}
              />
            )}
          />
          <ElsBlocField
            label="Comm"
            renderInput={readOnly => (
              <div
                className="d-grid gap-4px"
                style={{ gridTemplateColumns: 'minmax(85px, 120px) 70px' }}
              >
                <NumericInput
                  readOnly={readOnly}
                  value={brokerFields.brokerCommission}
                  onBlur={value => updateLegBrokerFields('brokerCommission', value)}
                  data-e2e="els-section-broker-commission"
                />
                <NeosSelect
                  readOnly={readOnly}
                  options={currencies.map(({ refId }) => ({ value: refId, label: refId }))}
                  onChange={value => updateLegBrokerFields('brokerCommissionCcy', value)}
                  addEmptyOption
                  value={brokerFields.brokerCommissionCcy}
                  data-e2e="els-section-broker-commission-currency"
                />
              </div>
            )}
          />
          <ElsBlocField
            label="Trading Date"
            renderInput={readOnly => (
              <div className="d-flex">
                <FlowDatePicker
                  readOnly={readOnly}
                  onChange={value => onDateTimeChange(datetime?.time, value)}
                  maturities={[]}
                  date={datetime?.date ?? ''}
                  hideCalendarIcon
                  inputClassName="form-control w-100px"
                  popperPlacement="top"
                  data-e2e="els-section-broker-trading-date"
                />
                <input
                  className="form-control"
                  type="time"
                  value={datetime?.time}
                  onChange={event => onDateTimeChange(event.target.value)}
                  disabled={readOnly}
                  data-e2e="els-section-broker-trading-time"
                />
              </div>
            )}
          />
          <ElsBlocField
            label="Venue"
            renderInput={readOnly => (
              <NeosBlurInput
                value={brokerFields.brokerInfoVenue || ''}
                onBlur={event => updateLegBrokerFields('brokerInfoVenue', event.target.value)}
                disabled={readOnly}
                data-e2e="els-section-broker-venue"
              />
            )}
          />
          <ElsBlocField
            label="Trading Venue Transaction ID"
            renderInput={readOnly => (
              <NeosBlurInput
                data-e2e="els-section-broker-trading-venue-transaction-id"
                value={brokerFields.brokerInfoTradingVenueTransactionId || ''}
                onBlur={event =>
                  updateLegBrokerFields('brokerInfoTradingVenueTransactionId', event.target.value)
                }
                disabled={readOnly}
              />
            )}
          />
          <ElsBlocField
            label="Execution Venue (SEF)"
            renderInput={readOnly => (
              <NeosSelect
                readOnly={readOnly}
                value={brokerFields.brokerInfoExecutionVenue}
                onChange={value => updateLegBrokerFields('brokerInfoExecutionVenue', value)}
                data-e2e="els-section-broker-execution-venue"
                addEmptyOption
                options={brokerExecutionVenueTypes}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
