import type { FC } from 'react';
import { StrategyHeader } from './strategyHeader/StrategyHeader';
import { FairPricesHeader } from './strategy/prices/fairPrices/FairPricesHeader.container';
import type { StrategiesModel } from './getStrategiesModel';
import type { ColumnGridDefinition } from './getDisplayedColumns';
import { join } from 'lodash';
import { GlobalActions } from './globalActions/GlobalActions.container';
import { QuotesHeader } from './strategy/prices/quotes/QuotesHeader';

import styles from './Strategies.module.scss';
import { Strategy } from '@/neos/components/rfq/strategies/strategy/Strategy';

export interface StrategiesOwnProps {
  rfqId: string;
  isSimilarActivityMode?: boolean;
  areSimilarActivitiesStrategies?: boolean;
}

export interface StrategiesMapStateToProps {
  model: StrategiesModel;
  columnGridDefinition: ColumnGridDefinition[];
}

function getGridTemplateColumns(columnGridDefinitions: ColumnGridDefinition[]): string {
  return join(
    columnGridDefinitions.map(
      ({ gridTemplateColumns }: ColumnGridDefinition) => gridTemplateColumns,
    ),
    ' ',
  );
}

type StrategiesProps = StrategiesOwnProps & StrategiesMapStateToProps;

export const StrategiesStyled: FC<StrategiesProps> = ({
  rfqId,
  isSimilarActivityMode,
  model: { isFairPriceDisplayed, strategyIds, displayedDeltaStrategyIds },
  columnGridDefinition,
}) => {
  const gridTemplateColumns = getGridTemplateColumns(columnGridDefinition);

  return (
    <section className={`${styles['strategies']} `}>
      {!isSimilarActivityMode && <GlobalActions rfqId={rfqId} />}
      <section className={`${styles['strategies-grid']} ${isSimilarActivityMode ? 'p-1' : ''}} `}>
        <div />
        <label className={`${styles['lblBooked']} `} />
        <StrategyHeader
          columnGridDefinition={columnGridDefinition}
          gridTemplateColumns={gridTemplateColumns}
        />
        {!isSimilarActivityMode && <QuotesHeader rfqId={rfqId} />}
        {isFairPriceDisplayed && !isSimilarActivityMode && <FairPricesHeader rfqId={rfqId} />}
        <div />
        {strategyIds.map((strategyId, i) => (
          <Strategy
            key={strategyId}
            rfqId={rfqId}
            strategyId={strategyId}
            columnGridDefinition={columnGridDefinition}
            gridTemplateColumns={gridTemplateColumns}
            strategyIndex={i}
            isFairPriceDisplayed={isFairPriceDisplayed}
            isSimilarActivityMode={isSimilarActivityMode}
          />
        ))}
        {displayedDeltaStrategyIds.map((deltaHedgingStrategyId, i) => (
          <Strategy
            key={deltaHedgingStrategyId}
            rfqId={rfqId}
            strategyId={deltaHedgingStrategyId}
            columnGridDefinition={columnGridDefinition}
            gridTemplateColumns={gridTemplateColumns}
            strategyIndex={i + strategyIds.length}
            isFairPriceDisplayed={isFairPriceDisplayed}
            isSimilarActivityMode={isSimilarActivityMode}
          />
        ))}
      </section>
    </section>
  );
};
