import { importExcelFileContent } from '@/util/excel/excel';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';

type ScheduleImportProps = {
  strategyId: string;
};

export const ELSScheduleImport = ({ strategyId }: ScheduleImportProps) => {
  const dispatch = useDispatch();

  function onImport(importedData: string[][]) {
    const dataWithoutHeaders = importedData.slice(1);
    dispatch(neosThunks.createImportScheduleLegsDataThunk(strategyId, dataWithoutHeaders));
  }

  const fileInputRef = useRef<HTMLInputElement>(null);
  const isReadonlyAtWorfklow = useAppSelector(appState =>
    selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors),
  );
  return (
    <button
      disabled={isReadonlyAtWorfklow}
      onClick={() => {
        fileInputRef.current?.click();
      }}
      className="btn btn-discreet-primary"
    >
      Import <i className="icon icon-sm ms-2">file_upload</i>
      <input
        ref={fileInputRef}
        data-e2e="els-schedule-import"
        type="file"
        accept=".xlsx"
        className="d-none"
        onChange={e => importExcelFileContent(e, onImport, true)}
      />
    </button>
  );
};
