import { createAddRateSchedulePeriodThunk } from '../../business/thunks/rateSchedulePeriod/addRateSchedulePeriodThunk';
import { createRemoveRateSchedulePeriodThunk } from '../../business/thunks/rateSchedulePeriod/removeRateSchedulePeriodThunk';
import { createUpdateRateSchedulePeriodThunk } from '../../business/thunks/rateSchedulePeriod/updateRateSchedulePeriodThunk';
import { createDisableElsSectionThunk } from '../../components/rfq/strategies/strategy/features/elsSectionsModal/disableElsSectionThunk';
import { createEnableElsSectionThunk } from '../../components/rfq/strategies/strategy/features/elsSectionsModal/enableElsSectionThunk';
import { createToggleElsSectionThunk } from '../../components/rfq/strategies/strategy/features/elsSectionsModal/toggleElsSectionThunk';
import { createUpdateBreakFeeElectionThunk } from './breakFeeElection/updateBreakFeeElectionThunk';
import { createAddBreakFeePeriodThunk } from './breakFeesPeriod/addBreakFeePeriodThunk';
import { createRemoveBreakFeePeriodThunk } from './breakFeesPeriod/removeBreakFeePeriodThunk';
import { createUpdateBreakFeePeriodThunk } from './breakFeesPeriod/updateBreakFeePeriodThunk';
import { createChangeScheduleStartOrEndDateThunk } from './changeScheduleStartOrEndDateThunk';
import { createAddEquitySchedulePeriodThunk } from './equitySchedulePeriod/addEquitySchedulePeriodThunk';
import { createRemoveAllEquitySchedulePeriodThunk } from './equitySchedulePeriod/removeAllEquitySchedulePeriodThunk';
import { createRemoveEquitySchedulePeriodThunk } from './equitySchedulePeriod/removeEquitySchedulePeriodThunk';
import { createUpdateEquitySchedulePeriodThunk } from './equitySchedulePeriod/updateEquitySchedulePeriodThunk';
import { createImportScheduleLegsDataThunk } from './importScheduleLegsDataThunk';
import { createRemoveAllRateSchedulePeriodThunk } from './rateSchedulePeriod/removeAllRateSchedulePeriodThunk';

export const elsThunks = {
  createRemoveRateSchedulePeriodThunk,
  createAddRateSchedulePeriodThunk,
  createUpdateRateSchedulePeriodThunk,
  createRemoveEquitySchedulePeriodThunk,
  createAddEquitySchedulePeriodThunk,
  createUpdateEquitySchedulePeriodThunk,
  createRemoveAllEquitySchedulePeriodThunk,
  createAddBreakFeePeriodThunk,
  createUpdateBreakFeePeriodThunk,
  createRemoveBreakFeePeriodThunk,
  createRemoveAllRateSchedulePeriodThunk,
  createImportScheduleLegsDataThunk,
  createEnableElsSectionThunk,
  createDisableElsSectionThunk,
  createToggleElsSectionThunk,
  createChangeScheduleStartOrEndDateThunk,
  createUpdateBreakFeeElectionThunk,
};
