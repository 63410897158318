import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { Switch } from '@/neos/components/share/switch/Switch';
import type { ExecFeesValue } from '../../../../../../../../neos/business/neosModel';

type DisplayedField<T> = { value: T; isDisplayed: true } | { isDisplayed: false };

export interface ElsFields {
  equityBullet: DisplayedField<boolean>;
  rateBullet: DisplayedField<boolean>;
  rateOvernight: DisplayedField<boolean>;
  execFees: DisplayedField<ExecFeesValue>;
  resetFrequency: DisplayedField<number | undefined>;
}

interface CheckBowWithErrorHandlingProps {
  rfqId: string;
  strategyId: string;
  fieldValue: boolean;
  fieldName: keyof Omit<ElsFields, 'execFees' | 'availableExecFeesValues'>;
  onChangeFunction: (newValue: boolean) => void;
}

export function CheckBoxWithErrorHandling({
  rfqId,
  strategyId,
  fieldValue,
  fieldName,
  onChangeFunction,
}: CheckBowWithErrorHandlingProps) {
  return (
    <ErrorHighlight errorField={fieldName} related={{ rfqId, strategyId }}>
      <Switch
        switchClassName="text-center"
        data-e2e={`neos-extra-features-els-${fieldName}`}
        checked={fieldValue}
        onChange={() => onChangeFunction(!fieldValue)}
      />
    </ErrorHighlight>
  );
}
