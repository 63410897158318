import type { PropsWithChildren } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { type EventType, isElsProduct } from '../../../../../../../../neos/business/neosModel';

import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useSelector } from 'react-redux';
import type {
  SecondaryRfqSecondaryTypeField,
  SecondaryTypeCellModel,
  SecondaryTypeFieldModel,
} from './getSecondaryTypeCellModel';

export interface SecondaryTypeCellOwnProps {
  rfqId: string;
  strategyId: string;
}

export type SecondaryTypeCellMapStateProps = SecondaryTypeCellModel;

export interface SecondaryTypeCellDispatchProps {
  loadLatestTransaction(rfqId: string): void;
}

type SecondaryTypeCellProps = SecondaryTypeCellOwnProps &
  SecondaryTypeCellMapStateProps &
  SecondaryTypeCellDispatchProps;

function of(et: EventType) {
  return et;
}

const handleClick = (
  props: PropsWithChildren<SecondaryTypeCellProps>,
  st: SecondaryRfqSecondaryTypeField,
) => {
  if (st.isOldUnwind) {
    return;
  }
  return props.loadLatestTransaction(st.primaryRfqId);
};

/**
 * Displayed at leg level in a secondary rfq
 * for display only not an action button
 * both in transaction and in Similar Activities
 */
export function SecondaryTypeCellComponent(props: SecondaryTypeCellProps) {
  const rfqProducts = useSelector((state: AppState) =>
    selectors.getRfqProducts(state, props.rfqId, selectors),
  );
  const isNovationEnabled = rfqProducts.every(p => !isElsProduct(p));
  return (
    <section>
      {props.secondaryTypes.map((st: SecondaryTypeFieldModel) => {
        const secondaryEvent =
          st.isEligible &&
          props.secondaryEvents.find(secondaryEvent => secondaryEvent.legId === st.legId)
            ?.secondaryEvent;

        return st.isEligible ? (
          <ToggleButtonGroup
            key={`legEvent${st.legId}-ON-SECONDARY`}
            name={`legEvent${st.legId}`}
            value={st.secondaryType ?? ''}
            onChange={() => {
              handleClick(props, st);
            }}
            onClick={() => {
              handleClick(props, st);
            }}
          >
            <ToggleButton
              key={`legEvent${st.legId}-INCREASE-ON-SECONDARY`}
              value={of('INCREASE')}
              variant="outline-success"
              className={`${secondaryEvent === 'INCREASE' ? 'active' : 'd-none'} px-2`}
              id="increase"
              disabled={props.isReadonly}
            >
              <SimpleNeosTooltip
                key={`legEvent-increase-button-tooltip-overlay`}
                id="increase-button-tooltip"
                message={'Increase'}
              >
                <i className="icon icon-sm c-pointer">arrow_upward</i>
              </SimpleNeosTooltip>
            </ToggleButton>
            <ToggleButton
              key={`legEvent${st.legId}-PARTIAL_UNWIND-ON-SECONDARY`}
              value={of('PARTIAL_UNWIND')}
              variant="outline-warning"
              className={`${secondaryEvent === 'PARTIAL_UNWIND' ? 'active' : 'd-none'} px-2`}
              id="partial_unwind"
              disabled={props.isReadonly}
            >
              <SimpleNeosTooltip
                key={`legEvent-decrease-button-tooltip-overlay`}
                id="decrease-button-tooltip"
                message={'Decrease'}
              >
                <i className="icon icon-sm c-pointer">arrow_downward</i>
              </SimpleNeosTooltip>
            </ToggleButton>
            <ToggleButton
              key={`legEvent${st.legId}-FULL_UNWIND-ON-SECONDARY`}
              value={of('FULL_UNWIND')}
              variant="outline-danger"
              className={`${secondaryEvent === 'FULL_UNWIND' ? 'active' : 'd-none'} px-2`}
              id="full_unwind"
              disabled={props.isReadonly}
            >
              <SimpleNeosTooltip
                key={`legEvent-close-button-tooltip-overlay-ON-SECONDARY`}
                id="close-button-tooltip"
                message={'Close'}
              >
                <i className="icon icon-sm c-pointer">sync_alt</i>
              </SimpleNeosTooltip>
            </ToggleButton>
            {isNovationEnabled && (
              <ToggleButton
                key={`legEvent${st.legId}-NOVATION_REMAINING-ON-SECONDARY`}
                value={of('NOVATION_REMAINING')}
                variant="outline-info"
                className={`${secondaryEvent === 'NOVATION_REMAINING' ? 'active' : 'd-none'} px-2`}
                id="novation_remaining"
                disabled={props.isReadonly}
              >
                <SimpleNeosTooltip
                  key={`legEvent-close-button-tooltip-overlay-ON-SECONDARY`}
                  id="close-button-tooltip"
                  message={'Novation Remaining'}
                >
                  <span className="px-1">R</span>
                </SimpleNeosTooltip>
              </ToggleButton>
            )}
            <ToggleButton
              key={`legEvent${st.legId}--ON-SECONDARY`}
              value={''}
              variant="outline-primary"
              className={`${secondaryEvent === undefined ? 'active' : 'd-none'} px-2`}
              id="empty"
              disabled={props.isReadonly}
            >
              <SimpleNeosTooltip
                key={`legEvent-close-button-tooltip-overlay`}
                id="not-applicable-button-tooltip"
                message={'Not applicable'}
              >
                <i>N/A</i>
              </SimpleNeosTooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        ) : (
          <div
            key={`legEvent${st.legId}-ON-SECONDARY`}
            style={{ height: '32px' }}
            className="flex-center"
          >
            <SimpleNeosTooltip
              key={`legEvent-not-eligible-${st.legId}-ON-SECONDARY`}
              id="unwind-leg-warning"
              message={'Not Eligible'}
            >
              <i className="icon text-warning">warning</i>
            </SimpleNeosTooltip>
          </div>
        );
      })}
    </section>
  );
}
